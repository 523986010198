import {TemplateBaseForm} from '@/modules/correspondentiebeheer/forms/templates/baseForm';
import VueI18n from 'vue-i18n';
import {BriefTemplate} from '@/modules/correspondentiebeheer/domains';
import {FormItem, formRules} from '@/modules/core/components/forms/forms.model';

export class CuForm extends TemplateBaseForm {
	templateDocument: FormItem;

	constructor(translator: VueI18n, initialData?: BriefTemplate) {
		super('brieven', translator, initialData);

		this.templateDocument = new FormItem(
			'templateDocument',
			'correspondentieBeheer.brieven.cuForm.document',
			null,
			formRules.fileAndRequired(translator, ['.docx'])
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			...super.toJSON(),
			file: this.templateDocument.value,
		};
	}
}
