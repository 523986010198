import {httpService} from "@/modules/core/services/http-service";

export interface ConfigJson {
    pingTimeout: number;
    version: string;
}

export class Config {
    pingTimeout: number = 5000;
    version: string;

    constructor(json: ConfigJson) {
        this.pingTimeout = json.pingTimeout;
        this.version = json.version;
    }
}

export class ConfigService {

    fetchConfig(): Promise<Config> {
        return httpService
            .get<ConfigJson>("/api/system/config")
            .then((data: ConfigJson) => {
                return new Config(data);
            });
    }


    ping(): Promise<String> {
        return httpService
            .get<String>("/api/system/ping");
    }
}

export const configService = new ConfigService();
