import Vue from "vue";

const message = Vue.observable({ text: '', show: false, color: '' })

const ErrorPlugin = {
    install(Vue: any, options: any) {
        Vue.prototype.$message = message;
        Vue.prototype.$hideMessage = function() {
            message.show = false;
        };
        Vue.prototype.$showSuccessMessage = function(msg: string) {
            message.text = msg;
            message.show = true;
            message.color = 'success'
        };
        Vue.prototype.$showErrorMessage = function(msg: string) {
            message.text = msg;
            message.show = true;
            message.color = 'error'
        };
        Vue.prototype.$showWarningMessage = function(msg: string) {
            message.text = msg;
            message.show = true;
            message.color = 'warning'
        };
    }
};

export default ErrorPlugin;