<template>
	<v-container>
		<v-row class='mb-3 mt-6'>
			<v-col>
				<h2>{{ $t('bpm.title') }}</h2>
			</v-col>

			<v-col class='d-flex align-center justify-end'>
				<v-btn color='primary'
							 small
							 class='ml-2'
							 @click='handleNewBPM'>
					<v-icon class='material-icons-outlined'>add</v-icon>
					<span class='pa-2'>{{ $t('bpm.new') }}</span>
				</v-btn>
			</v-col>
		</v-row>

		<v-data-table class='elevation-1'
									calculate-widths
									:headers='headers'
									:items='items'
									:footer-props="{'items-per-page-options': [100, 50, 25, 10, -1]}"
									:items-per-page='100'
		>
			<template v-slot:item.type='{ item }'>
				<span>{{ $t(`bpm.types.${item.type}`) }}</span>
				<span v-if='item.hasDetailType'>
					&emsp;{{ $t(`bpm.detailTypes.opvolging.${item.detailType}`) }}
				</span>
			</template>

			<template v-slot:item.laatstGewijzigdOp='{ item }'>
				<span>{{ item.laatstGewijzigdOp ? item.laatstGewijzigdOp.format('DD/MM/YYYY') : '' }}</span>
			</template>

			<template v-slot:item.acties='{ item }'>
				<v-icon class='material-icons-outlined' color='primary' @click='() => handleDownload(item)'>
					file_download
				</v-icon>
			</template>
		</v-data-table>

		<update-template v-if='showUpdateTemplateDialog'
										 @submit='handleDialogSubmit'
										 @cancel='handleDialogCancel' />

	</v-container>
</template>

<script>
import {getBPMList} from '@/modules/bpmbeheer/api';
import UpdateTemplate from '@/modules/bpmbeheer/dialogs/updateTemplate.vue';
import {downloadByGet} from '@/modules/core/services/downloadFile';

export default {
	name: 'Overview',
	components: { UpdateTemplate },
	data() {
		return {
			items: [],
			options: {},
			headers: [
				{
					text: this.$t('bpm.table.type'),
					value: 'type',
					sortable: false,
				},
				{
					text: this.$t('bpm.table.laatstGewijzigdOp'),
					value: 'laatstGewijzigdOp',
					sort: (a, b) => a.isAfter(b) ? 1 : -1,
				},
				{
					text: this.$t('bpm.table.laatstGewijzigdDoor'),
					value: 'laatstGewijzigdDoor',
					sortable: false,
				},
				{ text: '', value: 'acties', sortable: false, align: 'end' },
			],

			showUpdateTemplateDialog: false,
		};
	},
	mounted() {
		this.init();
	},

	methods: {
		init: async function() {
			this.items = await getBPMList();
		},
		handleNewBPM: function() {
			this.showUpdateTemplateDialog = true;
		},
		handleDownload: async function (item) {
			await downloadByGet(item.link);
		},
		handleDialogSubmit: function() {
			this.init();
			this.handleDialogCancel();
		},
		handleDialogCancel: function() {
			this.showUpdateTemplateDialog = false;
		},

	},
};
</script>

<style scoped>

</style>
