<template>
    <v-dialog
            v-model="$loader.loading"
            persistent
            width="150"
    >
        <v-card
                dark
        >
            <v-card-text style="padding: 0;text-align: center;z-index: 6;">
                Loading
                <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "Loader",
    }
</script>

<style scoped>
    .overlay {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .center {
        margin: 0 auto;
        width: 100px;
    }
</style>