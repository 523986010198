<template>
  <v-card class="pa-6" dense>
    <v-form ref="form">
      <v-row>
        <v-col>
          <date-field :date.sync="form.vermoedelijkeBevallingsDatum.value"
                      :rules="form.vermoedelijkeBevallingsDatum.rules"
                      :label="form.vermoedelijkeBevallingsDatum.label"
                      :name="form.vermoedelijkeBevallingsDatum.id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="form.heightBefore.value"
                        :rules="form.heightBefore.rules"
                        :label="$t(form.heightBefore.label)"
                        :name="form.heightBefore.id"
                        outlined
                        type="number"
          >
            <template v-slot:append>
              <span class="grey4--text">{{ $t('woman.cu.metrics.heightUnit') }}</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="form.weightBefore.value"
                        :rules="form.weightBefore.rules"
                        :label="$t(form.weightBefore.label)"
                        :name="form.weightBefore.id"
                        outlined
                        type="number"
          >
            <template v-slot:append>
              <span class="grey4--text">{{ $t('woman.cu.metrics.weightUnit') }}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-actions class="form-row">
        <v-spacer/>
        <v-btn text color="text_light" @click="resetForm">
          {{ $t('woman.cu.actions.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="saveForm">
          {{ $t('woman.details.actions.submit') }}
        </v-btn>
      </v-card-actions>



    </v-form>
  </v-card>
</template>

<script>
import DateField from "@/modules/core/components/forms/DateField";
import {RegForm} from "@/modules/vrouw/forms/registration/RegForm";
import {updateVrouwRegistratie} from "@/modules/vrouw/api";
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {VrouwLogType} from "@/modules/vrouw/domains/logs";

export default {
  name: "RegForm",
  components: {DateField},
  props: {
    woman: Vrouw,
    type: VrouwLogType
  },
  data() {
    return {
      formData: null,
      form: new RegForm(this.$i18n)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init:  function () {
        this.form = new RegForm(this.$i18n, this.woman)
    },
    saveForm: async function () {
      if (this.$refs.form.validate()) {
        await updateVrouwRegistratie(this.woman.vrouwId, this.form);
         this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.cu.saved');
        this.$message.show = true;
      }
    },
    resetForm: function () {
      this.form.reset();
    }
  }
}
</script>

<style scoped>
</style>
