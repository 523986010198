import VueI18n from "vue-i18n";
import {FormItem, formRules} from "@/modules/core/components/forms/forms.model";
import {DetailsForm} from "@/modules/vrouw/forms/details/detailsForm";
import {Diabetesteam} from "@/modules/diabetesteam/domains";

export class DiabeticForm extends DetailsForm<Diabetesteam> {
    naam: FormItem;

    diabetesteamId: FormItem

    constructor(translator: VueI18n, initialData?: Diabetesteam) {
        super(initialData);

        this.naam = new FormItem(
            "name",
            'woman.details.diabeticTeam.name',
            initialData?.naam ?? ''
        )


        this.diabetesteamId = new FormItem(
            "diabeticTeamId",
            'woman.details.diabeticTeam.header',
            initialData?.diabetesteamId ?? ''
        )
    }

    public resetData(): void {
        this.diabetesteamId.value = this.initialData?.diabetesteamId ?? '';
        this.naam.value = this.initialData?.naam ?? '';
    }

    toJSON(): { [p: string]: any } {
        return {
            diabeticTeam: this.diabetesteamId.value
        };
    }
}