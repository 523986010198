import {Action, getAccessRightsByRoles} from '@/modules/core/authorisation/access-right';
import {UserProfile} from "@/modules/user/services/user-profile-service";

export const authorisationStore = {
    namespaced: true,
    state: {
        accessRights: <Action[]>[],
        userProfile: undefined as UserProfile | undefined,
        authenticated: false
    },
    getters: {
        accessRights: state => {
            return state.accessRights
        },
        isAuthenticated: state=>{
            return state.authenticated
        },
        getUserId: state => {
            return state.userProfile?.userId ?? '';
        },
        getUserEmail: state => {
            return state.userProfile?.email ?? '';
        },
        getUserUsername: state => {
            return state.userProfile?.username || (state.userProfile?.email ?? '');
        },
    },
    mutations: {
        SET_USER_PROFILE(state, userProfile: UserProfile) {
            state.userProfile = userProfile;
            state.accessRights = getAccessRightsByRoles(userProfile.roles);
            state.authenticated=true;
        },
        CLEARS(state) {
            state.accessRights = []
            state.userProfile = undefined;
            state.authenticated=false;
        },
    },
};
