import VueI18n from 'vue-i18n';
import {FormItem, formRules, SelectItem} from '@/modules/core/components/forms/forms.model';
import {RegistratieKanaal, Vrouw} from '@/modules/vrouw/domains/vrouw';
import {DetailsForm} from '@/modules/vrouw/forms/details/detailsForm';
import moment from 'moment';

export class PersonalForm extends DetailsForm<Vrouw> {
	naam: FormItem;
	voornaam: FormItem;
	geboortedatum: FormItem;
	taal: FormItem;
	registratieKanaal: FormItem;
	registratieDatum: FormItem;
	jaarlijkseOpvolgingDatum: FormItem;
	voorkeurEmail: FormItem;

	constructor(translator: VueI18n, initialData?: Vrouw) {
		super(initialData);

		this.naam = new FormItem(
			'lastName',
			'woman.details.personal.name',
			initialData?.naam ?? '',
			formRules
				.required(translator, 'woman.details.required')
				.concat(formRules.maxChars(translator, 100))
		);

		this.voornaam = new FormItem(
			'firstName',
			'woman.details.personal.firstName',
			initialData?.voornaam ?? '',
			formRules
				.required(translator, 'woman.details.required')
				.concat(formRules.maxChars(translator, 100))
		);

		this.geboortedatum = new FormItem(
			'birthDay',
			'woman.details.personal.birthDay',
			initialData?.geboortedatum?.format('YYYY-MM-DD') ?? null,
			formRules.geboorteDatumValidatie(translator)
		);

		this.taal = new FormItem(
			'languagePreference',
			'woman.details.personal.languagePreference',
			initialData?.taal ?? '',
			formRules.required(translator, 'woman.details.required'),
			-1,
			[
				new SelectItem(translator.t('common.languages.nl').toString(), 'nl'),
				new SelectItem(translator.t('common.languages.fr').toString(), 'fr'),
				new SelectItem(translator.t('common.languages.en').toString(), 'en'),
			]
		);

		this.registratieDatum = new FormItem(
			'registration',
			'woman.details.personal.registration',
			initialData?.registratieDatum.format('YYYY-MM-DD') ?? '',
			formRules.required(translator, 'woman.details.required')
		);

		this.registratieKanaal = new FormItem(
			'registratieKanaal',
			'woman.details.personal.registratieKanaal.label',
			initialData?.registratieKanaal ?? '',
			formRules.required(translator, 'woman.details.required'),
			-1,
			[
				new SelectItem(
					translator.t('woman.details.personal.registratieKanaal.online').toString(),
					RegistratieKanaal.online
				),
				new SelectItem(
					translator.t('woman.details.personal.registratieKanaal.papier').toString(),
					RegistratieKanaal.papier
				),
			]
		);

		this.jaarlijkseOpvolgingDatum = new FormItem(
			'jaarlijkseOpvolgingDatum',
			'woman.details.personal.jaarlijkseOpvolgingDatum',
			initialData?.jaarlijkseOpvolgingDatum.format('M') ?? '',
			formRules.required(translator, 'woman.details.required'),
			-1,
			[
				new SelectItem(translator.t('common.months.1').toString(), '1'),
				new SelectItem(translator.t('common.months.2').toString(), '2'),
				new SelectItem(translator.t('common.months.3').toString(), '3'),
				new SelectItem(translator.t('common.months.4').toString(), '4'),
				new SelectItem(translator.t('common.months.5').toString(), '5'),
				new SelectItem(translator.t('common.months.6').toString(), '6'),
				new SelectItem(translator.t('common.months.7').toString(), '7'),
				new SelectItem(translator.t('common.months.8').toString(), '8'),
				new SelectItem(translator.t('common.months.9').toString(), '9'),
				new SelectItem(translator.t('common.months.10').toString(), '10'),
				new SelectItem(translator.t('common.months.11').toString(), '11'),
				new SelectItem(translator.t('common.months.12').toString(), '12'),
			]
		);

		this.voorkeurEmail = new FormItem(
			'isEmailPreference',
			'woman.details.personal.isEmailPreference.label',
			initialData?.voorkeurEmail.toString() ?? '',
			formRules.required(translator, 'woman.details.required'),
			-1,
			[
				new SelectItem(
					translator.t('woman.details.personal.isEmailPreference.email').toString(),
					'true'
				),
				new SelectItem(
					translator.t('woman.details.personal.isEmailPreference.post').toString(),
					'false'
				),
			]
		);
	}

	public resetData(): void {
		if (!this.initialData) {
			return;
		}
		this.naam.value = this.initialData.naam;
		this.voornaam.value = this.initialData.voornaam;
		this.geboortedatum.value = this.initialData.geboortedatum?.format('YYYY-MM-DD') ?? null;
		this.taal.value = this.initialData.taal;
		this.registratieDatum.value = this.initialData.registratieDatum.format('YYYY-MM-DD');
		this.jaarlijkseOpvolgingDatum.value = this.initialData.jaarlijkseOpvolgingDatum.format('M');
		this.voorkeurEmail.value = this.initialData.voorkeurEmail.toString();
	}

	toJSON(): { [p: string]: any } {
		const jaarlijkseOpvolgingDatum = moment(
			this.initialData?.jaarlijkseOpvolgingDatum.set(
				'month',
				this.jaarlijkseOpvolgingDatum.value - 1
			)
		);

		return {
			lastName: this.naam.value,
			firstName: this.voornaam.value,
			geboortedatum: this.geboortedatum.value
				? moment(this.geboortedatum.value).format('DD/MM/YYYY')
				: null,
			language: this.taal.value,
			registratieKanaal: this.registratieKanaal.value,
			registratieDatum: moment(this.registratieDatum.value).format('DD/MM/YYYY'),
			jaarlijkseOpvolgingDatum: jaarlijkseOpvolgingDatum.format('DD/MM/YYYY'),
			voorkeurEmail: this.voorkeurEmail.value,
		};
	}
}
