<template>
</template>

<script>
import {sleep} from "@/modules/core/utils";

export default {
  name: "Polling",
  props: {
    pollFunc: {
      type: Function,
      default: async () => {
      }
    },
    seconds: {
      type: Number,
      default: 5
    },
    maxFailuresInARow: {
      type: Number,
      default: 3
    },
  },
  data() {
    return {
      isActiveComponent: true,
      failures: 0
    }
  },
  mounted() {
    this.isActiveComponent = true;
    this.startPolling();
  },
  destroyed() {
    this.isActiveComponent = false;
  },
  methods: {
    startPolling: async function () {
      try {
        await this.pollFunc();
        this.failures = 0;
      } catch (e) {
        this.failures += 1;
      } finally {
        await sleep(this.seconds);
        if (this.isActiveComponent && this.maxFailuresInARow >= this.failures) {
          this.startPolling();
        }
      }
    }
  }
}
</script>

<style scoped>

</style>