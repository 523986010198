<template>

	<div>
		<v-row class='mb-3 mt-6'>
			<v-col>
				<h2>{{ $t('correspondentieBeheer.brieven.title') }}</h2>
			</v-col>

			<v-col class='d-flex align-center justify-end'>
				<v-btn color='primary'
							 small
							 class='ml-2'
							 @click='handleNewBriefTemplate'>
					<v-icon class='material-icons-outlined'>add</v-icon>
					<span class='pa-2'>{{ $t('correspondentieBeheer.brieven.new') }}</span>
				</v-btn>
			</v-col>
		</v-row>

		<v-data-table class='elevation-1'
									calculate-widths
									:headers='headers'
									:items='templates'
									:footer-props="{'items-per-page-options': [100, 50, 25, 10 ]}"
									:items-per-page='100'
									v-on:click:row='handleRowClick'
		>
			<template v-slot:item.type='{ item }'>
				<span>{{ $t(`correspondentieBeheer.brieven.types.${item.type}`) }}</span>
				<span v-if='item.hasDetailType'>
					&emsp;{{ $t(`correspondentieBeheer.brieven.detailTypes.opvolging.${item.detailType}`) }}
				</span>
			</template>

			<template v-slot:item.laatstGewijzigdOp='{ item }'>
				<span>{{ item.laatstGewijzigdOp ? item.laatstGewijzigdOp.format('DD/MM/YYYY') : '' }}</span>
			</template>

			<template v-slot:item.taal='{ item }'>
				{{ $t(`common.languages.${item.taal}`) }}
			</template>

			<template v-slot:item.acties='{ item }'>
				<v-icon class='material-icons-outlined' color='primary' @click='(event) => handleDownload(event,item)'>
					file_download
				</v-icon>
				<!--				<a :href='getBriefTemplateDocumentURL(item)'>-->
				<!--					<v-icon class='material-icons-outlined' color='primary'>-->
				<!--						file_download-->
				<!--					</v-icon>-->
				<!--				</a>-->


				<v-icon class='ml-8' color='error' @click='(event) => handleDelete(event,item)'>delete</v-icon>
			</template>
		</v-data-table>

		<cu-template v-if='cuTemplateDialog'
								 :brief-template='activeCuTemplate'
								 @submit='handleCuSubmit'
								 @cancel='handleCuCancel'
		/>
		<confirm-delete-template v-model='deleteDialog' @submit='handleSubmitDelete' />

	</div>


</template>

<script>
import {deleteBriefTemplate, getBriefTemplateDocumentURL, getBriefTemplates,} from '@/modules/correspondentiebeheer/api';
import ConfirmDeleteTemplate from '@/modules/correspondentiebeheer/dialogs/confirmDeleteTemplate';
import CuTemplate from '@/modules/correspondentiebeheer/dialogs/cuTemplate';
import {downloadByGet} from '@/modules/core/services/downloadFile';

export default {
	name: 'Brieven',
	components: { CuTemplate, ConfirmDeleteTemplate },
	mounted() {
		this.init();
	},
	data() {
		return {
			templates: [],
			options: {},
			headers: [
				{
					text: this.$t('correspondentieBeheer.brieven.table.type'),
					value: 'type',
          sort: (a, b) => a.localeCompare(b),
				},
				{
					text: this.$t('correspondentieBeheer.brieven.table.taal'),
					value: 'taal',
          sort: (a, b) => a.localeCompare(b),
				},
				{
					text: this.$t('correspondentieBeheer.brieven.table.laatstGewijzigdOp'),
					value: 'laatstGewijzigdOp',
					sort: (a, b) => a.isAfter(b) ? 1 : -1,
				},
				{
					text: this.$t('correspondentieBeheer.brieven.table.laatstGewijzigdDoor'),
					value: 'laatstGewijzigdDoor',
          sort: (a, b) => a.localeCompare(b),
				},
				{ text: '', value: 'acties', sortable: false, align: 'end' },
			],

			deleteDialog: false,
			activeDeleteItem: null,

			cuTemplateDialog: false,
			activeCuTemplate: null,
		};
	},
	computed: {},
	methods: {
		init: async function() {
			this.templates = await getBriefTemplates();
		},
		handleNewBriefTemplate: function() {
			this.cuTemplateDialog = true;
		},
		handleRowClick: function(element) {
			this.cuTemplateDialog = true;
			this.activeCuTemplate = element;
		},
		handleCuSubmit: function() {
			this.init();
			this.handleCuCancel();
		},
		handleCuCancel: function() {
			this.cuTemplateDialog = false;
			this.activeCuTemplate = null;
		},

		handleDownload: async function(event, item) {
			try {
				event.stopPropagation();
				await downloadByGet(getBriefTemplateDocumentURL(item));
				// const response = await getBriefTemplateDocument(item);
				// window.open(response, `download_${item.id}`);
			} catch (e) {

			}
		},

		handleDelete: function(event, item) {
			event.stopPropagation();
			this.deleteDialog = true;
			this.activeDeleteItem = item;
		},
		handleSubmitDelete: async function() {
			await deleteBriefTemplate(this.activeDeleteItem);
			this.init();
			this.deleteDialog = false;
			this.activeDeleteItem = null;
		},
	},
	watch: {
		deleteDialog: function() {
			if (!this.deleteDialog) {
				this.activeDeleteItem = null;
			}
		},
	},
};
</script>

<style scoped>

</style>
