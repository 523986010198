import {getQueryString, httpService, PaginationType} from '@/modules/core/services/http-service';
import {HuisArts} from "@/modules/huisarts/domains";


const mapKeysWithSortForAllDoctors = {
    voornaam: 'voornaam',
    naam: 'naam',
    adres: 'adres',
    actief:'actief',
    riziv: 'riziv'
}

export async function getAllDoctors(searchQuery: string = "", options: any): Promise<PaginationType<HuisArts>> {
    const response = await httpService.get<PaginationType<HuisArts>>(`/api/huisarts?${getQueryString(searchQuery, options, mapKeysWithSortForAllDoctors)}`)
    return {
        ...response,
        content: response.content.map((doctor: any) => new HuisArts(doctor))
    }
}

export async function getAllDoctorsNoLimits(): Promise<HuisArts[]> {
    const response = await httpService.get<any>(`/api/huisarts/all?sort=naam`)
    return response
        .map((doctorData: any) => new HuisArts(doctorData))
}

export async function getDoctor(id: string): Promise<HuisArts> {
    const response = await httpService.get<any>(`/api/huisarts/${id}`)
    return new HuisArts(response)
}


export async function createDoctor(data: any): Promise<any> {
    await httpService.post('/api/huisarts', data);
}

export async function updateDoctor(id: string, data: any): Promise<any> {
    await httpService.put(`/api/huisarts/${id}`, data);
}

export async function validateRiziv(riziv: string): Promise<boolean> {
    return await httpService.get(`/api/huisarts/validate?riziv=${riziv}`)
}
