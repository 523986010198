import Vue from "vue";

const loader = Vue.observable({ loading: false})

const LoaderPlugin = {
    install(Vue: any, options: any) {
        Vue.prototype.$loader = loader;
        Vue.prototype.$startLoading = function() {
            loader.loading = true;
        };
        Vue.prototype.$stopLoading = function() {
            loader.loading = false;
        };
    }
};

export default LoaderPlugin;
