<template>
  <v-container>
    <drukker/>
    <brieven/>
    <s-m-s/>
    <emails/>
    <huisarts />
  </v-container>
</template>

<script>
import Brieven from "@/modules/correspondentiebeheer/components/Brieven";
import Emails from "@/modules/correspondentiebeheer/components/Emails";
import Drukker from "@/modules/correspondentiebeheer/components/Drukker";
import SMS from '@/modules/correspondentiebeheer/components/SMS.vue';
import Huisarts from '@/modules/correspondentiebeheer/components/Huisarts.vue';

export default {
  name: "Overview",
  components: { SMS, Drukker, Emails, Brieven, Huisarts}
}
</script>

<style scoped>

</style>
