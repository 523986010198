<template>

	<v-form ref='form' @submit='handleSubmit'>

		<v-row>
			<v-col>
				<v-select v-model='form.type.value'
									:label='$t(form.type.label)'
									:rules='form.type.rules'
									:items='form.type.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row v-if='showDetailType'>
			<v-col>
				<v-select v-model='form.detailType.value'
									:label='$t(form.detailType.label)'
									:rules='form.detailType.rules'
									:items='form.detailType.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-select v-model='form.taal.value'
									:label='$t(form.taal.label)'
									:rules='form.taal.rules'
									:items='form.taal.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-file-input
					v-model='form.templateDocument.value'
					:label='$t(form.templateDocument.label)'
					:rules='form.templateDocument.rules'
					outlined
				/>
			</v-col>
		</v-row>

		<v-row class='mb-2'>
			<v-spacer />
			<v-btn type='button' text @click="$emit('cancel')">
				{{ $t(`correspondentieBeheer.brieven.${getCuKey}.actions.no`) }}
			</v-btn>
			<v-btn color='primary' type='submit' @click='handleSubmit'>
				{{ $t(`correspondentieBeheer.brieven.${getCuKey}.actions.yes`) }}
			</v-btn>
		</v-row>

	</v-form>

</template>

<script>
import { CuForm } from '@/modules/correspondentiebeheer/forms/templates/cuForm';
import { BriefTemplate, TemplateType } from '@/modules/correspondentiebeheer/domains';
import { saveBriefTemplate } from '@/modules/correspondentiebeheer/api';

export default {
	name: 'cuForm',
	props: {
		briefTemplate: BriefTemplate,
	},
	data() {
		return {
			form: new CuForm(this.$i18n, this.briefTemplate),
		};
	},
	computed: {
		getCuKey() {
			return this.isEditForm ? 'edit' : 'add';
		},
		showDetailType() {
			return [TemplateType.opvolging, TemplateType.opvolging_herinnering].includes(this.form.type.value);
		},
		isEditForm() {
			return Boolean(this.briefTemplate);
		},
	},
	methods: {
		handleSubmit: async function(event) {
			event.preventDefault();

			if (!this.$refs.form.validate()) {
				return;
			}

			await saveBriefTemplate(this.form.toJSON());

			this.$emit('submit');
		},
	},
};
</script>

<style scoped>

</style>