import {AxiansForm, FormItem, formRules,} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import {HuisartsTemplate} from '@/modules/correspondentiebeheer/domains';

export class HuisartsCuForm implements AxiansForm {
	content: FormItem;

	constructor(translator: VueI18n, initialData?: HuisartsTemplate) {
		this.content = new FormItem(
			'content',
			'correspondentieBeheer.sms.cuForm.text',
			initialData?.content ?? '',
			formRules.maxChars(translator, 2000),
			2000
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			content: this.content.value
		};
	}
}
