import VueI18n from 'vue-i18n';
import {AxiansForm, FormItem, formRules} from '@/modules/core/components/forms/forms.model';
import {HuisArts} from '@/modules/huisarts/domains';

export class CuHuisartsForm implements AxiansForm {
	voornaam: FormItem;
	naam: FormItem;
	adres: FormItem;
	gemeenteId: FormItem;
	riziv: FormItem;
	actief: FormItem;

	initialData?: HuisArts;

	constructor(translator: VueI18n, initialData?: HuisArts) {
		this.initialData = initialData;

		this.voornaam = new FormItem(
			'voornaam',
			'doctors.cu.voornaam',
			initialData?.voornaam ?? '',
			formRules.required(translator, 'form.required')
		);

		this.naam = new FormItem(
			'naam',
			'doctors.cu.naam',
			initialData?.naam ?? '',
			formRules.required(translator, 'form.required')
		);

		this.adres = new FormItem(
			'adres',
			'doctors.cu.adres',
			initialData?.adres ?? '',
			formRules.required(translator, 'form.required')
		);

		this.gemeenteId = new FormItem(
			'gemeenteId',
			'doctors.cu.gemeente',
			initialData?.gemeenteId ?? '',
			formRules.required(translator, 'form.required')
		);

		this.riziv = new FormItem('riziv', 'doctors.cu.riziv', initialData?.riziv ?? '');

		this.actief = new FormItem('actief', 'doctors.cu.actief', initialData?.actief ?? true);
	}

	toJSON(): { [p: string]: any } {
		return {
			voornaam: this.voornaam.value,
			naam: this.naam.value,
			adres: this.adres.value,
			gemeenteId: this.gemeenteId.value,
			riziv: this.riziv.value.length === 0 ? null : this.riziv.value,
			actief: this.actief.value,
		};
	}

	resetData(): void {
		this.voornaam.value = this.initialData?.voornaam;
		this.naam.value = this.initialData?.naam;
		this.adres.value = this.initialData?.adres;
		this.gemeenteId.value = this.initialData?.gemeenteId;
		this.riziv.value = this.initialData?.riziv;
		this.actief.value = this.initialData?.actief;
	}
}
