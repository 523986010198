import {AxiansForm, FormItem, formRules, SelectItem} from "@/modules/core/components/forms/forms.model";
import VueI18n from "vue-i18n";
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {StopzettingReason} from "@/modules/vrouw/forms/stopzettingOpvolging";

export class UitschrijvenForm implements AxiansForm {
    reden: FormItem;

    initialData?: Vrouw;

    constructor(translator: VueI18n, initialData?: Vrouw) {
        this.initialData = initialData;

        this.reden = new FormItem(
            "reden",
            'woman.stopzettingOpvolging.reason.label',
            translator.t(`woman.stopzettingOpvolging.reason.${initialData?.stopzetting?.reden}`) ?? '',
            formRules.required(translator, 'form.required'),
            -1,
            [
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.wens}`).toString(), StopzettingReason.wens),
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.onbereikbaar}`).toString(), StopzettingReason.onbereikbaar),
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.diabetes}`).toString(), StopzettingReason.diabetes)
            ]
        )
    }

    reset() {
        if (!this.initialData) {
            return
        }
    }

    toJSON(): { [p: string]: any } {
        return {};
    }

}
