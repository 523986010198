<template>

  <div>
    <v-row class='mb-3 mt-6'>
      <v-col>
        <h2>{{ $t('correspondentieBeheer.drukker.title') }}</h2>
      </v-col>
    </v-row>

    <v-data-table class='elevation-1'
                  calculate-widths
                  :headers='headers'
                  :items='exports.content'
                  :options.sync='options'
                  :footer-props="{'items-per-page-options': [100, 50, 25, 10]}"
                  :items-per-page='50'
    >
      <template v-slot:item.type='{ item }'>
        {{ $t(`correspondentieBeheer.drukker.type.${item.type}`) }}
      </template>

      <template v-slot:item.verstuurd='{ item }'>
        <v-icon class='material-icons-outlined' v-if='item.verstuurd'>
          done_outline
        </v-icon>
      </template>

      <template v-slot:item.tijdstipVerzonden='{ item }'>
        <span>{{ item.tijdstipVerzonden ? item.tijdstipVerzonden.format('DD/MM/YYYY') : '' }}</span>
      </template>

      <template v-slot:item.actions='{ item }'>
        <v-icon v-if='isDownloadingFile !== item.id && item.aantal > 0'
                class='material-icons-outlined'
                color='primary'
                @click='downloadFile(item)'>
          file_download
        </v-icon>

        <v-progress-circular v-if='isDownloadingFile === item.id' indeterminate color='primary' size='24'/>
      </template>
    </v-data-table>


  </div>

</template>

<script>
import {exportDrukkerLijst, getExportsVoorDrukker} from '@/modules/correspondentiebeheer/api';

export default {
  name: 'Drukker',
  mounted() {
    this.init();
  },
  data() {
    return {
      exports: {},
      options: {},
      headers: [
        {
          text: this.$t('correspondentieBeheer.drukker.table.type'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('correspondentieBeheer.drukker.table.verstuurd'),
          value: 'verstuurd',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('correspondentieBeheer.drukker.table.tijdstipVerzonden'),
          value: 'tijdstipVerzonden',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('correspondentieBeheer.drukker.table.aantal'), value: 'aantal',
          sortable: false, align: 'center',
        },
        {text: '', value: 'actions', sortable: false},
      ],
      isDownloadingFile: null,
    };
  },
  methods: {
    init: async function () {
      this.exports = await getExportsVoorDrukker();
    },
    downloadFile: async function (item) {
      if (this.isDownloadingFile) {
        return;
      }

      try {
        this.isDownloadingFile = item.id;
        await exportDrukkerLijst(item);
      } catch (e) {
      } finally {
        this.isDownloadingFile = null;
        this.init();
      }

    },
  },
};
</script>

<style scoped>

</style>
