import {getValueFromObject} from "@/modules/core/utils";

export class Gemeente {
    gemeenteId: string;
    postcode: number;
    naam: string;

    constructor(gemeente: any) {
        this.gemeenteId = getValueFromObject(gemeente, 'gemeenteId', "")
        this.postcode = getValueFromObject(gemeente, 'postcode', "")
        this.naam = getValueFromObject(gemeente, 'naam', "");
    }

    get fullName(): string {
        return `${this.postcode} ${this.naam}`
    }

}
