























import Vue from 'vue';
import {ProjectConfig} from '@/axians-fe.config';
import Loader from '@/modules/core/components/Loader.vue';
import Message from '@/modules/core/components/Message.vue';
import Sidebar from '@/modules/core/components/Sidebar.vue';
import Modal from '@/modules/core/modal/Modal.vue';

export default Vue.extend({
  name: 'App',
  components: {Loader, Message, Sidebar, Modal},
  created() {
  },
  data() {
    return {
      useFooter: ProjectConfig.footer,
    };
  },
  computed: {
    sideBarEnabled() {
      return this.$route?.meta?.sidebar;
    }
  }
});
