<template>
  <v-card class="pa-6">
    <v-form ref="form">
      <v-select :label="$t(form.consultatieHuisarts.label)"
                :rules="form.consultatieHuisarts.rules"
                :items="form.consultatieHuisarts.selectItems"
                v-model="form.consultatieHuisarts.value"
                outlined
      />

      <v-checkbox v-model="form.bloedglucoseGedaan.value"
                  :label="$t(form.bloedglucoseGedaan.label)"
      />

      <v-text-field v-if="form.bloedglucoseGedaan.value"
                    v-model="form.bloedglucoseWaarde.value"
                    :rules="controlRules"
                    :label="$t(form.bloedglucoseWaarde.label)"
                    :name="form.bloedglucoseWaarde.id"
                    type="number"
                    outlined
      />

      <v-radio-group v-if="form.bloedglucoseGedaan.value"
                     v-model="form.bloedglucoseResultaat.value"
                     :label="$t(form.bloedglucoseResultaat.label)"
                     :name="form.bloedglucoseResultaat.id"
                     :rules="controlRules">
        <v-radio v-for="option in form.bloedglucoseResultaat.selectItems"
                 :key="option.value"
                 :value="option.value"
                 :label="$t(option.text)"
        />
      </v-radio-group>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="saveForm">
          <v-icon>save</v-icon>
          <span class="pa-2">
            {{ $t('woman.followUp.3m.form.save') }}
          </span>
        </v-btn>
      </v-card-actions>

    </v-form>
  </v-card>
</template>

<script>
import {getNumberResultOnNumberControl, JMForm} from "@/modules/vrouw/forms/followUp/jmForm";
import {getVrouwOpvolgingResultaat, updateVrouwDetailsJm} from "@/modules/vrouw/api";
import {OpvolgingType} from "@/modules/vrouw/domains/opvolging";
import {formRules} from "@/modules/core/components/forms/forms.model";
import DateField from "@/modules/core/components/forms/DateField";

export default {
  name: "JmForm",
  components: {DateField},
  props: {
    womanId: String,
    type: OpvolgingType
  },
  data() {
    return {
      formData: null,
      form: new JMForm(this.$i18n)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: async function () {
      this.formData = await getVrouwOpvolgingResultaat(this.womanId, this.type);

      if (this.formData) {
        this.form = new JMForm(this.$i18n, this.formData)
      }
    },
    saveForm: async function () {
      if (this.$refs.form.validate()) {
        await updateVrouwDetailsJm(this.womanId, this.type, this.form.toJSON())
        this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.followUp.saved');
        this.$message.show = true;
      }
    }
  },
  computed: {
    controlRules() {
      if (!this.form.bloedglucoseGedaan.value || this.form.bloedglucoseWaarde.value || this.form.bloedglucoseResultaat.value) {
        return []
      }
      return formRules.required(this.$i18n, 'woman.followUp.jm.form.controlDone.required')
    },
    helperNumberControl() {
      return this.form.bloedglucoseWaarde.value
    }
  },
  watch: {
    helperNumberControl() {
      if (!this.helperNumberControl) {
        return;
      }
      this.form.bloedglucoseResultaat.value = getNumberResultOnNumberControl(this.helperNumberControl);
    }
  }
}
</script>

<style scoped>
</style>