<template>

  <div>
    <v-row class="mb-3 mt-6">
      <v-col>
        <h2>{{ $t('correspondentieBeheer.emails.title') }}</h2>
      </v-col>
    </v-row>

    <v-btn color="primary" :href="get_link">
      {{ $t('correspondentieBeheer.emails.link') }}
    </v-btn>

  </div>

</template>

<script>

const LINK_TO_MAILCHIMP_TEMPLATES = 'https://admin.mailchimp.com/templates'

export default {
  name: "Emails",
  computed: {
    get_link: function () {
      return LINK_TO_MAILCHIMP_TEMPLATES
    }
  }
}
</script>

<style scoped>

</style>
