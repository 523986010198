import {AxiansForm, FormItem, formRules, SelectItem,} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import {TemplateType} from '@/modules/bpmbeheer/domains';

export class UpdateTemplateForm implements AxiansForm {
	type: FormItem;
	file: FormItem;

	constructor(translator: VueI18n, initialData?: any) {
		this.type = new FormItem(
			'type',
			'bpm.form.type',
			initialData?.type ?? '',
			formRules.required(translator, 'form.required'),
			-1,
			[new SelectItem(translator.t('bpm.types.mediakanaal').toString(), TemplateType.mediakanaal)]
		);
		this.file = new FormItem(
			'file',
			'bpm.form.file',
			null,
			formRules.fileAndRequired(translator, ['.dmn'])
		);
	}

	toJSON(): { [p: string]: any } {
		return {};
	}
}
