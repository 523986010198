import {AxiansForm, FormItem, formRules, SelectItem} from "@/modules/core/components/forms/forms.model";
import VueI18n from "vue-i18n";

export enum StopzettingReason {
    wens = "wens",
    onbereikbaar = "onbereikbaar",
    diabetes = "diabetes"
}

export class StopzettingOpvolgingForm implements AxiansForm {

    reason: FormItem;

    constructor(translator: VueI18n) {
        this.reason = new FormItem(
            'reason',
            'woman.stopzettingOpvolging.reason.label',
            "",
            formRules.required(translator, 'form.required'),
            -1,
            [
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.wens}`).toString(), StopzettingReason.wens),
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.onbereikbaar}`).toString(), StopzettingReason.onbereikbaar),
                new SelectItem(translator.t(`woman.stopzettingOpvolging.reason.${StopzettingReason.diabetes}`).toString(), StopzettingReason.diabetes)
            ]
        )
    }

    toJSON(): { [p: string]: any } {
        return {};
    }

}
