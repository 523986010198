import {getQueryString, httpService, PaginationType} from '@/modules/core/services/http-service';
import {Diabetesteam} from "@/modules/diabetesteam/domains";


const mapKeysWithSortForAllDiabeteicTeams = {
    naam: 'naam',
    email: 'email',
    actief: 'actief'
}

export async function getAllDiabeticTeams(searchQuery: string = "", options: any): Promise<PaginationType<Diabetesteam>> {
    const response = await httpService.get<PaginationType<Diabetesteam>>(`/api/diabetesteam?${getQueryString(searchQuery, options, mapKeysWithSortForAllDiabeteicTeams)}`)
    return {
        ...response,
        content: response.content.map((diabeticTeam: any) => new Diabetesteam(diabeticTeam))
    }
}

export async function getAllDiabeticTeamsNoLimits(): Promise<Diabetesteam[]> {
    const response = await httpService.get<any>(`/api/diabetesteam/all?sort=naam`)
    return response
        .map((diabeticTeamData: any) => new Diabetesteam(diabeticTeamData))
}

export async function getDiabeticTeam(id: string): Promise<Diabetesteam> {
    const response = await httpService.get<any>(`/api/diabetesteam/${id}`)
    return new Diabetesteam(response)
}

export async function createDiabeticTeam(data: any): Promise<any> {
    await httpService.post('/api/diabetesteam', data);
}

export async function updateDiabeticTeam(id: string, data: any): Promise<any> {
    await httpService.put(`/api/diabetesteam/${id}`, data);
}
