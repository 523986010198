import colors from 'vuetify/lib/util/colors';

const axians_purple = '#a30368';
const axians_blue = '#005eb8';

export enum Theme {
    AXIANS = 'Axians',
    CUSTOM = 'Custom'
};

const axiansTheme = {
    id: Theme.AXIANS,
    primary: '#00B1EB',
    secondary: "#F2994A",
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    axians_purple: '#a30368',
    axians_blue: '#005eb8',
    background: '#f7f8fb',
    text: '#1E1E1E',
    text_light: '#828282',
    green: '#219653',
    blue: '#2F80ED',
    grey4: '#BDBDBD',
};

export const getTheme = () => {
    return axiansTheme;
};

