import VueI18n from "vue-i18n";
import {FormItem} from "@/modules/core/components/forms/forms.model";
import {HuisArts} from "@/modules/huisarts/domains";
import {DetailsForm} from "@/modules/vrouw/forms/details/detailsForm";

export class DoctorForm extends DetailsForm<HuisArts> {
    naam: FormItem;
    gemeenteId: FormItem;

    huisartsId: FormItem;

    constructor(translator: VueI18n, initialData?: HuisArts) {
        super(initialData);

        this.naam = new FormItem(
            "name",
            'woman.details.doctor.name',
            initialData?.naam ?? ''
        )

        this.gemeenteId = new FormItem(
            "city",
            'woman.details.doctor.city',
            initialData?.gemeenteId ?? ''
        )

        this.huisartsId = new FormItem(
            "doctorId",
            'woman.details.doctor.header',
            initialData?.huisartsId ?? ''
        )
    }

    public resetData(): void {
        this.huisartsId.value = this.initialData?.huisartsId ?? '';
        this.naam.value = this.initialData?.naam ?? '';
        this.gemeenteId.value = this.initialData?.gemeenteId ?? '';
    }

    toJSON(): { [p: string]: any } {
        return {
            doctor: this.huisartsId.value
        };
    }
}
