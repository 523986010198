import {AxiansForm, FormItem, formRules} from "@/modules/core/components/forms/forms.model";
import VueI18n from "vue-i18n";
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import moment from "moment/moment";

export class RegForm implements AxiansForm {
    vermoedelijkeBevallingsDatum: FormItem;
    heightBefore: FormItem;
    weightBefore: FormItem;
    initialData?: Vrouw;

    constructor(translator: VueI18n, initialData?: Vrouw) {
        this.initialData = initialData;
        this.vermoedelijkeBevallingsDatum = new FormItem(
            "deliveryDate",
            'woman.cu.form.deliveryDate',
            initialData?.vermoedelijkeBevallingsDatum.format('YYYY-MM-DD') ?? '',
            formRules.datumIsRequired(translator),
        )

        this.heightBefore = new FormItem(
            "heightBefore",
            'woman.cu.form.heightBefore',
            initialData?.length ?? '',
            formRules.numberBetween(translator, 0, 3)
        )

        this.weightBefore = new FormItem(
            "weightBefore",
            'woman.cu.form.weightBefore',
            initialData?.gewicht ?? '',
            formRules.numberBetween(translator, 0, 500)
        )
    }

    reset() {
        if (!this.initialData) {
            return
        }
        this.vermoedelijkeBevallingsDatum.value = this.initialData.vermoedelijkeBevallingsDatum.format('YYYY-MM-DD') ?? ''
        this.heightBefore.value = this.initialData.length
        this.weightBefore.value = this.initialData.gewicht
    }

    toJSON(): { [p: string]: any } {
        return {
            deliveryDate: moment(this.vermoedelijkeBevallingsDatum.value).format("DD/MM/YYYY"),
            heightBefore: this.heightBefore.value,
            weightBefore: this.weightBefore.value,
        };
    }

}