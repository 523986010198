import moment from "moment/moment";
import {getValueFromObject} from "@/modules/core/utils";
import {getOpvolgingType, OpvolgingType} from "@/modules/vrouw/domains/opvolging";

type BaseLogType = VrouwLogType | OpvolgingLogType | MediaLogType | RegistratieLogType | UitschrijvenLogType | string;

export abstract class BaseLog {
    id: string
    logType: BaseLogType;
    details: any;
    timestamp: moment.Moment;
    legacy: boolean;
    taskId: string;
    checkTask: boolean;
    processInstanceId: string;
    communicatie_type: string;
    detail_type: string;

    protected constructor(data: any) {
        let foundDetails = getValueFromObject(data, 'details', {});
        this.id = getValueFromObject(data, 'logId', '')
        this.details = Object.fromEntries(
            Object.entries(foundDetails)
                .filter(([key, _]) => key !== 'communicatie_type')
                .filter(([key, _]) => key !== 'opvolging_type')
                .filter(([key, _]) => key !== 'registratie_type')
                .filter(([key, _]) => key !== 'onbereikbaar_type')
        );
        this.timestamp = moment(getValueFromObject(data, 'timestamp', moment()));
        this.legacy = getValueFromObject(data, 'legacy', '');
        this.taskId = getValueFromObject(data, 'taskId', '');
        this.checkTask = getValueFromObject(data, 'checkTask', false);
        this.processInstanceId = getValueFromObject(data, 'processInstanceId', '');
        this.logType = this._parseLogType(getValueFromObject(data, 'logType', ''));

        this.communicatie_type = foundDetails["communicatie_type"]
        this.detail_type = foundDetails["opvolging_type"] || foundDetails["registratie_type"] || foundDetails["onbereikbaar_type"]

    }

    protected abstract _parseLogType(data: any): BaseLogType;

}


export enum VrouwLogType {
    registratie = "registratie",
    bevallen = "bevallen",
    opvolging3m = "opvolging3m",
    opvolgingJaarlijks = "opvolgingJaarlijks",
    uitschrijven = "uitschrijven"
}

export class VrouwLog extends BaseLog {
    opvolgingType: OpvolgingType;

    constructor(data: any) {
        super(data)
        this.opvolgingType = getOpvolgingType(getValueFromObject(data, 'details.opvolging_type', ''));
    }

    protected _parseLogType(type: string): BaseLogType {
        switch (type) {
            case "opvolging3m":
                return VrouwLogType.opvolging3m
            case "opvolgingJaarlijks":
                return VrouwLogType.opvolgingJaarlijks;
            case 'registratie':
                return VrouwLogType.registratie
            case 'bevallen':
                return VrouwLogType.bevallen
            case 'uitschrijven':
                return VrouwLogType.uitschrijven
            default:
                return type
        }
    }
}


export enum MediaLogType {
    unknown = "unknown",
    email = "email",
    drukker = "drukker",
    bellen = "bellen",
    sms = "sms",
    brief = "brief",
    geen_media_gevonden = "geen_media_gevonden"
}

function parseMediaLogType(type: string): BaseLogType {

    switch (type) {
        case "unknown": {
            return MediaLogType.unknown
        }
        case "email": {
            return MediaLogType.email
        }
        case "drukker": {
            return MediaLogType.drukker
        }
        case "bellen": {
            return MediaLogType.bellen
        }
        case "sms": {
            return MediaLogType.sms
        }
        case "brief": {
            return MediaLogType.brief
        }
        case "geen_media_gevonden":
            return MediaLogType.geen_media_gevonden;
        default: {
            return type;
        }
    }
}

export enum OpvolgingLogType {
    feedback_ontvangen = "feedback_ontvangen",
    diabetes = "diabetes",
}


export class OpvolgingLog extends BaseLog {
    constructor(data: any) {
        super(data);
    }

    protected _parseLogType(data: any): BaseLogType {
        switch (data) {
            case 'feedback_ontvangen':
                return OpvolgingLogType.feedback_ontvangen
            case 'diabetes':
                return OpvolgingLogType.diabetes
            default:
                return parseMediaLogType(data)
        }
    }
}


export enum RegistratieLogType {
    registratie_ingegeven = "registratie_ingegeven",
    dubbele_registratie_check = "dubbele_registratie_check",
    geregistreerd = "geregistreerd",
    huisarts_verwittigd = "huisarts_verwittigd",
    diabetesteam_verwittigd = "diabetesteam_verwittigd"
}

export class RegistratieLog extends BaseLog {

    constructor(data: any) {
        super(data);
    }

    protected _parseLogType(data: any): BaseLogType {
        switch (data) {
            case 'registratie_ingegeven':
                return RegistratieLogType.registratie_ingegeven
            case 'dubbele_registratie_check':
                return RegistratieLogType.dubbele_registratie_check
            case 'geregistreerd':
                return RegistratieLogType.geregistreerd
            case 'huisarts_verwittigd':
                return RegistratieLogType.huisarts_verwittigd;
            case 'diabetesteam_verwittigd':
                return RegistratieLogType.diabetesteam_verwittigd;
            default:
                return parseMediaLogType(data)
        }
    }

}


export enum UitschrijvenLogType {
    uitschrijven_detail = "uitschrijven_detail"
}

export class UitschrijvenLog extends BaseLog {

    constructor(data: any) {
        super(data);
    }

    protected _parseLogType(data: any): BaseLogType {
        switch (data) {

            case 'uitschrijven_detail':
                return UitschrijvenLogType.uitschrijven_detail;
            default:
                return parseMediaLogType(data)
        }
    }

}
