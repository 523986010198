<template>

	<v-form ref='cuForm'
					style='max-width: 900px;margin: auto;'
					@submit='handleSubmit'>

		<v-row>
			<v-col>
				<v-text-field v-model='cuForm.voornaam.value'
											:label='$t(cuForm.voornaam.label)'
											:rules='cuForm.voornaam.rules'
											:readonly='readonly'
											outlined
				/>
			</v-col>

			<v-col>
				<v-text-field v-model='cuForm.naam.value'
											:label='$t(cuForm.naam.label)'
											:rules='cuForm.naam.rules'
											:readonly='readonly'
											outlined
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-text-field v-model='cuForm.riziv.value'
											:label='$t(cuForm.riziv.label)'
											:rules='rizivRules'
											:readonly='readonly'
											outlined
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-text-field v-model='cuForm.adres.value'
											:label='$t(cuForm.adres.label)'
											:rules='cuForm.adres.rules'
											:readonly='readonly'
											outlined
				/>
			</v-col>
			<v-col>
				<default-select type='city'
												v-model='cuForm.gemeenteId.value'
												:label='cuForm.gemeenteId.label'
												:rules='cuForm.gemeenteId.rules'
												:readonly-outlined='readonly'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-switch v-model='cuForm.actief.value'
									:label='$t(cuForm.actief.label)'
									:rules='cuForm.actief.rules'
									:readonly='readonly'
									outlined />
			</v-col>
		</v-row>

		<v-row v-if='!readonly'>
			<v-col>
				<v-card-actions>
					<v-spacer />
					<v-btn type='button' @click='handleReset'>
						{{ $t('doctors.cu.actions.cancel') }}
					</v-btn>
					<v-btn color='primary' type='submit' style='min-width: 200px;'>
						{{ $t('doctors.cu.actions.submit') }}
					</v-btn>
				</v-card-actions>

			</v-col>
		</v-row>

	</v-form>

</template>

<script>
import {CuHuisartsForm} from '@/modules/huisarts/forms/cuHuisarts';
import {HuisArts} from '@/modules/huisarts/domains';
import DefaultSelect from '@/modules/core/components/selects/defaultSelect';
import {createDoctor, updateDoctor, validateRiziv} from '@/modules/huisarts/api';

export default {
	name: 'CuHuisarts',
	components: { DefaultSelect },
	props: {
		huisArts: HuisArts | null,
		readonly: Boolean,
	},
	data() {
		return {
			cuForm: new CuHuisartsForm(this.$i18n, this.huisArts),
			isRizivCorrect: false,
			formValidated: false,
		};
	},
	computed: {
		rizivRules() {
			if (!this.formValidated || !this.cuForm.riziv.value) {
				return [];
			}


			return this.isRizivCorrect ? [] : [() => this.$t('form.riziv')];
		},
	},
	methods: {
		handleReset() {
			this.$refs.cuForm.resetValidation();
			this.cuForm.resetData();
			this.$emit('cancel');
		},
		async handleSubmit(event) {
			try {
				event.preventDefault();

				this.formValidated = false;

				this.$nextTick(async () => {
					if (!this.$refs.cuForm.validate()) {
						console.log('SOMETHING WENT WRONG');
						return;
					}

					this.formValidated = true;

					if (this.cuForm.riziv.value) {
						this.isRizivCorrect = await validateRiziv(this.cuForm.riziv.value);

						if (!this.isRizivCorrect) {
							return;
						}
					}

					if (this.huisArts) {
						await updateDoctor(this.huisArts.huisartsId, this.cuForm.toJSON());
					} else {
						await createDoctor(this.cuForm.toJSON());
					}
					this.$emit('submit');
				});
			} catch (e) {
			}
		},
	},
};
</script>

<style scoped lang='scss'>
::v-deep .v-input--is-readonly {
	&.primary--text, .primary--text {
		color: rgba(0, 0, 0, 0.38) !important;
	}

	fieldset {
		border-width: 1px;
	}

	.v-text-field__slot, .v-select__slot {
		label {
			color: rgba(0, 0, 0, 0.6) !important;
		}
	}
}
</style>
