import {getValueFromObject} from "@/modules/core/utils";

export class Diabetesteam {
    diabetesteamId: string;
    naam: string;
    email: string;
    actief: boolean;

    constructor(diabeticTeam: any) {
        this.diabetesteamId = getValueFromObject(diabeticTeam, 'diabetesteamId', "")
        this.naam = getValueFromObject(diabeticTeam, 'naam', "")
        this.email = getValueFromObject(diabeticTeam, 'email', "");
        this.actief = diabeticTeam.hasOwnProperty('actief') ? diabeticTeam.actief : getRandomIsActief();    }
}

function getRandomIsActief(): boolean {
    return (Math.floor(Math.random() * 10) + 1) % 2 === 0;
}
