<template>

  <v-container class='container woman-details' fluid v-if="woman">
    <HeaderContainer :back-text="$t('woman.details.header.overview.back').toString()"
                     :label-text="$t('woman.details.header.overview.label').toString()"
                     :woman="woman"
                     :prev-route-should-be="thisPrevRoute">
      <jaarlijkse-opvolging-table :woman="woman"/>
      <v-btn color="primary"
             outlined
             small
             class="header-btn"
             @click="handleDownload">
        <v-icon class="material-icons-outlined">download</v-icon>
        {{ $t("woman.details.header.download") }}
      </v-btn>
      <stopzetting-opvolging v-if="!isStopgezet" :vrouw-id="woman.vrouwId" @submit="handleStopzetting"/>
    </HeaderContainer>
    <v-row>
      <v-col md="5">
        <woman-details :woman="woman" @update-completed="fetchWomanDetails"/>
      </v-col>

      <time-line-container>
        <time-line>
          <time-line-item v-for="item in logs.slice().reverse()"
                          :item="item"
                          :key="item.id"
                          :woman="woman"
                          :bpm-info="findBpmInfo(item)"
                          :polling-seconds="pollingSeconds"
                          @clickItem="handleLogClick"
          />
        </time-line>
      </time-line-container>

    </v-row>
    <polling :poll-func="startPolling" :seconds="pollingSeconds"/>
  </v-container>

</template>

<script>
import {getVrouwDoorId, getVrouwLogs} from "@/modules/vrouw/api";
import HeaderContainer from "@/modules/vrouw/components/headerContainer";
import TimeLine from "@/modules/vrouw/components/TimeLine";
import TimeLineItem from "@/modules/vrouw/components/TimeLineItem.vue";
import {ROUTES} from "@/modules/core/router/routes";
import TimeLineContainer from "@/modules/vrouw/components/TimeLineContainer";
import WomanDetails from "@/modules/vrouw/components/WomanDetails";
import {OpvolgingType} from "@/modules/vrouw/domains/opvolging";
import {VrouwLogType} from "@/modules/vrouw/domains/logs";
import JaarlijkseOpvolgingTable from "@/modules/vrouw/dialogs/JaarlijkseOpvolgingTable";
import {getVrouwLogsActief} from "@/modules/camunda/getVrouwLogsActief";
import StopzettingOpvolging from "@/modules/vrouw/dialogs/StopzettingOpvolging";
import Polling from "@/modules/core/components/Polling";
import {mapState} from "vuex";
import {getEnv, sleep} from "@/modules/core/utils";
import {downloadByGet} from "@/modules/core/services/downloadFile";


export default {
  name: "Details",
  components: {
    Polling,
    StopzettingOpvolging,
    JaarlijkseOpvolgingTable,
    WomanDetails, TimeLineContainer, TimeLine, TimeLineItem, HeaderContainer
  },
  data() {
    return {
      woman: null,
      logs: [],
      activeLogs: [],
      isStopgezet: false,
      thisPrevRoute: {name: ROUTES.WOMAN.name}
    }
  },
  mounted() {
    this.fetchWomanDetails();
  },
  computed: {
    ...mapState('route', ['prevRoute']),
    pollingSeconds() {
      try {
        const pollingMilliseconds = Number(getEnv("VUE_APP_POLLING_DOSSIER_DETAILS", 5000))
        return pollingMilliseconds / 1000;
      } catch (e) {
        return 5;
      }
    }
  },
  methods: {
    fetchWomanDetails: async function () {
      try {
        const id = this.$route.params.id;
        this.woman = await getVrouwDoorId(id);
        this.isStopgezet = Boolean(this.woman.stopzetting);
        this.logs = await getVrouwLogs(id);
        this.activeLogs = await getVrouwLogsActief(id)
      } catch (e) {
        if (e.find(err => err.code === 404)) {
          this.$message.color = "#ff5252";
          this.$message.text = this.$t('woman.search.not-found')
          this.$message.show = true;
          await sleep(2);
          this.handleGoBack();
        }
        console.log(e);
      }
    },
    handleLogClick: function (item, taskId, event) {
      let newRoute = undefined
      if (item.logType === VrouwLogType.opvolging3m) {
        newRoute = {
          name: ROUTES.WOMAN_DETAILS_FOLLOWUP.name,
          params: {womanId: this.woman.vrouwId, type: OpvolgingType.M3}
        }
      } else if (item.logType === VrouwLogType.opvolgingJaarlijks) {
        newRoute = {
          name: ROUTES.WOMAN_DETAILS_FOLLOWUP.name,
          params: {womanId: this.woman.vrouwId, type: item.opvolgingType}
        }
      } else if (item.logType === VrouwLogType.registratie) {
        newRoute = {
          name: ROUTES.WOMAN_DETAILS_REGISTRATION.name,
          params: {womanId: this.woman.vrouwId, type: VrouwLogType.registratie, woman: this.woman}
        }
      } else if (item.logType === VrouwLogType.uitschrijven) {
        newRoute = {
          name: ROUTES.WOMAN_DETAILS_UITSCHRIJVEN.name,
          params: {womanId: this.woman.vrouwId, type: VrouwLogType.uitschrijven, woman: this.woman}
        }
      }

      if (newRoute) {
        if (event.ctrlKey) {
          const route = this.$router.resolve(newRoute);
          window.open(route.href, "_blank");
        } else {
          this.$router.push(newRoute)
        }
    }

  },
  handleDownload: async function () {
      await downloadByGet(`/api/vrouw/${this.woman.vrouwId}/export`)
  },
  findBpmInfo: function (item) {
    return this.activeLogs.find(i => {

      if (item.logType === VrouwLogType.opvolging3m) {
        return i.logType === OpvolgingType.M3;
      }

      if (item.logType === VrouwLogType.opvolgingJaarlijks) {
        return i.logType === item.opvolgingType;
      }

      return i.logType === item.logType
    })
  },
  startPolling: async function () {
    const id = this.$route.params.id;
    this.logs = await getVrouwLogs(id);
    this.activeLogs = await getVrouwLogsActief(id);
  },
  handleStopzetting: function () {
    this.isStopgezet = true;
  },
  handleGoBack: function () {
    if (this.prevRoute.name === this.thisPrevRoute.name) {
      this.$router.back();
    } else {
      this.$router.replace(this.thisPrevRoute)
    }
  },
}
}
</script>

<style scoped lang="scss">

.container--fluid {
  max-width: 2345px;
}

::v-deep .header-btn {
  padding: 8px !important;
  margin-left: 8px;

  .v-icon {
    padding-right: 12px;
  }
}

.woman-details {

}


</style>
