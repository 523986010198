<template>
  <v-dialog max-width="960" v-model="dialog">
    <v-card>
      <v-card-title>
        <h4>{{ $t(`correspondentieBeheer.brieven.${getCuKey}.title`) }}</h4>
        <v-spacer/>
        <v-icon @click="handleCancel">close</v-icon>
      </v-card-title>

      <v-card-text class="mt-8">
        <cu-form :brief-template="briefTemplate" @submit='handleSubmit' @cancel='handleCancel'/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {BriefTemplate} from "@/modules/correspondentiebeheer/domains";
import CuForm from "@/modules/correspondentiebeheer/forms/templates/cuForm.vue";

export default {
  name: "CuTemplate",
  components: {CuForm},
  props: {
    briefTemplate: BriefTemplate | null,
  },
  data() {
    return {
      dialog: true
    }
  },
  computed: {
    getCuKey() {
      return Boolean(this.briefTemplate) ? 'edit' : 'add';
    }
  },
  methods: {
    handleCancel: function () {
      this.$emit('cancel')
    },
    handleSubmit: function () {
      this.$emit('submit')
    }
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.handleCancel();
      }
    }
  }
}
</script>

<style scoped>

</style>