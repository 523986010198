<template>
	<v-snackbar
		v-model='$message.show'
		:bottom="y === 'bottom'"
		:color='$message.color'
		:left="x === 'left'"
		:multi-line="mode === 'multi-line'"
		:right="x === 'right'"
		:timeout='timeout'
		:top="y === 'top'"
		:vertical="mode === 'vertical'"
	>
		{{ $message.text }}
	</v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
	name: 'Message',
	data() {
		return {
			mode: '',
			timeout: 5000,
			x: 'right',
			y: 'bottom',
		};
	},
	computed: {
		...mapState('error', ['errors']),
	},
	watch: {
		errors: function() {
			if (this.errors.length) {
				this.$message.color = '#ff5252';
				this.$message.text = this.$te(this.errors[0].message.message)? this.$t(this.errors[0].message.message): this.errors[0].message.message;
				this.$message.show = true;
			}
		},
		'$message.show': function() {
			if (!this.$message.show) {
				this.$store.commit('error/CLEAR_ERRORS');
			}
		},
	},
};
</script>

<style scoped>

</style>
