import VueI18n from "vue-i18n";
import {FormItem, formRules} from "@/modules/core/components/forms/forms.model";
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {DetailsForm} from "@/modules/vrouw/forms/details/detailsForm";

export class CommentForm extends DetailsForm<Vrouw> {
    comment: FormItem;

    constructor(translator: VueI18n, initialData?: Vrouw) {
        super(initialData);

        this.comment = new FormItem(
            "comment",
            '',
            initialData?.comment ?? ''
        )

    }

    public resetData(): void {
        if (!this.initialData) {
            return
        }
        this.comment.value = this.initialData.comment;
    }

    toJSON(): { [p: string]: any } {
        return {
            comment: this.comment.value
        };
    }
}