<template>

  <v-form ref="cuForm"
          style="max-width: 900px;margin: auto;"
          @submit="handleSubmit">

    <v-row>
      <v-col>
        <v-text-field v-model="cuForm.naam.value"
                      :label="$t(cuForm.naam.label)"
                      :rules="cuForm.naam.rules"
                      :readonly="readonly"
                      outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field v-model="cuForm.email.value"
                      :label="$t(cuForm.email.label)"
                      :rules="cuForm.email.rules"
                      :readonly="readonly"
                      outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="cuForm.actief.value"
                  :label="$t(cuForm.actief.label)"
                  :rules="cuForm.actief.rules"
                  :readonly="readonly"
                  outlined/>
      </v-col>
    </v-row>
        <v-row v-if="!readonly">
      <v-col>
        <v-card-actions>
          <v-spacer/>
          <v-btn type="button" @click="handleReset">
            {{ $t('diabeticTeams.cu.actions.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" style="min-width: 200px;">
            {{ $t('diabeticTeams.cu.actions.submit') }}
          </v-btn>
        </v-card-actions>

      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import {CuDiabetesForm} from "@/modules/diabetesteam/forms/cuDiabetes";
import DefaultSelect from "@/modules/core/components/selects/defaultSelect";
import {Diabetesteam} from "@/modules/diabetesteam/domains";
import {createDiabeticTeam, updateDiabeticTeam} from "@/modules/diabetesteam/api";

export default {
  name: "CuDiabetes",
  components: {DefaultSelect},
  props: {
    diabetesTeam: Diabetesteam | null,
    readonly: Boolean
  },
  data() {
    return {
      cuForm: new CuDiabetesForm(this.$i18n, this.diabetesTeam)
    }
  },
  methods: {
    handleReset() {
      this.$refs.cuForm.resetValidation();
      this.cuForm.resetData();
      this.$emit('cancel')
    },
   async handleSubmit(event) {
      try {
      event.preventDefault();

      if (!this.$refs.cuForm.validate()) {
        return;
      }

      if (this.diabetesTeam) {
        await updateDiabeticTeam(this.diabetesTeam.diabetesteamId, this.cuForm.toJSON())
      } else {
        await createDiabeticTeam(this.cuForm.toJSON())
      }
      this.$emit('submit')
    } catch (e) {
    }

    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-input--is-readonly {
  &.primary--text, .primary--text {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  fieldset {
    border-width: 1px;
  }

  .v-text-field__slot, .v-select__slot {
    label {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
</style>
