<template>
  <v-dialog max-width="600" v-model="dialog">
    <v-card>
      <v-card-title>
        <h4>{{ $t('correspondentieBeheer.sms.delete.title') }}</h4>
        <v-spacer/>
        <v-icon @click="handleCancel">close</v-icon>
      </v-card-title>

      <v-card-text class="mt-8">
        {{ $t('correspondentieBeheer.sms.delete.content') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="handleCancel">
          {{ $t('correspondentieBeheer.sms.delete.actions.no') }}
        </v-btn>
        <v-btn color="error" @click="handleSubmit">
          {{ $t('correspondentieBeheer.sms.delete.actions.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { deleteSMSTemplate } from '@/modules/correspondentiebeheer/api';

export default {
  name: "confirmDeleteSMSTemplate",
  props: {
    value: false,
		activeTemplate: null
  },
  data() {
    return {
      dialog: this.value
    }
  },
  methods: {
    handleCancel: function () {
      this.$emit('input', false)
    },
    handleSubmit: async function () {
			await deleteSMSTemplate(this.activeTemplate)
      this.$emit('submit')
    }
  },
  watch: {
    value: function () {
      this.dialog = this.value;
    },
    dialog: function () {
      if (!this.dialog) {
        this.handleCancel();
      }
    }
  }
}
</script>

<style scoped>

</style>