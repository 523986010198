import moment from 'moment';
import { getValueFromObject } from '@/modules/core/utils';

export enum TemplateType {
	mediakanaal = 'mediakanaal',
	niet_gekend = 'niet_gekend',
}

function getTemplateType(type: string): TemplateType {
	switch (type) {
		case 'mediakanaal':
			return TemplateType.mediakanaal;
		default:
			return TemplateType.niet_gekend;
	}
}

export class BPMFile {
	id: string;
	type: TemplateType;
	laatstGewijzigdOp: moment.Moment | string;
	laatstGewijzigdDoor: string;
	link: string;
	source: string;

	constructor(data: any) {
		this.id = getValueFromObject(data, 'id', '');
		this.type = getTemplateType(getValueFromObject(data, 'type', ''));

		const laatstGewijzigdOp = getValueFromObject(data, 'laatstGewijzigdOp', '');
		this.laatstGewijzigdOp = laatstGewijzigdOp ? moment(laatstGewijzigdOp) : '';

		this.laatstGewijzigdDoor = getValueFromObject(data, 'laatstGewijzigdDoor', '');
		this.link = getValueFromObject(data, 'link', '');
		this.source = getValueFromObject(data, 'source', '');
	}
}
