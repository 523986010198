import {ControlOptions} from "@/modules/vrouw/forms/followUp/3mForm";
import moment from "moment/moment";
import {getValueFromObject} from "@/modules/core/utils";

export enum OpvolgingType {
    M3 = "3m",
    J1 = "1j",
    J2 = "2j",
    J3 = "3j",
    J4 = "4j",
    J5 = "5j",
    J6 = "6j",
    J7 = "7j",
    J8 = "8j",
    J9 = "9j",
    J10 = "10j",
    nietGekend = "niet_gekend"
}

export function getOpvolgingType(type: string): OpvolgingType {
    switch (type) {
        case "3m":
            return OpvolgingType.M3;
        case "1j":
            return OpvolgingType.J1;
        case "2j":
            return OpvolgingType.J2;
        case "3j":
            return OpvolgingType.J3;
        case "4j":
            return OpvolgingType.J4;
        case "5j":
            return OpvolgingType.J5;
        case "6j":
            return OpvolgingType.J6;
        case "7j":
            return OpvolgingType.J7;
        case "8j":
            return OpvolgingType.J8;
        case "9j":
            return OpvolgingType.J9;
        case "10j":
            return OpvolgingType.J10;
        default:
            return OpvolgingType.nietGekend
    }
}

export enum consultatieHuisartsType {
    Ja = "j",
    Nee = "n",
    NeeMaarGepland = "gepland",
    NeeWantZwanger = "zwanger",
    GeenResultaat = "geen_result"
}

export enum NumberResultOptions {
    NORMAL = "N",
    CRAZY = "GNG",
    DIABETIC = "DM",
    NIET_GEKEND = ""
}

export class JaarlijkseOpvolging {
    opvolgingType: OpvolgingType;
    year: number;
    consultatieHuisarts: consultatieHuisartsType;
    bloedglucoseWaarde: boolean;
    naBevallingBloedafname: ControlOptions;
    bloedglucoseResultaat: NumberResultOptions;
    communicatieVerstuurd: moment.Moment | null;
    antwoordOp: moment.Moment | null;

    constructor(followUp: any) {
        this.opvolgingType = getOpvolgingType(getValueFromObject(followUp, 'opvolgingType'));
        this.consultatieHuisarts = this._getConsultatieDokterType(getValueFromObject(followUp, 'consultatieHuisarts', ""));
        this.bloedglucoseWaarde = getValueFromObject(followUp, 'bloedglucoseWaarde', 0);
        this.naBevallingBloedafname = this._getControlType(getValueFromObject(followUp, 'naBevallingBloedafname', ""));
        this.bloedglucoseResultaat = this._getNumberResultOption(getValueFromObject(followUp, 'bloedglucoseResultaat', ""));

        const communicatieVerstuurd = getValueFromObject(followUp, 'communicatieVerstuurd', null)
        this.communicatieVerstuurd = communicatieVerstuurd ? moment(communicatieVerstuurd) : null;

        const antwoordOp = getValueFromObject(followUp, 'antwoordOp', null)
        this.antwoordOp = antwoordOp ? moment(antwoordOp) : null;

        const year = getValueFromObject(followUp, 'startDatumOpvolging', null)
        this.year = +moment(year).format("YYYY");
    }

    get isScreeningHuisArts(): boolean{
        return this.consultatieHuisarts === consultatieHuisartsType.Ja;
    }

    private _getConsultatieDokterType(type: string): consultatieHuisartsType {
        switch (type) {
            case "j":
                return consultatieHuisartsType.Ja
            case "n":
                return consultatieHuisartsType.Nee
            case "gepland":
                return consultatieHuisartsType.NeeMaarGepland
            case "zwanger":
                return consultatieHuisartsType.NeeWantZwanger
            default:
                return consultatieHuisartsType.GeenResultaat

        }
    }

    private _getControlType(controlType: string): ControlOptions {
        switch (controlType) {
            case 'J':
                return ControlOptions.YES
            case 'N':
                return ControlOptions.NO
            case 'Borstvoeding':
                return ControlOptions.NO_BREASTFEEDING
            case 'Gepland':
                return ControlOptions.NO_PLANNED
            default:
                return ControlOptions.NIET_GEKEND
        }
    }

    private _getNumberResultOption(numberResult: string): NumberResultOptions {
        switch (numberResult) {
            case "N":
                return NumberResultOptions.NORMAL;
            case "GNG":
                return NumberResultOptions.CRAZY;
            case "DM":
                return NumberResultOptions.DIABETIC;

            default:
                return NumberResultOptions.NIET_GEKEND;
        }
    }
}
