import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css';
import {getTheme, Theme} from '@/modules/core/theme/theme';
import nl from 'vuetify/src/locale/nl';
import en from 'vuetify/src/locale/en';
import {VuetifyThemeVariant} from 'vuetify/types/services/theme';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { en, nl },
		current: 'nl',
	},
	icons: {
		iconfont: 'md',
	},
	theme: {
		options: { customProperties: true},
		themes: { light: getTheme() as Partial<VuetifyThemeVariant> },
	},
});
