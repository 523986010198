



































import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';
import {configService} from "@/modules/config/services/config-service";

export default Vue.extend({
  name: 'UserOptions',
  computed: {
    ...mapState('navigation', ['sidebarMini']),
    ...mapGetters('authorisationStore', ['isAuthenticated', 'getUserEmail']),
    ...mapState('authorisationStore', ['userProfile']),
    getClassForUser(){
      const output = ['user-details'];

      if(this.sidebarMini){
        output.push('mini')
      }
      return output;
    }
  },
  methods: {
    logout() {
      configService
          .fetchConfig()
          .then(value => {
            window.location.href = '/oidc/sign_out'
          });
    },
    login() {
      window.location.href = '/oidc/sign_in'
    },
  },
});
