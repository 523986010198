import {httpService, PaginationType} from '@/modules/core/services/http-service';
import {BriefTemplate, DrukkerExport, DrukkerExportType, HuisartsTemplate, SMSTemplate,} from '@/modules/correspondentiebeheer/domains';
import {downloadByGet, downloadByPost} from "@/modules/core/services/downloadFile";

export async function getBriefTemplates(): Promise<BriefTemplate[]> {
    const response: any[] = await httpService.get('/api/correspondentie-beheer/brief/all');
    return response.map((item: any) => new BriefTemplate(item));
}

export function getBriefTemplateDocumentURL(data: BriefTemplate): string {
    let params = `communicatieType=${data.type}&taal=${data.taal}`;
    if (data.hasDetailType) {
        params += `&detailType=${data.detailType}`;
    }
    return `/api/correspondentie-beheer/brief/content?${params}`;
}

export async function saveBriefTemplate(templateData: any): Promise<void> {
    const data = new FormData();
    Object.keys(templateData)
        .forEach((key: string) => {
			let value = templateData[key];
			if(value!==null){
				return data.append(key, value);
			}
		});
    await httpService.putWithFile(`/api/correspondentie-beheer/brief`, data);
}

export async function deleteBriefTemplate(data: BriefTemplate): Promise<void> {
    let params = `communicatieType=${data.type}&taal=${data.taal}`;
    if (data.hasDetailType) {
        params += `&detailType=${data.detailType}`;
    }
    await httpService.delete(`/api/correspondentie-beheer/brief?${params}`);
}

export async function getSMSTemplates(): Promise<SMSTemplate[]> {
    const response: any = await httpService.get('/api/correspondentie-beheer/sms/all');
    return response.map((item) => new SMSTemplate(item));
}

export async function saveSMSTemplate(data: any): Promise<void> {
    await httpService.put('api/correspondentie-beheer/sms', data);
}

export async function deleteSMSTemplate(data: SMSTemplate): Promise<void> {
    let params = `communicatieType=${data.type}&taal=${data.taal}`;
    if (data.hasDetailType) {
        params += `&detailType=${data.detailType}`;
    }

    await httpService.delete(`api/correspondentie-beheer/sms?${params}`);
}

export async function getExportsVoorDrukker(): Promise<PaginationType<DrukkerExport>> {
    const response = await httpService.get<PaginationType<DrukkerExportType>>('/api/drukker/batch');
    return {
        ...response,
        content: response.content.map((item) => new DrukkerExport(item)),
    };
}

export async function exportDrukkerLijst(item: DrukkerExport): Promise<any> {
    if(item.verstuurd){
        await downloadByGet(`api/drukker/batch/${item.id}/export`)
    } else {
        await downloadByPost(`api/drukker/batch/${item.type}`)
    }
}

export async function getHuisartsTemplate(): Promise<HuisartsTemplate> {
    const response: any = await httpService.get('/api/correspondentie-beheer/huisarts');
    return new HuisartsTemplate(response);
}

export async function saveHuisartsTemplate(data: any): Promise<void> {
    await httpService.put('api/correspondentie-beheer/huisarts', data);
}
