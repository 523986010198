import {httpSilentService} from '@/modules/core/services/http-service';
import {ALL_USER_ROLES, UserRole} from '@/modules/core/authorisation/auth.domain';

export interface IUserProfile {
    userId: string;
    roles: string[];
    username: string;
    email: string;
}

export class UserProfile {
    userId: string;
    roles: UserRole[];
    username: string;

    constructor(userId: string, roles: UserRole[], username: string) {
        this.userId = userId;
        this.roles = roles;
        this.username = username || "";
    }

    get initials(): string {
        const beforeAtSymbol = this.username.split('@')[0];
        const fullNameSplit = beforeAtSymbol.split('.');
        return fullNameSplit.map((f: string) => f.length ? f[0] : '').join('');
    }
}

export class UserProfileService {

    fetchUserProfile(): Promise<UserProfile> {
        return httpSilentService
            .get<IUserProfile>('/api/user-profile')
            .then((data: IUserProfile) => {
                let filteredRoles = ALL_USER_ROLES.filter((ur) => {
                    return data.roles.some((role) => ur.toUpperCase() === role.toUpperCase());
                });

                return new UserProfile(data.userId, filteredRoles, data.username || data.email);
            });
    }

    fetchUserProfileSilent(): Promise<UserProfile> {
        return httpSilentService
            .get<IUserProfile>('/api/user/me')
            .then((data: IUserProfile) => {
                let filteredRoles = ALL_USER_ROLES.filter((ur) => {
                    return data.roles.some((role) => ur.toUpperCase() === role.toUpperCase());
                });
                return new UserProfile(data.userId, filteredRoles, data.username || data.email);
            });
    }
}

export const userProfileService = new UserProfileService();
