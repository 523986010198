import moment from 'moment';
import {v4 as uuid} from 'uuid';
import {getValueFromObject} from '@/modules/core/utils';
import {getOpvolgingType, OpvolgingType} from '@/modules/vrouw/domains/opvolging';

export enum TemplateTaal {
	NL = 'nl',
	EN = 'en',
	FR = 'fr',
}

export enum TemplateType {
	registratie = 'registratie',
	dubbele_registratie = 'dubbele_registratie',
	opvolging = 'opvolging',
	opvolging_herinnering = 'opvolging_herinnering',
	diabetes = 'diabetes',
	onbereikbaar = 'onbereikbaar',
}

function getTemplateType(type: string): TemplateType {
	switch (type) {
		case 'registratie':
			return TemplateType.registratie;
		case 'dubbele_registratie':
			return TemplateType.dubbele_registratie;
		case 'opvolging':
			return TemplateType.opvolging;
		case 'opvolging_herinnering':
			return TemplateType.opvolging_herinnering;
		case 'diabetes':
			return TemplateType.diabetes;
		case 'onbereikbaar':
			return TemplateType.onbereikbaar;
		default:
			return TemplateType.onbereikbaar;
	}
}

function getTemplateTaal(taal: string): TemplateTaal {
	switch (taal) {
		case 'nl':
			return TemplateTaal.NL;
		case 'en':
			return TemplateTaal.EN;
		case 'fr':
			return TemplateTaal.FR;
		default:
			return TemplateTaal.NL;
	}
}

interface BaseTemplateDTO {
	template: {
		communicatieType: string;
		detailType: string;
		taal: string;
	},
	laatstGewijzigdOp: string;
	laatstGewijzigdDoor: string;
}

export abstract class BaseTemplate {
	type: TemplateType;
	detailType: OpvolgingType;
	taal: TemplateTaal;
	laatstGewijzigdOp: moment.Moment | string;
	laatstGewijzigdDoor: string;

	constructor(data: BaseTemplateDTO) {
		this.type = getTemplateType(getValueFromObject(data, 'template.communicatieType', ''));
		this.detailType = getOpvolgingType(getValueFromObject(data, 'template.detailType', ''));

		this.taal = getTemplateTaal(getValueFromObject(data, 'template.taal'));

		const laatstGewijzigdOp = getValueFromObject(data, 'laatstGewijzigdOp', '');
		this.laatstGewijzigdOp = laatstGewijzigdOp ? moment(laatstGewijzigdOp) : '';

		this.laatstGewijzigdDoor = getValueFromObject(data, 'laatstGewijzigdDoor', '');
	}

	public get hasDetailType(): boolean {
		return [TemplateType.opvolging, TemplateType.opvolging_herinnering].includes(this.type) && this.detailType !== OpvolgingType.nietGekend;
	}
}

export class BriefTemplate extends BaseTemplate {
	constructor(data: any) {
		super(data);
	}
}

export class SMSTemplate extends BaseTemplate {
	content: string;

	constructor(data: any) {
		super(data);
		this.content = getValueFromObject(data, 'content', '');
	}
}

export enum DrukkerExportType {
	M3 = '3m',
	jaarlijks = 'jaarlijks',
	bevestiging = 'bevestiging',
	nietGekend = 'niet_gekend',
}

function getDrukkerExportType(type: string): DrukkerExportType {
	switch (type) {
		case '3m':
			return DrukkerExportType.M3;
		case 'jaarlijks':
			return DrukkerExportType.jaarlijks;
		case 'bevestiging':
			return DrukkerExportType.bevestiging;
		default:
			return DrukkerExportType.nietGekend;
	}
}

export class DrukkerExport {
	id: string;
	type: DrukkerExportType;
	tijdstipVerzonden: moment.Moment | string;
	verstuurd: boolean;
	aantal: number;

	constructor(data: any) {
		this.id = getValueFromObject(data, 'id', uuid());
		this.type = getDrukkerExportType(
			getValueFromObject(data, 'type', DrukkerExportType.nietGekend)
		);
		const tijdstipVerzonden = getValueFromObject(data, 'tijdstipVerzonden', '');
		this.tijdstipVerzonden = tijdstipVerzonden ? moment(tijdstipVerzonden) : '';
		this.verstuurd = Boolean(tijdstipVerzonden);
		this.aantal = getValueFromObject(data, 'aantal', 0);
	}
}

interface HuisartsTemplateDTO {
	content: string;
	laatstGewijzigdOp: string;
	laatstGewijzigdDoor: string;
}

export class HuisartsTemplate  {
	laatstGewijzigdOp: moment.Moment | string;
	laatstGewijzigdDoor: string;

	content: string;

	constructor(data: HuisartsTemplateDTO) {
		const laatstGewijzigdOp = getValueFromObject(data, 'laatstGewijzigdOp', '');
		this.laatstGewijzigdOp = laatstGewijzigdOp ? moment(laatstGewijzigdOp) : '';

		this.laatstGewijzigdDoor = getValueFromObject(data, 'laatstGewijzigdDoor', '');
		this.content = data.content;
	}
}
