import {Module} from "vuex";

export default (): Module<any, any> => ({
    namespaced: true,
    state: {
        prevRoute: null
    },
    mutations: {
        CHANGE_ROUTE(state, route) {
            state.prevRoute = route;
        }
    },
    actions: {
        updatePrevRoute(context, prevRoute) {
            context.commit('CHANGE_ROUTE', prevRoute)
        }
    }
})