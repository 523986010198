<template>

	<v-form ref='form' @submit='handleSubmit'>

		<v-row>
			<v-col>
				<v-select v-model='form.type.value'
									:label='$t(form.type.label)'
									:rules='form.type.rules'
									:items='form.type.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row v-if='showDetailType'>
			<v-col>
				<v-select v-model='form.detailType.value'
									:label='$t(form.detailType.label)'
									:rules='form.detailType.rules'
									:items='form.detailType.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-select v-model='form.taal.value'
									:label='$t(form.taal.label)'
									:rules='form.taal.rules'
									:items='form.taal.selectItems'
									outlined
									:disabled='isEditForm'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-textarea :label='$t(form.content.label)'
										outlined
										v-model='form.content.value'
										:rules='form.content.rules'
										:hint="$t('correspondentieBeheer.sms.cuForm.helperText')"
										:placeholder="$t('correspondentieBeheer.sms.cuForm.helperText')"
										:counter='form.content.counter'
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<p>
					{{ $t('correspondentieBeheer.sms.cuForm.resultText', { amount: amountOfSMS }) }}
				</p>
			</v-col>
		</v-row>

		<v-row class='mb-2'>
			<v-spacer />
			<v-btn type='button' text @click='$emit("cancel")'>
				{{ $t(`correspondentieBeheer.sms.${getCuKey}.actions.no`) }}
			</v-btn>
			<v-btn color='primary' type='submit' @click='handleSubmit'>
				{{ $t(`correspondentieBeheer.sms.${getCuKey}.actions.yes`) }}
			</v-btn>
		</v-row>

	</v-form>

</template>

<script>
import { SMSCuForm } from '@/modules/correspondentiebeheer/forms/templates/smsCuForm';
import { SMSTemplate, TemplateType } from '@/modules/correspondentiebeheer/domains';
import { saveSMSTemplate } from '@/modules/correspondentiebeheer/api';

export default {
	name: 'cuForm',
	props: {
		smsTemplate: SMSTemplate,
	},
	data() {
		return {
			form: new SMSCuForm(this.$i18n, this.smsTemplate),
		};
	},
	computed: {
		getCuKey() {
			return this.isEditForm ? 'edit' : 'add';
		},
		showDetailType() {
			return [TemplateType.opvolging, TemplateType.opvolging_herinnering].includes(this.form.type.value);
		},
		amountOfSMS() {
			//https://support.messagebird.com/hc/en-us/articles/208739745
			const maxChars = [160, 306, 459, 612, 765, 918, 1071, 1224, 1377];
			const findIndex = maxChars.findIndex(value => value >= this.form.content.value.length);
			return findIndex + 1;
		},
		isEditForm() {
			return Boolean(this.smsTemplate);
		},
	},
	methods: {
		handleSubmit: async function(event) {
			event.preventDefault();
			if (!this.$refs.form.validate()) {
				return;
			}

			await saveSMSTemplate(this.form.toJSON());
			this.$emit('submit');

		},
	},
};
</script>

<style scoped>

</style>