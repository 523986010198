import VueI18n from 'vue-i18n';
import moment from 'moment/moment';

export interface AxiansForm {
	// [formFieldName: string]: FormItem

	toJSON(): { [fieldName: string]: any };
}

export const formRules = {
	required: (translator: VueI18n, transKey: string) => [
		(v: string) => !!v || translator.t(transKey),
	],
	isEmail: (translator: VueI18n, transKey: string) => [
		(v: string) => {
			if (!v) {
				// not required
				return true;
			}
			return (
				/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					v
				) || translator.t(transKey)
			);
		},
	],
	isEmailAndRequired: (translator: VueI18n, transKey: string) => [
		(v: string) => {
			return (
				/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					v
				) || translator.t(transKey)
			);
		},
	],
	geboorteDatumValidatie: (translator: VueI18n) => [
		(v: string) => {
			if (!v) {
				return true;
			}

			const geboorteDatum = moment(v, 'DD-MM-YYYY', true);

			if (!geboorteDatum.isValid()) {
				return translator.t('form.date');
			}

			if (
				!geboorteDatum.isBetween(moment().subtract(100, 'years'), moment().subtract(10, 'years'))
			) {
				return translator.t('form.geboorteDatumValid');
			}

			return true;
		},
	],
	vermoedelijkeBevallingsDatumValidatie: (translator: VueI18n) => [
		(v: string) => {
			if (!v) {
				return translator.t('form.required');
			}

			const vermoedelijkeBevallingsDatum = moment(v, 'DD-MM-YYYY', true);

			if (!vermoedelijkeBevallingsDatum.isValid()) {
				return translator.t('form.date');
			}

			if (
				!vermoedelijkeBevallingsDatum.isBetween(
					moment().subtract(10, 'years'),
					moment().add(9, 'months')
				)
			) {
				return translator.t('form.vermoedelijkeBevallingsDatumValidatie');
			}

			return true;
		},
	],
	datumIsRequired: (translator: VueI18n) => [
		(v: string) => {
			if (!v) {
				return translator.t('form.required');
			}

			const datum = moment(v, 'DD-MM-YYYY', true);

			if (!datum.isValid()) {
				return translator.t('form.date');
			}

			return true;
		},
	],
	numberBetween: (translator: VueI18n, min: number, max: number) => [
		(v: string) => {
			if (!v) {
				return true;
			}

			if (Number.isNaN(v)) {
				return translator.t('form.number');
			}

			if (min > +v) {
				return translator.t('form.numberMin');
			}

			if (max < +v) {
				return translator.t('form.numberMax');
			}

			return true;
		},
	],
	maxChars: (translator: VueI18n, max: number) => [
		(v: string) => {
			if (!v) {
				return translator.t('form.required');
			}

			if (v.length > max) {
				return translator.t('form.maxChars', { max });
			}

			return true;
		},
	],
	fileAndRequired: (translator: VueI18n, fileTypes: string[]) => [
		(v: any) => {
			if (!v) {
				return translator.t('form.required');
			}

			if (v instanceof File) {
				return fileTypes.some((type) => v.name.endsWith(type))
					? true
					: translator.t('form.file', { fileTypes: fileTypes.join(', ') });
			}

			return false;
		},
	],
};

export class FormItem {
	private _id: string;
	private _label: string;
	private _value: any;
	private _rules: Array<Function>;
	private _counter: number;
	private _selectItems: Array<SelectItem>;

	constructor(
		id: string,
		label: string,
		value: any = '',
		rules: Array<Function> = [],
		counter: number = -1,
		selectItems: Array<SelectItem> = []
	) {
		this._id = id;
		this._label = label;
		this._value = value;
		this._rules = rules;
		this._counter = counter;
		this._selectItems = selectItems;
	}

	get id(): string {
		return this._id;
	}

	get label(): string {
		return this._label;
	}

	get rules(): Array<Function> | undefined {
		return this._rules;
	}

	get value(): any {
		return this._value;
	}

	set value(value: any) {
		this._value = value;
	}

	get counter(): number {
		return this._counter;
	}

	get selectItems(): Array<SelectItem> {
		return this._selectItems;
	}
}

export class SelectItem {
	private _text: string;
	private _value: string;

	constructor(text: string, value: string) {
		this._text = text;
		this._value = value;
	}

	get text(): string {
		return this._text;
	}

	get value(): string {
		return this._value;
	}
}
