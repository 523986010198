<template>
  <div>
    <v-row class='mb-3 mt-6'>
      <v-col>
        <h2>{{ $t('correspondentieBeheer.huisarts.title') }}</h2>
      </v-col>
    </v-row>

    <v-data-table class='elevation-1'
                  :headers='headers'
                  :items='templates'
                  hide-default-footer
                  v-on:click:row='handleRowClick'
    >
      <template v-slot:item.type='{ item }'>
        <span>{{$t('correspondentieBeheer.huisarts.type')}}</span>
      </template>

      <template v-slot:item.laatstGewijzigdOp='{ item }'>
        <span>{{ item.laatstGewijzigdOp ? item.laatstGewijzigdOp.format('DD/MM/YYYY') : '' }}</span>
      </template>

    </v-data-table>

    <cu-huisarts-template v-if='cuTemplateDialog'
                          :huisarts-template="activeCuTemplate"
                          @submit='handleCuSubmit'
                          @cancel='handleCuCancel'
    />
  </div>
</template>

<script>
import {getHuisartsTemplate} from '@/modules/correspondentiebeheer/api';
import CuHuisartsTemplate from '@/modules/correspondentiebeheer/dialogs/cuHuisartsTemplate.vue';
import ConfirmDeleteSMSTemplate from '@/modules/correspondentiebeheer/dialogs/confirmDeleteSMSTemplate.vue';

export default {
  name: 'Huisarts',
  components: {ConfirmDeleteSMSTemplate, CuHuisartsTemplate},
  mounted() {
    this.init();
  },
  data() {
    return {
      templates: [],
      headers: [
        {
          text: this.$t('correspondentieBeheer.huisarts.table.type'),
          value: 'type',
          sort: (a, b) => a.localeCompare(b),
        },
        {
          text: this.$t('correspondentieBeheer.huisarts.table.laatstGewijzigdOp'),
          value: 'laatstGewijzigdOp',
          sort: (a, b) => a.isAfter(b) ? 1 : -1,
        },
        {
          text: this.$t('correspondentieBeheer.huisarts.table.laatstGewijzigdDoor'),
          value: 'laatstGewijzigdDoor',
          sort: (a, b) => a.localeCompare(b),
        }
      ],
      deleteDialog: false,
      activeDeleteItem: null,

      cuTemplateDialog: false,
      activeCuTemplate: null,
    };
  },
  methods: {
    init: async function () {
      this.templates = [await getHuisartsTemplate()];
    },
    handleRowClick: function (element) {
      this.cuTemplateDialog = true;
      this.activeCuTemplate = element;
    },
    handleCuSubmit: function () {
      this.init();
      this.handleCuCancel();
    },
    handleCuCancel: function () {
      this.cuTemplateDialog = false;
      this.activeCuTemplate = null;
    },
  },
};
</script>
