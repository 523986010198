import Vue from 'vue'
import Vuex from 'vuex'
import {authorisationStore} from '../authorisation/authorisation-store'
import navigation from './navigation-store';
import {errorStore} from './../errors/error-store';
import modal from '../modal/modal.store';
import prevRouteStore from "@/modules/core/stores/route-store";
import gemeenteStore from "@/modules/core/stores/gemeente-store";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        authorisationStore: authorisationStore,
        navigation: navigation(),
        error: errorStore,
        gemeenten: gemeenteStore(),
        modal: modal(),
        route: prevRouteStore(),
    }
});
