import {
	AxiansForm,
	FormItem,
	formRules,
	SelectItem,
} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import { getValueFromObject } from '@/modules/core/utils';
import { consultatieHuisartsType, NumberResultOptions } from '@/modules/vrouw/domains/opvolging';

export function getNumberResultOnNumberControl(numberControl: number): NumberResultOptions {
	if (numberControl < 100) {
		return NumberResultOptions.NORMAL;
	}
	if (numberControl >= 100 && numberControl <= 125) {
		return NumberResultOptions.CRAZY;
	}

	return NumberResultOptions.DIABETIC;
}

export type JMFormType = {
	consultatieHuisarts: consultatieHuisartsType;
	bloedglucoseWaarde: number | null;
	bloedglucoseResultaat: string | null;
};

export class JMResult {
	consultatieHuisarts: consultatieHuisartsType;
	bloedglucoseWaarde: number;
	bloedglucoseResultaat: NumberResultOptions;

	constructor(result: any) {
		this.consultatieHuisarts = getValueFromObject(result, 'consultatieHuisarts', false);
		this.bloedglucoseWaarde = getValueFromObject(result, 'bloedglucoseWaarde', '');
		const bloedglucoseResultaat = getValueFromObject(result, 'bloedglucoseResultaat', 0);
		this.bloedglucoseResultaat = this._getNumberResultOption(
			this.bloedglucoseWaarde,
			bloedglucoseResultaat
		);
	}

	get bloedglucoseGedaan(): boolean {
		return (
			Boolean(this.bloedglucoseWaarde) ||
			this.bloedglucoseResultaat !== NumberResultOptions.NIET_GEKEND
		);
	}

	private _getNumberResultOption(numberControl: number, numberResult: string): NumberResultOptions {
		switch (numberResult) {
			case 'N':
				return NumberResultOptions.NORMAL;
			case 'GNG':
				return NumberResultOptions.CRAZY;
			case 'DM':
				return NumberResultOptions.DIABETIC;
		}

		return NumberResultOptions.NIET_GEKEND;
	}
}

export class JMForm implements AxiansForm {
	consultatieHuisarts: FormItem;
	bloedglucoseGedaan: FormItem;
	bloedglucoseWaarde: FormItem;
	bloedglucoseResultaat: FormItem;

	constructor(translator: VueI18n, initialData?: JMResult) {
		this.consultatieHuisarts = new FormItem(
			'consultDOC',
			'woman.followUp.jm.form.consultDOC.label',
			initialData?.consultatieHuisarts || consultatieHuisartsType.Nee,
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem(
					translator.t('woman.followUp.jm.form.consultDOC.options.j').toString(),
					consultatieHuisartsType.Ja
				),
				new SelectItem(
					translator.t('woman.followUp.jm.form.consultDOC.options.n').toString(),
					consultatieHuisartsType.Nee
				),
				new SelectItem(
					translator.t('woman.followUp.jm.form.consultDOC.options.zwanger').toString(),
					consultatieHuisartsType.NeeWantZwanger
				),
				new SelectItem(
					translator.t('woman.followUp.jm.form.consultDOC.options.gepland').toString(),
					consultatieHuisartsType.NeeMaarGepland
				),
			]
		);
		this.bloedglucoseGedaan = new FormItem(
			'controlDone',
			'woman.followUp.jm.form.controlDone.label',
			initialData?.bloedglucoseGedaan || false
		);
		this.bloedglucoseWaarde = new FormItem(
			'numberControl',
			'woman.followUp.jm.form.numberControl.label',
			initialData?.bloedglucoseWaarde,
			formRules.numberBetween(translator, 50, 300)
		);

		this.bloedglucoseResultaat = new FormItem(
			'numberResult',
			'woman.followUp.jm.form.numberResult.label',
			initialData?.bloedglucoseResultaat,
			[],
			-1,
			[
				new SelectItem(
					'woman.followUp.jm.form.numberResult.options.normal',
					NumberResultOptions.NORMAL
				),
				new SelectItem(
					'woman.followUp.jm.form.numberResult.options.crazy',
					NumberResultOptions.CRAZY
				),
				new SelectItem(
					'woman.followUp.jm.form.numberResult.options.diabetic',
					NumberResultOptions.DIABETIC
				),
			]
		);
	}

	toJSON(): JMFormType {
		const bloedglucoseGedaan = this.bloedglucoseGedaan.value;

		return {
			consultatieHuisarts: this.consultatieHuisarts.value,
			bloedglucoseWaarde: bloedglucoseGedaan ? this.bloedglucoseWaarde.value || null : null,
			bloedglucoseResultaat: bloedglucoseGedaan ? this.bloedglucoseResultaat.value || null : null,
		};
	}
}
