<template>
	<v-dialog max-width='960' v-model='dialog'>
		<v-card>
			<v-card-title>
				<h4>{{ $t(`bpm.new`) }}</h4>
				<v-spacer />
				<v-icon @click='handleCancel'>close</v-icon>
			</v-card-title>

			<v-card-text class='mt-8'>
				<update-template-form @submit='handleSubmit' @cancel='handleCancel' />
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>

import UpdateTemplateForm from '@/modules/bpmbeheer/forms/updateTemplateForm.vue';

export default {
	name: 'UpdateTemplate',
	components: { UpdateTemplateForm },
	props: {},
	data() {
		return {
			dialog: true,
		};
	},
	computed: {},
	methods: {
		handleCancel: function() {
			this.$emit('cancel');
		},
		handleSubmit: function() {
			this.$emit('submit');
		},
	},
	watch: {
		dialog: function() {
			if (!this.dialog) {
				this.handleCancel();
			}
		},
	},
};
</script>

<style scoped>

</style>