<template>
  <v-container>
    <v-row class="mb-3 mt-6">
      <v-col>
        <h2>{{ $t('diabeticTeams.header.title') }}</h2>
      </v-col>

      <v-col class="d-flex align-center justify-end">
        <v-btn color="primary"
               small
               class="ml-2"
               @click="handleNewDiabeticTeams">
          <v-icon class="material-icons-outlined">add</v-icon>
          <span class="pa-2">{{ $t('diabeticTeams.header.new') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-text-field
        label="Zoek"
        prepend-inner-icon="search"
        outlined
        v-model="searchInput"
        clearable
    />
    <v-data-table class="elevation-1"
                  calculate-widths
                  :headers="headers"
                  :items="diabeticTeams"
                  :options.sync="options"
                  :server-items-length="totalDiabeticTeams"
                  :items-per-page="100"
                  v-on:click:row="handleRowClick"

    >
      <template v-slot:item.actief="{item}">
        <v-icon class="material-icons-outlined">
          {{ item.actief ? 'done_outline' : 'close' }}
        </v-icon>
      </template>
    </v-data-table>


    <cu-diabetes-team v-if="dialogOpen"
                      v-model="dialogOpen"
                      :diabetes-team="activeDiabetesTeam"
                      @submit="handleSubmitDiabeticTeam"
    />
  </v-container>
</template>

<script>
import {getAllDiabeticTeams} from "@/modules/diabetesteam/api";
import CuDiabetesTeam from "@/modules/diabetesteam/dialogs/cuDiabetesTeam";

export default {
  name: "list",
  components: {CuDiabetesTeam},
  data() {
    return {
      diabeticTeams: [],
      totalDiabeticTeams: 0,
      options: {},
      headers: [
        {text: this.$t("diabeticTeams.table.name"), value: "naam"},
        {text: this.$t("diabeticTeams.table.email"), value: "email"},
        {text: this.$t("diabeticTeams.table.active"), value: "actief"},

      ],
      searchInput: '',
      dialogOpen: false,
      activeDiabetesTeam: null,
    }
  },
  methods: {
    fetchAllDiabeticTeams: async function (searchInput = "") {
      try {
        const response = await getAllDiabeticTeams(searchInput, this.options);
        this.diabeticTeams = response.content;
        this.totalDiabeticTeams = response.totalElements;
      } catch (e) {
      }
    },
    fetchAllDiabeticTeamsDebounce: function (searchInput = "") {
      clearTimeout(this._fetchAllDiabeteicTeamsTimer)
      this._fetchAllDiabeteicTeamsTimer = setTimeout(() => {
        this.fetchAllDiabeticTeams(searchInput)
      }, 500)
    },
    handleRowClick: function (element) {
      this.activeDiabetesTeam = element;
      this.dialogOpen = true;
    },
    handleNewDiabeticTeams: function () {
      this.activeDiabetesTeam = null;
      this.dialogOpen = true;
    },
    handleSubmitDiabeticTeam: function () {
      this.dialogOpen = false;
      this.activeDiabetesTeam = false;
      this.searchInput = "";
      this.fetchAllDiabeticTeamsDebounce();
    }
  },
  watch: {
    searchInput: function (searchInput) {
      this.fetchAllDiabeticTeamsDebounce(searchInput || "");
    },
    options: {
      handler() {
        this.fetchAllDiabeticTeams(this.searchInput)
      }, deep: true
    }

  }
}
</script>

<style scoped lang="scss">
</style>
