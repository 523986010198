import { BPMFile, TemplateType } from '@/modules/bpmbeheer/domains';
import { createNewDeployment, getDeployments } from '@/modules/camunda/api';
import { UserProfile } from '@/modules/user/services/user-profile-service';

export async function getBPMList(): Promise<BPMFile[]> {
	return await getDeployments();
}

export async function createNewBPMDeployment(
	type: TemplateType,
	currentVrouw: UserProfile,
	newFile: File
): Promise<void> {
	await createNewDeployment(type, currentVrouw, newFile);
}
