<template>
  <v-form ref='form' @submit='handleSubmit'>
    <v-row>
      <v-col>
        <span>{{ $t('correspondentieBeheer.huisarts.cuForm.variables') }}</span>
        <v-chip
            v-for="variable of variables"
            :key="variable"
            class="ma-2"
            color="primary"
            outlined
            @click="appendVariable(variable)"
        >
          {{variable}}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea :label='$t(form.content.label)'
                    outlined
                    v-model='form.content.value'
                    :rules='form.content.rules'
                    :hint="$t('correspondentieBeheer.huisarts.cuForm.helperText')"
                    :placeholder="$t('correspondentieBeheer.huisarts.cuForm.helperText')"
                    :counter='form.content.counter'
                    @focusout="rememberCaretPosition"
        />
      </v-col>
    </v-row>

    <v-row class='mb-2'>
      <v-spacer/>
      <v-btn type='button' text @click='$emit("cancel")'>
        {{ $t(`correspondentieBeheer.huisarts.edit.actions.no`) }}
      </v-btn>
      <v-btn color='primary' type='submit' @click='handleSubmit'>
        {{ $t(`correspondentieBeheer.huisarts.edit.actions.yes`) }}
      </v-btn>
    </v-row>

  </v-form>

</template>

<script>
import {HuisartsCuForm} from '@/modules/correspondentiebeheer/forms/templates/huisartsCuForm';
import {HuisartsTemplate} from '@/modules/correspondentiebeheer/domains';
import {saveHuisartsTemplate} from '@/modules/correspondentiebeheer/api';

export default {
  name: 'cuForm',
  props: {
    huisartsTemplate: HuisartsTemplate,
  },
  data() {
    return {
      form: new HuisartsCuForm(this.$i18n, this.huisartsTemplate),
      caretPosition: undefined
    };
  },
  computed: {
    isEditForm() {
      return Boolean(this.huisartsTemplate);
    },
    variables() {
      return [
        "huisarts_naam",
        "naam",
        "voornaam",
        "geboortedatum",
      ]
    }
  },
  methods: {
    handleSubmit: async function (event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) {
        return;
      }

      await saveHuisartsTemplate(this.form.toJSON());
      this.$emit('submit');

    },
    appendVariable(variableText) {
      const variableInterpolation = `*|${variableText}|*`
      if(this.caretPosition){
        this.form.content.value = `${this.form.content.value.substr(0, this.caretPosition)}${variableInterpolation}${this.form.content.value.substr(this.caretPosition)}`;
      } else {
        this.form.content.value = `${this.form.content.value} ${variableInterpolation}`;
      }
    },
    rememberCaretPosition(event) {
      this.caretPosition = event.target.selectionStart
    }
  },
};
</script>

<style scoped>

</style>
