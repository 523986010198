import { AxiansForm } from '@/modules/core/components/forms/forms.model';
import moment from 'moment';

export abstract class DetailsForm<T> implements AxiansForm {
	initialData?: T;

	constructor(initialData?: T) {
		this.initialData = initialData;
	}

	public isSame(key: string, compareValue: any): boolean {
		if (!this[key] || !this[key].hasOwnProperty) {
			return false;
		}

		if (key === 'geboortedatum') {
			return this[key].value === (compareValue as moment.Moment)?.format('YYYY-MM-DD');
		}

		if (key === 'jaarlijkseOpvolgingDatum') {
			const aValue: string = (this[key].value || '').toString();
			const bValue: moment.Moment = compareValue;

			return aValue === bValue.format('M');
		}

		if (key === 'voorkeurEmail') {
			return this[key].value.toString() === compareValue.toString();
		}

		return (this[key].value || '').toString() === (compareValue || '').toString();
	}

	public abstract resetData(): void;

	public changeEditData(): void {
		Object.keys(this).forEach((prop) => {
			if (prop !== 'initialData') {
				if (this[prop].value === 'n/a') {
					this[prop].value = '';
				}
			}
		});
	}

	toJSON(): { [p: string]: any } {
		return {};
	}
}
