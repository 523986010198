var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3 mt-6"},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$t('correspondentieBeheer.drukker.title')))])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.exports.content,"options":_vm.options,"footer-props":{'items-per-page-options': [100, 50, 25, 10]},"items-per-page":50},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("correspondentieBeheer.drukker.type." + (item.type))))+" ")]}},{key:"item.verstuurd",fn:function(ref){
var item = ref.item;
return [(item.verstuurd)?_c('v-icon',{staticClass:"material-icons-outlined"},[_vm._v(" done_outline ")]):_vm._e()]}},{key:"item.tijdstipVerzonden",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tijdstipVerzonden ? item.tijdstipVerzonden.format('DD/MM/YYYY') : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isDownloadingFile !== item.id && item.aantal > 0)?_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(" file_download ")]):_vm._e(),(_vm.isDownloadingFile === item.id)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"24"}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }