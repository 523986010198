<template>

	<div>
		<v-row class='mb-3 mt-6'>
			<v-col>
				<h2>{{ $t('correspondentieBeheer.sms.title') }}</h2>
			</v-col>

			<v-col class='d-flex align-center justify-end'>
				<v-btn color='primary'
							 small
							 class='ml-2'
							 @click='handleNewSMSTemplate'>
					<v-icon class='material-icons-outlined'>add</v-icon>
					<span class='pa-2'>{{ $t('correspondentieBeheer.sms.new') }}</span>
				</v-btn>
			</v-col>
		</v-row>

		<v-data-table class='elevation-1'
									:headers='headers'
									:items='templates'
									:items-per-page='100'
									:footer-props="{'items-per-page-options': [100, 50, 25, 10]}"
									v-on:click:row='handleRowClick'
		>
			<template v-slot:item.type='{ item }'>
				<span>{{ $t(`correspondentieBeheer.sms.types.${item.type}`) }}</span>
				<span v-if='item.hasDetailType'>
					&emsp;{{ $t(`correspondentieBeheer.sms.detailTypes.opvolging.${item.detailType}`) }}
				</span>
			</template>

			<template v-slot:item.laatstGewijzigdOp='{ item }'>
				<span>{{ item.laatstGewijzigdOp ? item.laatstGewijzigdOp.format('DD/MM/YYYY') : '' }}</span>
			</template>

			<template v-slot:item.taal='{ item }'>
				{{ $t(`common.languages.${item.taal}`) }}
			</template>

			<template v-slot:item.acties='{ item }'>
				<a v-if='item.linkNaarDownload' :href='item.linkNaarDownload' style='text-decoration:none'>
					<v-icon class='material-icons-outlined' color='primary'>file_download</v-icon>
				</a>
				<v-icon class='ml-8' color='error' @click='(event) => handleDelete(event,item)'>delete</v-icon>
			</template>
		</v-data-table>

		<cu-s-m-s-template v-if='cuTemplateDialog'
											 :sms-template='activeCuTemplate'
											 @submit='handleCuSubmit'
											 @cancel='handleCuCancel'
		/>
		<confirm-delete-s-m-s-template v-model='deleteDialog' :active-template='activeDeleteItem' @submit='handleSubmitDelete' />

	</div>


</template>

<script>
import {getSMSTemplates} from '@/modules/correspondentiebeheer/api';
import CuSMSTemplate from '@/modules/correspondentiebeheer/dialogs/cuSMSTemplate.vue';
import ConfirmDeleteSMSTemplate from '@/modules/correspondentiebeheer/dialogs/confirmDeleteSMSTemplate.vue';

export default {
	name: 'SMS',
	components: { ConfirmDeleteSMSTemplate, CuSMSTemplate },
	mounted() {
		this.init();
	},
	data() {
		return {
			templates: [],
			headers: [
				{
					text: this.$t('correspondentieBeheer.sms.table.type'),
					value: 'type',
          sort: (a, b) => a.localeCompare(b),
				},
				{
					text: this.$t('correspondentieBeheer.sms.table.taal'),
					value: 'taal',
          sort: (a, b) => a.localeCompare(b),
				},
				{
					text: this.$t('correspondentieBeheer.sms.table.laatstGewijzigdOp'),
					value: 'laatstGewijzigdOp',
					sort: (a, b) => a.isAfter(b) ? 1 : -1,
				},
				{
					text: this.$t('correspondentieBeheer.sms.table.laatstGewijzigdDoor'),
					value: 'laatstGewijzigdDoor',
          sort: (a, b) => a.localeCompare(b),
				},
				{ text: '', value: 'acties', sortable: false, align: 'end' },
			],

			deleteDialog: false,
			activeDeleteItem: null,

			cuTemplateDialog: false,
			activeCuTemplate: null,
		};
	},
	methods: {
		init: async function() {
			this.templates = await getSMSTemplates();
		},
		handleNewSMSTemplate: function() {
			this.cuTemplateDialog = true;
		},
		handleRowClick: function(element) {
			this.cuTemplateDialog = true;
			this.activeCuTemplate = element;
		},
		handleCuSubmit: function() {
			this.init();
			this.handleCuCancel();
		},
		handleCuCancel: function() {
			this.cuTemplateDialog = false;
			this.activeCuTemplate = null;
		},

		handleDelete: function(event, item) {
			event.stopPropagation();
			this.deleteDialog = true;
			this.activeDeleteItem = item;
		},
		handleSubmitDelete: function() {
			this.deleteDialog = false;
			this.activeDeleteItem = null;
			this.init();
		},
	},
	watch: {
		deleteDialog: function() {
			if (!this.deleteDialog) {
				this.activeDeleteItem = null;
			}
		},
	},
};
</script>

<style scoped>

</style>
