import { TemplateBaseForm } from '@/modules/correspondentiebeheer/forms/templates/baseForm';
import VueI18n from 'vue-i18n';
import { SMSTemplate } from '@/modules/correspondentiebeheer/domains';
import { FormItem, formRules } from '@/modules/core/components/forms/forms.model';

export class SMSCuForm extends TemplateBaseForm {
	content: FormItem;

	constructor(translator: VueI18n, initialData?: SMSTemplate) {
		super('sms', translator, initialData);

		this.content = new FormItem(
			'content',
			'correspondentieBeheer.sms.cuForm.text',
			initialData?.content ?? '',
			formRules.maxChars(translator, 1337), //https://support.messagebird.com/hc/en-us/articles/208739745
			1337
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			...super.toJSON(),
			content: this.content.value,
		};
	}
}
