export function getValueFromObject(item: any, path: string, defaultValue: any = null): any {
    const pathSplit = path.split(".");
    return pathSplit.reduce((xs: string, x: string) => (xs && xs[x] ? xs[x] : defaultValue), item);
}

export async function sleep(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

const envKey = 'axians_envs'

export function getEnv(key: string, defaultEnv: any = ""): any {

    if (window.hasOwnProperty(envKey) && window[envKey].hasOwnProperty(key)) {
        return window[envKey][key];
    }
    if (process.env.hasOwnProperty(key)) {
        return process.env[key];
    }
    return defaultEnv;
}
