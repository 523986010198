
































































import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
	name: 'DateField',
	props: {
		date: {
			type: String, // of format: 'yyyy-MM-DD'
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		rules: {
			type: Array,
			required: false,
			default: () => [],
		},
		allowedDates: {
			type: Function,
			default: () => () => true,
		},
		readonly: {
			type: Boolean,
			default: () => false,
		},
		hideBorders: {
			type: Boolean,
			default: () => false,
		},
    noDatePicker: {
      type: Boolean,
      required: false,
      default: false,
    }
	},
	computed: {
		internalDate: {
			get(): string {
				// @ts-ignore
				return this.date;
			},
			set(newValue): void {
				const menu: any = this.$refs.menu;
				if (menu && menu.save) {
					menu.save(newValue);
				}
				this.$emit('update:date', newValue);
			},
		},
		locale() {
			// @ts-ignore
			return this.$vuetify.lang.current;
		},
		formRules(): any {
			return (this.rules || []).map((rule: any) => rule(this.formattedDate));
		},
	},
	data() {
		return {
			menu: false,
			formattedDate: this.date,
		};
	},
	methods: {
		onClear() {
			// @ts-ignore
			this.internalDate = '';
		},
		onChange(updateDate: string) {
			this.formattedDate = updateDate;
			const momentDate = moment(updateDate, 'DD-MM-YYYY', true);
			if (momentDate.isValid()) {
				this.internalDate = momentDate.format('YYYY-MM-DD');
			}
		},
	},
	watch: {
		internalDate(val) {
			const momentDate = moment(val, 'YYYY-MM-DD', true);
			if (momentDate.isValid()) {
				this.formattedDate = momentDate.format('DD-MM-YYYY');
			}
		},
	},
});
