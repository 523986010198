<template>
	<v-dialog max-width='1250' v-model='dialogOpen'>
		<template v-slot:activator='{ on, attrs }'>
			<v-btn color='primary'
						 small
						 class='ml-2'
						 v-on='on'>
				<v-icon class='material-icons-outlined'>add</v-icon>
				<span class='pa-2'>{{ $t('woman.header.new') }}</span>
			</v-btn>
		</template>

		<template v-slot:default='dialog'>
			<v-card>
				<v-card-title>
					<v-row class='mb-8'>
						<v-col md='3' class='mr-8' />
						<v-col>
							<h4>{{ $t('woman.cu.create.title') }}</h4>
						</v-col>

						<v-col md='1' class='d-flex justify-end'>
							<v-icon @click='dialog.value = false'>close</v-icon>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text>
					<v-row class='form-row'>
						<v-col md='3' class='d-flex align-center mr-8'>
							<v-img src='@/assets/pregnantWoman.png' alt='' />
						</v-col>

						<v-col>
							<v-form ref='form'>
								<v-row class='mb-4 body-1'>
									<v-col>
										{{ $t('woman.cu.subtitles.personal') }}
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<v-text-field v-model='form.voornaam.value'
																	:rules='form.voornaam.rules'
																	:label='$t(form.voornaam.label)'
																	:name='form.voornaam.id'
																	maxlength='100'
																	outlined
										/>
									</v-col>
									<v-col>
										<v-text-field v-model='form.naam.value'
																	:rules='form.naam.rules'
																	:label='$t(form.naam.label)'
																	:name='form.naam.id'
																	maxlength='100'
																	outlined
										/>
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<v-text-field v-model='form.email.value'
																	:rules='form.email.rules'
																	:label='$t(form.email.label)'
																	:name='form.email.id'
																	outlined
										/>
									</v-col>
									<v-col>
										<date-field :date.sync='form.geboortedatum.value'
																:rules='form.geboortedatum.rules'
																:label='form.geboortedatum.label'
																:name='form.geboortedatum.id'
										/>
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<v-text-field v-model='form.adres.value'
																	:rules='form.adres.rules'
																	:label='$t(form.adres.label)'
																	:name='form.adres.id'
																	outlined
										/>
									</v-col>

									<v-col>
										<default-select
											type='city'
											:rules='form.gemeenteId.rules'
											:label='form.gemeenteId.label'
											v-model='form.gemeenteId.value'
										/>
									</v-col>

								</v-row>

								<!--                <v-row class="ml-8">-->
								<!--                  <v-col>-->
								<!--                    <phone-field v-model="form.telefoon.value"-->
								<!--                                 :label="$t(form.telefoon.label)"-->
								<!--                    />-->
								<!--                  </v-col>-->
								<!--                </v-row>-->
								<v-row class='ml-8'>
									<!--                  <v-col>-->
									<!--                    <v-text-field v-model="form.IDNumber.value"-->
									<!--                                  :rules="form.IDNumber.rules"-->
									<!--                                  :label="$t(form.IDNumber.label)"-->
									<!--                                  :name="form.IDNumber.id"-->
									<!--                                  outlined-->
									<!--                    />-->
									<!--                  </v-col>-->
									<v-col>
										<phone-field v-model='form.telefoon.value'
																 :label='$t(form.telefoon.label)'
										/>
									</v-col>
									<v-col>
										<v-select
											:rules='form.taal.rules'
											:items='form.taal.selectItems'
											:label='$t(form.taal.label)'
											v-model='form.taal.value'
											outlined
										/>
									</v-col>
								</v-row>

								<v-row class='mb-4 body-1'>
									<v-col>
										{{ $t('woman.cu.subtitles.medical') }}
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<date-field :date.sync='form.vermoedelijkeBevallingsDatum.value'
																:rules='form.vermoedelijkeBevallingsDatum.rules'
																:label='form.vermoedelijkeBevallingsDatum.label'
																:name='form.vermoedelijkeBevallingsDatum.id'
										/>
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<v-text-field v-model='form.heightBefore.value'
																	:rules='form.heightBefore.rules'
																	:label='$t(form.heightBefore.label)'
																	:name='form.heightBefore.id'
																	type='number'
																	outlined
										>
											<template v-slot:append>
												<span class='grey4--text'>{{ $t('woman.cu.metrics.heightUnit') }}</span>
											</template>
										</v-text-field>
									</v-col>
									<v-col>
										<v-text-field v-model='form.weightBefore.value'
																	:rules='form.weightBefore.rules'
																	:label='$t(form.weightBefore.label)'
																	:name='form.weightBefore.id'
																	type='number'
																	outlined
										>
											<template v-slot:append>
												<span class='grey4--text'>{{ $t('woman.cu.metrics.weightUnit') }}</span>
											</template>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<default-select type='doctor' clearable refreshable
																		:rules='form.huisartsId.rules'
																		:label='form.huisartsId.label'
																		v-model='form.huisartsId.value' />
									</v-col>
									<v-col>
										<default-select type='diabeticTeam' clearable
																		:rules='form.diabetesteamId.rules'
																		:label='form.diabetesteamId.label'
																		v-model='form.diabetesteamId.value' />
									</v-col>
								</v-row>
								<v-row class='ml-8'>
									<v-col>
										<v-text-field v-model='form.comment.value'
																	:rules='form.comment.rules'
																	:label='$t(form.comment.label)'
																	:name='form.comment.id'
																	outlined
										/>
									</v-col>
								</v-row>
							</v-form>
						</v-col>

					</v-row>
				</v-card-text>

				<v-card-actions class='form-row'>
					<v-spacer />
					<v-btn text color='text_light' @click='dialog.value = false'>
						{{ $t('woman.cu.actions.cancel') }}
					</v-btn>
					<v-btn color='primary' @click='handleSubmit(false)'>
						{{ $t('woman.cu.actions.submit') }}
					</v-btn>
					<v-btn color='primary' @click='handleSubmit(true)'>
						{{ $t('woman.cu.actions.submitAndGoAgain') }}
					</v-btn>
				</v-card-actions>

			</v-card>

		</template>
	</v-dialog>
</template>

<script>
import {CreateUpdateForm} from '@/modules/vrouw/forms/cu/cuForm';

import {postVoorlopigePapierRegistratie} from '@/modules/vrouw/api';
import DateField from '@/modules/core/components/forms/DateField';
import DefaultSelect from '@/modules/core/components/selects/defaultSelect';
import PhoneField from '@/modules/core/components/forms/PhoneField';

export default {
	name: 'WomanCU',
	components: { PhoneField, DefaultSelect, DateField },
	data() {
		return {
			dialogOpen: false,
			snackbarStatus: 'success',
			form: new CreateUpdateForm(this.$i18n),
		};
	},
	methods: {
		async handleSubmit(goAgain) {
			if (!this.$refs.form.validate()) {
				return;
			}
			try {
				const response = await postVoorlopigePapierRegistratie(this.form.toJSON());

				this.snackbarStatus = response.dubbelsGevonden ? 'successDouble' : 'success';

				if (goAgain) {
					this.$refs.form.reset();
					this.form = new CreateUpdateForm(this.$i18n);
				} else {
					this.dialogOpen = false;
				}

				this.$emit('submitted');
			} catch (e) {
				this.snackbarStatus = 'error';
			} finally {
				this.setSnackBarMessage();
			}
		},
		setSnackBarMessage() {
			this.$message.color = this.getSnackbarColor();
			this.$message.text = this.getSnackbarText();
			this.$message.show = true;
		},
		getSnackbarColor() {
			switch (this.snackbarStatus) {
				case 'success': {
					return '#4caf50';
				}
				case 'successDouble': {
					return '#f2994a';
				}
				case 'error': {
					return '#ff5252';
				}
				default:
					return 'grey4-base';
			}
		},
		getSnackbarText() {
			switch (this.snackbarStatus) {
				case 'success': {
					return this.$t('woman.cu.responses.success');
				}
				case 'successDouble': {
					return this.$t('woman.cu.responses.successDouble');
				}
				case 'error': {
					return this.$t('woman.cu.responses.error');
				}
				default:
					return '';
			}

		},
	},
	watch: {
		dialogOpen() {
			if (this.$refs && this.$refs.form) {
				this.$refs.form.reset();
			}
			this.form = new CreateUpdateForm(this.$i18n);
		},
	},
};
</script>

<style scoped lang='scss'>
.form-row {
	.col {
		padding: 4px 12px;
	}

	&.v-card__actions {
		padding-bottom: 16px;
	}
}
</style>
