<template>

  <v-dialog max-width="1250" v-model="dialog">
    <v-card>
      <v-card-title>
        <h4>{{ getTitle }}</h4>
        <v-spacer/>
        <v-icon @click="handleCancel">close</v-icon>
      </v-card-title>

      <v-card-text class="mt-8">
        <cu-diabetes-team-form :diabetes-team="diabetesTeam" @cancel="handleCancel" @submit="handleSubmit"/>
      </v-card-text>

    </v-card>
  </v-dialog>

</template>

<script>
import CuDiabetesTeamForm from "@/modules/diabetesteam/forms/cuDiabetes.vue";
import {Diabetesteam} from "@/modules/diabetesteam/domains";

export default {
  name: "cuDiabetesTeam",
  components: {CuDiabetesTeamForm},
  props: {
    diabetesTeam: Diabetesteam | null,
    value: false
  },
  data() {
    return {
      dialog: this.value
    }
  },
  computed: {
    getTitle() {
      return Boolean(this.diabetesTeam) ? this.$t('diabeticTeams.details.title') : this.$t('diabeticTeams.new.title');
    }
  },
  methods: {
    handleSubmit: function () {
      this.$emit('submit')
    },
    handleCancel: function () {
      this.$emit('input', false)
    }
  },
  watch: {
    value: function () {
      this.dialog = this.value;
    },
    dialog: function () {
      if (!this.dialog) {
        this.handleCancel();
      }
    }
  }
}
</script>

<style scoped>

</style>