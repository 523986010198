import {AxiansError} from "@/modules/core/errors/errors.domain";

interface ErrorState {
    errors: Array<AxiansError>;
}

export const errorStore = {
    namespaced: true,
    state: {
        errors: [],
    },
    mutations: {
        SET_ERRORS(state, errors: Array<AxiansError>) {
            state.errors = errors;
        },
        ADD_ERROR(state, error: AxiansError) {
            state.errors.push(error);
        },
        CLEAR_ERRORS(state) {
            state.errors = []
        },
        DELETE_ERROR(state, error: AxiansError) {
            state.errors = state.errors
                .filter((e: AxiansError) => e !== error);
        }
    },
    actions: {}
};
