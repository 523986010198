import { Action } from '@/modules/core/authorisation/access-right';

export class Route {
	name: string;
	publicRoute: boolean;
	sideBar: boolean;
	accessRight?: Action;

	constructor(name: string, publicRoute: boolean, sideBar: boolean, accessRight?: Action) {
		this.name = name;
		this.publicRoute = publicRoute;
		this.sideBar = sideBar;
		this.accessRight = accessRight;
	}
}

export const ROUTES = {
    WOMAN: new Route('woman', true, true),
    WOMAN_DETAILS: new Route('womanDetails', true, true),
    WOMAN_DETAILS_FOLLOWUP: new Route('womanDetailsFollowUp', true, true),
    WOMAN_DETAILS_REGISTRATION: new Route('womanDetailsRegistration', true, true),
    WOMAN_DETAILS_UITSCHRIJVEN: new Route('womanDetailsUitschrijven', true, true),
    WOMAN_DOUBLE_CHECK: new Route('womanDoubleCheck', true, true),
    DOCTORS: new Route('doctors', true, true),
    DIABETIC_TEAMS: new Route('diabeticTeams', true, true),
    CORRESPONDENTIE_BEHEER: new Route('CorrespondentieBeheer', true, true),
    BPM_BEHEER: new Route('BPMBeheer', false, true, Action.BPM_BEHEER),
};
