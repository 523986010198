<template>
  <v-dialog max-width='960' v-model='dialog'>
    <v-card>
      <v-card-title>
        <h4>{{ $t(`correspondentieBeheer.huisarts.edit.title`) }}</h4>
        <v-spacer/>
        <v-icon @click='handleCancel'>close</v-icon>
      </v-card-title>

      <v-card-text class='mt-2'>
        <cu-form :huisarts-template='huisartsTemplate' @submit='handleSubmit' @cancel='handleCancel'/>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import CuForm from "@/modules/correspondentiebeheer/forms/templates/huisartsCuForm.vue";
import {HuisartsTemplate} from "@/modules/correspondentiebeheer/domains";

export default {
  name: 'CuHuisartsTemplate',
  components: {CuForm},
  props: {
    huisartsTemplate: {
      type: HuisartsTemplate | null,
      required: true
    }
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    handleCancel(){
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit');
    },
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.handleCancel();
      }
    },
  },
};
</script>


<style scoped lang="scss">

</style>