import {getValueFromObject} from '@/modules/core/utils';
import {Gemeente} from "@/modules/gemeente/domain";

export class HuisArts {
	huisartsId: string;
	voornaam: string;
	naam: string;
	adres: string;
	gemeenteId: string;
	riziv: string;
	actief: boolean;
	gemeente: Gemeente | null;

	constructor(doctor: any) {
		this.huisartsId = getValueFromObject(doctor, 'huisartsId', '');
		this.voornaam = getValueFromObject(doctor, 'voornaam', '');
		this.naam = getValueFromObject(doctor, 'naam', '');
		this.adres = getValueFromObject(doctor, 'adres', '');
		this.gemeenteId = getValueFromObject(doctor, 'gemeenteId', '');
		this.riziv = getValueFromObject(doctor, 'riziv', '');
		this.actief = Boolean(doctor.actief);
		this.gemeente = this.gemeenteId? new Gemeente(doctor.gemeente) : null;
	}

	get fullName(): string {
		return `${this.voornaam} ${this.naam}`;
	}
}
