import {store} from "@/modules/core/stores/main-store";
import {AxiansError, ValidationCode} from "@/modules/core/errors/errors.domain";

export function downloadByGet(url): Promise<any> {
    return downloadBy(url, 'GET')
}

export function downloadByPost(url): Promise<any> {
    return downloadBy(url, 'POST')
}

//TODO: turn these into promises because refreshing page in drukker correspondentie happens too soon as it does not wait until the post is done
function downloadBy(url, method): Promise<any> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url, true);
        xhr.responseType = 'arraybuffer';

        xhr.ontimeout = () => reject(xhr.statusText);
        xhr.onerror = () => reject(xhr.statusText);

        xhr.onload = function () {
            if (this.status === 200) {
                let filename = '';
                const disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const type = xhr.getResponseHeader('Content-Type') || "application/octet-stream";

                const blob = new Blob([this.response], {
                    type: type,
                });
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    const URL = window.URL || window.webkitURL;
                    const downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        const a = document.createElement('a');
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.open(downloadUrl);
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.open(downloadUrl);
                    }

                    setTimeout(function () {
                        URL.revokeObjectURL(downloadUrl);
                    }, 100); // cleanup
                }
                resolve(xhr.response);
            } else {
                store.commit(
                    'error/ADD_ERROR',
                    new AxiansError(
                        {
                            code: ValidationCode.GENERAL_ERROR,
                            message: this.status === 404 ? 'common.errors.filenotfound' : 'common.errors.general',
                        },
                        this.status
                    )
                );
                reject(xhr.statusText);

            }
        };
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send();
    });
}
