<template>
	<v-form ref='form' @submit='handleSubmit'>
		<v-row>
			<v-col>
				<v-file-input
					v-model='form.file.value'
					:label='$t(form.file.label)'
					:rules='form.file.rules'
					outlined
				/>
			</v-col>
		</v-row>

		<v-row class='mb-2'>
			<v-spacer />
			<v-btn type='button' text @click="$emit('cancel')">
				{{ $t(`bpm.form.cancel`) }}
			</v-btn>
			<v-btn color='primary' type='submit' @click='handleSubmit'>
				{{ $t(`bpm.form.submit`) }}
			</v-btn>
		</v-row>
	</v-form>
</template>

<script>
import { UpdateTemplateForm } from '@/modules/bpmbeheer/forms/updateTemplate';
import { createNewBPMDeployment } from '@/modules/bpmbeheer/api';
import { mapState } from 'vuex';
import { TemplateType } from '@/modules/bpmbeheer/domains';

export default {
	name: 'updateTemplateForm',
	data() {
		return {
			form: new UpdateTemplateForm(this.$i18n, {type: TemplateType.mediakanaal}),
		};
	},
	computed: {
		...mapState('authorisationStore', ['userProfile']),
	},
	methods: {
		handleSubmit: async function(event) {
			event.preventDefault();

			if (!this.$refs.form.validate()) {
				return;
			}

			await createNewBPMDeployment(this.form.type.value, this.userProfile, this.form.file.value);

			this.$emit('submit');
		},
	},

};
</script>

<style scoped>

</style>