import Vue from 'vue';
import Router from 'vue-router';

import Woman from '@/modules/vrouw/pages/List.vue';
import WomanDetails from '@/modules/vrouw/pages/Details.vue';
import WomanFollowUp from '@/modules/vrouw/pages/FollowUp.vue';
import WomanDoubleCheck from '@/modules/vrouw/pages/DoubleChecks.vue';
import WomanRegistration from '@/modules/vrouw/pages/Registration.vue';
import WomanUitschrijven from '@/modules/vrouw/pages/Uitschrijven.vue';

import Doctors from '@/modules/huisarts/pages/List.vue'
import DiabeticTeams from '@/modules/diabetesteam/pages/List.vue'

import CorrespondentieBeheer from '@/modules/correspondentiebeheer/pages/Overview.vue';
import BpmBeheer from '@/modules/bpmbeheer/pages/Overview.vue';

import {store} from '@/modules/core/stores/main-store';
import {AxiansError, ValidationCode} from '@/modules/core/errors/errors.domain';
import {ROUTES} from '@/modules/core/router/routes';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    // base: Configuration.value("baseUrl"),
    routes: [
        {
            path: '/dossier',
            name: ROUTES.WOMAN.name,
            component: Woman,
            meta: {
                isPublic: ROUTES.WOMAN.publicRoute,
                accessRight: ROUTES.WOMAN.accessRight,
                sidebar: ROUTES.WOMAN.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },
        {
            path: '/dossier/:id',
            name: ROUTES.WOMAN_DETAILS.name,
            component: WomanDetails,
            meta: {
                isPublic: ROUTES.WOMAN_DETAILS.publicRoute,
                accessRight: ROUTES.WOMAN_DETAILS.accessRight,
                sidebar: ROUTES.WOMAN_DETAILS.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },
        {
            path: '/dossier/:womanId/opvolging/:type',
            name: ROUTES.WOMAN_DETAILS_FOLLOWUP.name,
            component: WomanFollowUp,
            meta: {
                isPublic: ROUTES.WOMAN_DETAILS_FOLLOWUP.publicRoute,
                accessRight: ROUTES.WOMAN_DETAILS_FOLLOWUP.accessRight,
                sidebar: ROUTES.WOMAN_DETAILS_FOLLOWUP.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },
        {
            path: '/dossier/:womanId/registratie',
            name: ROUTES.WOMAN_DETAILS_REGISTRATION.name,
            component: WomanRegistration,
            meta: {
                isPublic: ROUTES.WOMAN_DETAILS_REGISTRATION.publicRoute,
                accessRight: ROUTES.WOMAN_DETAILS_REGISTRATION.accessRight,
                sidebar: ROUTES.WOMAN_DETAILS_REGISTRATION.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },
        {
            path: '/dossier/:womanId/uitschrijven',
            name: ROUTES.WOMAN_DETAILS_UITSCHRIJVEN.name,
            component: WomanUitschrijven,
            meta: {
                isPublic: ROUTES.WOMAN_DETAILS_UITSCHRIJVEN.publicRoute,
                accessRight: ROUTES.WOMAN_DETAILS_UITSCHRIJVEN.accessRight,
                sidebar: ROUTES.WOMAN_DETAILS_UITSCHRIJVEN.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },
        {
            path: '/dossier/:id/dubbel',
            name: ROUTES.WOMAN_DOUBLE_CHECK.name,
            component: WomanDoubleCheck,
            meta: {
                isPublic: ROUTES.WOMAN_DOUBLE_CHECK.publicRoute,
                accessRight: ROUTES.WOMAN_DOUBLE_CHECK.accessRight,
                sidebar: ROUTES.WOMAN_DOUBLE_CHECK.sideBar,
                sideBarActiveRoute: ROUTES.WOMAN.name
            },
        },

        {
            path: '/huisarts',
            name: ROUTES.DOCTORS.name,
            component: Doctors,
            meta: {
                isPublic: ROUTES.DOCTORS.publicRoute,
                accessRight: ROUTES.DOCTORS.accessRight,
                sidebar: ROUTES.DOCTORS.sideBar,
                sideBarActiveRoute: ROUTES.DOCTORS.name
            },
        },
        {
            path: '/diabetesteam',
            name: ROUTES.DIABETIC_TEAMS.name,
            component: DiabeticTeams,
            meta: {
                isPublic: ROUTES.DIABETIC_TEAMS.publicRoute,
                accessRight: ROUTES.DIABETIC_TEAMS.accessRight,
                sidebar: ROUTES.DIABETIC_TEAMS.sideBar,
                sideBarActiveRoute: ROUTES.DIABETIC_TEAMS.name
            },
        },
        {
            path: '/correspondentie-beheer',
            name: ROUTES.CORRESPONDENTIE_BEHEER.name,
            component: CorrespondentieBeheer,
            meta: {
                isPublic: ROUTES.CORRESPONDENTIE_BEHEER.publicRoute,
                accessRight: ROUTES.CORRESPONDENTIE_BEHEER.accessRight,
                sidebar: ROUTES.CORRESPONDENTIE_BEHEER.sideBar,
                sideBarActiveRoute: ROUTES.CORRESPONDENTIE_BEHEER.name
            },
        },
        {
            path: '/bpm-beheer',
            name: ROUTES.BPM_BEHEER.name,
            component: BpmBeheer,
            meta: {
                isPublic: ROUTES.BPM_BEHEER.publicRoute,
                accessRight: ROUTES.BPM_BEHEER.accessRight,
                sidebar: ROUTES.BPM_BEHEER.sideBar,
                sideBarActiveRoute: ROUTES.BPM_BEHEER.name
            },
        },
    ],
});


router.beforeEach((to, from, next) => {
    store.dispatch('route/updatePrevRoute', from);

    if (to.meta?.isPublic) {
        next();
    } else {
        // check authentication
        if (store.state.authorisationStore.authenticated) {
            //check authorization
            const rights = store.state.authorisationStore.accessRights;
            if (!to.meta?.accessRight) {
                next();
            } else if (rights.includes(to.meta.accessRight)) {
                next();
            } else {
                store.commit('error/ADD_ERROR', new AxiansError({
                    code: ValidationCode.NOT_AUTHORIZED,
                    message: 'unauthorized',
                }, 401));
                next(false);
            }
        } else {
            store.commit('error/ADD_ERROR', new AxiansError({
                code: ValidationCode.NOT_AUTHORIZED,
                message: 'unauthorized',
            }, 401));
            next(false);
        }
    }
});
