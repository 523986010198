var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{class:{'cursor-pointer': _vm.canClick},attrs:{"large":"","right":"","color":_vm.getColor},nativeOn:{"click":function($event){return _vm.handleClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.item.timestamp.format('DD MMM YYYY')))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.item.timestamp.format('DD/MM/YYYY HH:mm')))])])]},proxy:true},{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.getIcon)+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.getTitle))]),(_vm.canRedoEmail)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"redo-text ml-4",on:{"click":_vm.handleRedoEmail}},'v-icon',attrs,false),on),[_vm._v(" alternate_email ")])]}}],null,false,1084366555)},[_c('span',[_vm._v(_vm._s(_vm.$t('woman.timeline.resend.e-mail_tooltip')))])]):_vm._e(),(_vm.canRedoPost)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"redo-text ml-4",on:{"click":_vm.handleRedoPost}},'v-icon',attrs,false),on),[_vm._v(" mail ")])]}}],null,false,1539313221)},[_c('span',[_vm._v(_vm._s(_vm.$t('woman.timeline.resend.post_tooltip')))])]):_vm._e(),(_vm.canRedoEmailDiabetesTeam)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"redo-text ml-4",on:{"click":_vm.handleRedoDiabetesEmail}},'v-icon',attrs,false),on),[_vm._v(" alternate_email ")])]}}],null,false,3564503922)},[_c('span',[_vm._v(_vm._s(_vm.$t('woman.timeline.resend.e-mail_tooltip')))])]):_vm._e(),(_vm.canRedoHuisartsNotificatie)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"redo-text ml-4",on:{"click":_vm.handleRedoHuisartsNotificatie}},'v-icon',attrs,false),on),[_vm._v(" send ")])]}}],null,false,336734002)},[_c('span',[_vm._v(_vm._s(_vm.$t('woman.timeline.resend.ehealth_tooltip')))])]):_vm._e()],1),(_vm.getMetaData.length)?_c('v-card-text',_vm._l((_vm.getMetaData),function(item){return _c('div',{staticClass:"info-row"},[_c('span',{staticClass:"info-row__label"},[_vm._v(" "+_vm._s(item.key)+" ")]),_c('span',{staticClass:"info-row__value"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_vm._e(),(_vm.canCloseTask)?_c('v-card-actions',[(_vm.closeTaskStatus === 'open')?_c('v-btn',{attrs:{"color":"orange"},on:{"click":function($event){_vm.closeTaskStatus = 'question'}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("done")])],1):_vm._e(),(_vm.closeTaskStatus ==='question')?_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('woman.timeline.complete_task')))])]),_c('v-col',[_c('v-btn',{attrs:{"text":"","color":"text"},on:{"click":function($event){_vm.closeTaskStatus = 'open'}}},[_vm._v(" "+_vm._s(_vm.$t('common.yes_no.no'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":_vm.handleCloseTask}},[_vm._v(_vm._s(_vm.$t('common.yes_no.yes')))])],1)],1):_vm._e()],1):_vm._e()],1),_c('polling',{attrs:{"poll-func":_vm.getTask}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }