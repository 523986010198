<template>
  <v-container>
    <v-row class="mb-3 mt-6">
      <v-col>
        <h2>{{ $t('doctors.header.title') }}</h2>
      </v-col>

      <v-col class="d-flex align-center justify-end">
        <v-btn color="primary"
               small
               class="ml-2"
               @click="handleNewDoctor">
          <v-icon class="material-icons-outlined">add</v-icon>
          <span class="pa-2">{{ $t('doctors.header.new') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-text-field
        label="Zoek"
        prepend-inner-icon="search"
        outlined
        v-model="searchInput"
        clearable
    />
    <v-data-table class="elevation-1"
                  calculate-widths
                  :headers="headers"
                  :items="doctors"
                  :options.sync="options"
                  :server-items-length="totalDoctors"
                  :footer-props="{'items-per-page-options': [100, 50, 25, 10, -1]}"
                  :items-per-page="100"
                  v-on:click:row="handleRowClick"
    >
      <template v-slot:item.actief="{item}">
        <v-icon class="material-icons-outlined">
          {{ item.actief ? 'done_outline' : 'close' }}
        </v-icon>
      </template>
    </v-data-table>
    <cu-huisarts v-if="dialogOpen"
                 v-model="dialogOpen"
                 :huis-arts="activeDoctor"
                 @submit="handleSubmitNewDoctor"/>
  </v-container>

</template>

<script>
import {getAllDoctors} from "@/modules/huisarts/api";
import CuHuisarts from "@/modules/huisarts/dialogs/cuHuisarts";

export default {
  name: "list",
  components: {CuHuisarts},
  data() {
    return {
      doctors: [],
      totalDoctors: 0,
      options: {},
      headers: [
        {text: this.$t("doctors.table.firstName"), value: "voornaam"},
        {text: this.$t("doctors.table.lastName"), value: "naam"},
        {text: this.$t("doctors.table.address"), value: "adres", sortable: false},
        {text: this.$t("doctors.table.gemeente"), value: "gemeente.fullName"},
        {text: this.$t("doctors.table.riziv"), value: "riziv"},
        {text: this.$t("doctors.table.active"), value: "actief"},
      ],
      searchInput: '',
      dialogOpen: false,
      activeDoctor: null,
    }
  },
  methods: {
    fetchAllDoctors: async function (searchInput = "") {
      try {
        const response = await getAllDoctors(searchInput, this.options);
        this.doctors = response.content;
        this.totalDoctors = response.totalElements;
      } catch (e) {
      }
    },
    fetchAllDoctorsDebounce: function (searchInput = "") {
      clearTimeout(this._fetchAllDoctorsTimer)
      this._fetchAllDoctorsTimer = setTimeout(() => {
        this.fetchAllDoctors(searchInput)
      }, 500)
    },
    handleRowClick: function (element) {
      this.activeDoctor = element;
      this.dialogOpen = true;
    },
    handleNewDoctor: function () {
      this.activeDoctor = null;
      this.dialogOpen = true;
    },
    handleSubmitNewDoctor: function () {
      this.dialogOpen = false;
      this.activeDoctor = false;
      this.searchInput = "";
      this.fetchAllDoctorsDebounce();
    }
  },
  watch: {
    searchInput: function (searchInput) {
      this.fetchAllDoctorsDebounce(searchInput || "");
    },
    options: {
      handler() {
        this.fetchAllDoctors(this.searchInput)
      }, deep: true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
