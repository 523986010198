import './modules/core/plugins/vuetify';
import App from './modules/app/App.vue';
import i18n from './i18n/i18n';
import vuetify from './modules/core/plugins/vuetify';
import errorPlugin from './modules/core/plugins/ErrorPlugin';
import loaderPlugin from './modules/core/plugins/LoaderPlugin';
import formatPlugin from './modules/core/plugins/FormatPlugin';
import Vue from 'vue';
import {router} from '@/modules/core/router/router';
import {store} from '@/modules/core/stores/main-store';
import {sidebarItems} from '@/modules/core/stores/navigation-store';
import {UserProfile, userProfileService} from '@/modules/user/services/user-profile-service';
import {configService} from '@/modules/config/services/config-service';
import VueMask from 'v-mask'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import initStoreData from "@/modules/core/stores/init-data";

import moment from "moment";
moment.locale('nl')


Vue.config.productionTip = false;

Vue.use(errorPlugin);
Vue.use(loaderPlugin);
Vue.use(formatPlugin);
Vue.use(VueMask);
Vue.use(VueTelInputVuetify, {vuetify})

// Remove all console log/error/warn/debug statements in production
if (process.env.NODE_ENV === 'production') {
    console.log = function () {
    };
    console.error = function () {
    };
    console.warn = function () {
    };
    console.debug = function () {
    };
}

userProfileService.fetchUserProfileSilent()
    .then((data: UserProfile) => {
        store.commit('authorisationStore/SET_USER_PROFILE', data);
        configService
            .fetchConfig()
            .then(config => {
                let timeout = config.pingTimeout != undefined ? config.pingTimeout : 5000;
                return setInterval(() => configService.ping(), timeout);
            });

        const routerMatch = router.match(window.location.pathname);

        if (routerMatch.matched.length) {
            const activeSidebarMeta = routerMatch.meta?.sideBarActiveRoute;
            const activeSideBarItem = sidebarItems.find((sideBarItem) => sideBarItem.route.name === activeSidebarMeta);

            store.dispatch('navigation/setCurrentItem', activeSideBarItem || sidebarItems[0])
        } else {
            store.dispatch('navigation/goTo', sidebarItems[0]);
        }

        initStoreData()

    })
    .catch(reason => {
        console.log(reason);
        window.location.href = '/oidc/sign_in'
    }).finally(() => initializeVue());

function initializeVue() {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
}


