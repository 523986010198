<template>
  <v-col ref="timelinecontainer" :style="{minHeight: height} ">
    <slot/>
  </v-col>
</template>

<script>
export default {
  name: "TimeLineContainer",
  data() {
    return {
      height: '100%'
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.calcSize);
  },
  mounted() {
    this.calcSize();
    window.addEventListener("resize", this.calcSize);

  },
  methods: {
    calcSize() {
      const bounds = this.$refs.timelinecontainer.getBoundingClientRect()
      this.height = `${window.innerHeight - bounds.top}px`;
    }
  }
}
</script>

<style scoped>

</style>