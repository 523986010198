




















import Vue from "vue";
import {mapState} from "vuex";
import {ModalAction, ModalType} from "@/modules/core/modal/modal.domain";

export default Vue.extend({
        name: "Modal",
        computed: {
            ...mapState('modal', ['modal']),
            shouldShowModal(): boolean {
                return Boolean(this.modal);
            },
            titleClass(): string {
                switch (this.modal.type) {
                    case ModalType.ERROR:
                        return `error-header`;
                    case ModalType.GENERAL:
                        return `general-header`;
                    default:
                        return `general-header`;
                }
            }
        },
        methods: {
            hideModal() {
                this.$store.commit('modal/HIDE_MODAL');
            },
            performAction(action: ModalAction) {
                action.callback();
                this.hideModal();
            }
        }
    })
