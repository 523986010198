<template>
	<v-dialog max-width='960' v-model='dialog'>
		<v-card>
			<v-card-title>
				<h4>{{ $t(`correspondentieBeheer.sms.${getCuKey}.title`) }}</h4>
				<v-spacer />
				<v-icon @click='handleCancel'>close</v-icon>
			</v-card-title>

			<v-card-text class='mt-8'>
				<cu-form :sms-template='smsTemplate' @submit='handleSubmit' @cancel='handleCancel'/>
			</v-card-text>

		</v-card>
	</v-dialog>
</template>

<script>
import { SMSTemplate } from '@/modules/correspondentiebeheer/domains';
import CuForm from '@/modules/correspondentiebeheer/forms/templates/smsCuForm.vue';

export default {
	name: 'CuSMSTemplate',
	components: { CuForm },
	props: {
		smsTemplate: SMSTemplate | null,
	},
	data() {
		return {
			dialog: true,
		};
	},
	computed: {
		getCuKey() {
			return Boolean(this.smsTemplate) ? 'edit' : 'add';
		},
	},
	methods: {
		handleCancel: function() {
			this.$emit('cancel');
		},
		handleSubmit: function() {
			this.$emit('submit');
		},
	},
	watch: {
		dialog: function() {
			if (!this.dialog) {
				this.handleCancel();
			}
		},
	},
};
</script>

<style scoped>

</style>