


















































import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import UserOptions from '@/modules/core/components/UserOptions.vue';
import {configService} from '@/modules/config/services/config-service';
import {router} from "@/modules/core/router/router";

export default Vue.extend({
  name: 'Sidebar',
  components: {UserOptions},
  props: {
    source: String,
  },
  data() {
    return {
      version: '',
    };
  },
  async mounted() {
    const config = await configService.fetchConfig();
    this.version = config.version;
  },
  computed: {
    ...mapState('navigation', ['items', 'sidebarShown', 'sidebarMini', 'currentItem']),
    ...mapState('authorisationStore', ['userProfile']),
    ...mapGetters('authorisationStore', ['accessRights']),
    ...mapGetters('navigation', ['canSeeItem']),
    collapseClass(): string {
      return `collapse-container d-flex align-center justify-center ${this.sidebarMini ? 'mini-sidebar' : ''}`;
    },
  },
  methods: {
    ...mapActions('navigation', ['goTo', 'goToInNewTab']),
    isCurrentRoute(item) {
      return this.currentItem?.route === item?.route;
    },
    getColorIcon(item) {
      return this.isCurrentRoute(item) ? 'primary' : 'null';
    },
    getColorText(item) {
      return this.isCurrentRoute(item) ? 'primary--text' : 'null';
    },
    childIsCurrentRoute(item) {
      return !item.model
          && item.children
          && item.children.some(c => this.isCurrentRoute(c));
    },
    navigateHome() {
      let route = '/';
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },
    toggleSideBarMini() {
      this.$store.commit('navigation/TOGGLE_SIDEBAR_MINI');
    },
    goToTaskList() {
      window.open('/camunda/app/tasklist/default/#/?searchQuery=%5B%5D', 'bpm');
    },
    handleClick(event, item) {
      if (event.ctrlKey) {
        const {href} = router.resolve({name: item.route.name});
        window.open(href, '_blank');
        return;
      }

      this.goTo(item);
    },
  },
});
