var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"header-btn",attrs:{"color":"primary","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-outlined"},[_vm._v("table_chart")]),_vm._v(" "+_vm._s(_vm.$t("woman.details.header.tableFollowUp"))+" ")],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.$t('woman.followUp.table.title'))+_vm._s(_vm.woman.fullName))]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){dialog.value = false}}},[_vm._v("close")])],1),_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.followUps},scopedSlots:_vm._u([{key:"item.opvolgingType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("woman.followUp.table.type." + (item.opvolgingType))))+" ")]}},{key:"item.consultatieHuisarts",fn:function(ref){
var item = ref.item;
return [(_vm.isIcon(item))?_c('v-icon',{staticClass:"material-icons-outlined"},[_vm._v(" "+_vm._s(_vm.getIcon(item))+" ")]):_c('span',[_vm._v(_vm._s(_vm.getIcon(item)))])]}},{key:"item.bloedglucoseWaarde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bloedglucoseWaarde ? item.bloedglucoseWaarde : '')+" ")]}},{key:"item.communicatieVerstuurd",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.communicatieVerstuurd ? item.communicatieVerstuurd.format("DD/MM/YYYY") : ''))])]}},{key:"item.antwoordOp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.antwoordOp ? item.antwoordOp.format("DD/MM/YYYY") : ''))])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v(" "+_vm._s(_vm.$t('woman.followUp.table.close'))+" ")])],1)],1)]}}]),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}})}
var staticRenderFns = []

export { render, staticRenderFns }