<template>
  <v-toolbar dense class="header-container">
    <div class="d-flex flex-column align-start">
      <v-btn text
             color="primary"
             :ripple="false"
             class="go-back"
             small
             v-on:click="handleGoBack($event)">
        <v-icon>chevron_left</v-icon>
        {{ backText }}
      </v-btn>
      <div class="breadcrumb">
        <h2 class="breadcrumb__fullname">{{ woman.fullName }}</h2>
        <span class="breadcrumb__divider"/>
        <span class="breadcrumb__place">
            {{ labelText }}
          </span>
      </div>
    </div>
    <v-spacer/>
    <slot/>
  </v-toolbar>
</template>

<script>
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {mapState} from "vuex";

export default {
  name: "headerContainer",
  props: {
    backText: String,
    labelText: String,
    woman: Vrouw,
    prevRouteShouldBe: Object
  },
  computed: {
    ...mapState('route', ['prevRoute']),
  },
  methods: {
    handleGoBack: function (event) {
      if (event.ctrlKey) {
        const route = this.$router.resolve(this.prevRouteShouldBe);
        window.open(route.href, "_blank");
      } else {
        if (this.prevRoute && this.prevRoute.name === this.prevRouteShouldBe.name) {
          this.$router.back();
        } else {
          this.$router.replace(this.prevRouteShouldBe)
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.header-container {
  background: unset !important;
  box-shadow: none !important;
  height: unset !important;
  margin-top: 16px;
  margin-bottom: 32px;


}

::v-deep .go-back {
  padding-left: 0 !important;
  margin-left: -8px;
}

.breadcrumb {
  display: flex;
  align-items: center;

  &__fullname {
  }

  &__divider {
    background: var(--v-text_light-base);
    height: 16px;
    width: 2px;
    margin: 0 16px;
  }

  &__place {
    color: var(--v-text_light-base);
  }
}


::v-deep .v-toolbar__content {
  height: unset !important;
  padding: 0;
}
</style>
