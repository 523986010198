import moment from 'moment';


const FormatPlugin = {
    install(Vue: any, options: any) {
        Vue.prototype.$dateTimeFormat = (dateTime, format = 'L') => moment(dateTime).format(format);
        Vue.prototype.$simpleDateFormat = (datetime) => {
            const mDateTime = moment(datetime);
            return mDateTime.local().format(`YYYY-MM-DD`);
        };

        Vue.filter('dateTimeFormat', (dateTime, format = 'DD-MM-YYYY HH:mm:ss') => {
            const m = moment(dateTime);
            if (m.isValid()) {
                return m.local().format(format);
            } else {
                return '';
            }
        });
        Vue.filter('simpleDateFormat', (dateTime) => Vue.prototype.$simpleDateFormat(dateTime));
    }
};

export default FormatPlugin;


