import {
	AxiansForm,
	FormItem,
	formRules,
	SelectItem,
} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import { TemplateType, TemplateTaal, BaseTemplate } from '@/modules/correspondentiebeheer/domains';
import { OpvolgingType } from '@/modules/vrouw/domains/opvolging';

export class TemplateBaseForm implements AxiansForm {
	type: FormItem;
	detailType: FormItem;
	taal: FormItem;

	constructor(key: string, translator: VueI18n, initialData?: BaseTemplate) {
		this.type = new FormItem(
			'type',
			`correspondentieBeheer.${key}.cuForm.type`,
			initialData?.type ?? ``,
			formRules.required(translator, `form.required`),
			-1,
			[
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.registratie`).toString(),
					TemplateType.registratie
				),
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.dubbele_registratie`).toString(),
					TemplateType.dubbele_registratie
				),
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.opvolging`).toString(),
					TemplateType.opvolging
				),
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.opvolging_herinnering`).toString(),
					TemplateType.opvolging_herinnering
				),
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.diabetes`).toString(),
					TemplateType.diabetes
				),
				new SelectItem(
					translator.t(`correspondentieBeheer.${key}.types.onbereikbaar`).toString(),
					TemplateType.onbereikbaar
				),
			]
		);

		this.detailType = new FormItem(
			'detailType',
			`correspondentieBeheer.${key}.cuForm.detailType`,
			initialData?.detailType ?? '',
			[],
			-1,
			Object.values(OpvolgingType).map(
				(opvolgingTypeValue: string) =>
					new SelectItem(
						translator
							.t(
								`correspondentieBeheer.${key}.detailTypes.opvolging.${opvolgingTypeValue.toLowerCase()}`
							)
							.toString(),
						opvolgingTypeValue.toLowerCase()
					)
			)
		);

		this.taal = new FormItem(
			'type',
			`correspondentieBeheer.${key}.cuForm.taal`,
			initialData?.taal ?? ``,
			formRules.required(translator, `form.required`),
			-1,
			[
				new SelectItem(translator.t(`common.languages.nl`).toString(), TemplateTaal.NL),
				new SelectItem(translator.t(`common.languages.fr`).toString(), TemplateTaal.FR),
				new SelectItem(translator.t(`common.languages.en`).toString(), TemplateTaal.EN),
			]
		);
	}

	toJSON(): { [p: string]: any } {
		let detailType =
			[TemplateType.opvolging, TemplateType.opvolging_herinnering].includes(this.type.value) &&
			this.detailType.value !== OpvolgingType.nietGekend
				? this.detailType.value
				: null;

		return {
			communicatieType: this.type.value,
			detailType,
			taal: this.taal.value,
		};
	}
}
