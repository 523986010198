export class AxiansModal {
    public type: ModalType;
    public title: string;
    public message: string;
    public cancelable: boolean;
    public actions: Array<ModalAction>;

    constructor(title: string, message: string, modalActions?: Array<ModalAction>, type?: ModalType, cancelable?: boolean) {
        this.type = type || ModalType.GENERAL;
        this.title = title;
        this.message = message;
        this.actions = modalActions || [];
        this.cancelable = cancelable || false;
    }
}

export enum ModalType {
    GENERAL = 'GENERAL',
    ERROR = 'ERROR'
}

export class ModalAction {
    public title: string;
    public callback: Function;

    constructor(title: string, callback: Function) {
        this.title = title;
        this.callback = callback;
    }
}