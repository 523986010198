<template>
  <v-container v-if="woman" fluid>
    <HeaderContainer :back-text="$t('woman.followUp.overview.header.back').toString()"
                     :label-text="$t(`woman.timeline.type.uitschrijven`).toString()"
                     :woman="woman"
                     :prev-route-should-be="prevRoute">
      <v-btn v-if="Boolean(processInstanceId)"
             :href="`/camunda/app/cockpit/default/#/process-instance/${processInstanceId}/runtime`"
      >
          {{ $t('common.tobpm') }}&nbsp;
          <v-icon>
          launch
        </v-icon>
      </v-btn>
    </HeaderContainer>
    <v-row>
      <v-col md="5">
        <uitschrijven-form :woman="woman"/>
      </v-col>

      <time-line-container>
        <time-line>
          <time-line-item v-for="item in logs.slice().reverse()"
                          :item="item"
                          :woman="woman"
                          :key="item.id"
                          :polling-seconds="pollingSeconds"
          />
        </time-line>
      </time-line-container>
    </v-row>
    <polling :poll-func="startPolling" :seconds="pollingSeconds"/>
  </v-container>
</template>

<script>
import HeaderContainer from "@/modules/vrouw/components/headerContainer";
import TimeLine from "@/modules/vrouw/components/TimeLine";
import TimeLineItem from "@/modules/vrouw/components/TimeLineItem";
import {getVrouwDoorId, getVrouwUitschrijvenLogs} from "@/modules/vrouw/api";
import TimeLineContainer from "@/modules/vrouw/components/TimeLineContainer";
import {ROUTES} from "@/modules/core/router/routes";
import Polling from "@/modules/core/components/Polling";
import UitschrijvenForm from "@/modules/vrouw/forms/uitschrijven/UitschrijvenForm.vue";
import {findPIDByProcessDefinitionKeyAndVariables} from "@/modules/camunda/api";
import {getEnv} from "@/modules/core/utils";

export default {
  name: "Uitschrijven",
  components: {
    UitschrijvenForm, Polling, TimeLineContainer, HeaderContainer, TimeLine, TimeLineItem
  },
  data() {
    return {
      woman: null,
      logs: [],
      type: null,
      processInstanceId: '',
      prevRoute: {name: ROUTES.WOMAN_DETAILS.name, params: {id: this.$route.params.womanId}}
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    pollingSeconds() {
      try {
        const pollingMilliseconds = Number(getEnv("VUE_APP_POLLING_UITSCHRIJVEN", 5000))
        return pollingMilliseconds / 1000;
      } catch (e) {
        return 5;
      }
    }
  },
  methods: {
    init: async function () {
      this.woman = await getVrouwDoorId(this.$route.params.womanId);
      this.processInstanceId = await findPIDByProcessDefinitionKeyAndVariables(this.woman.vrouwId, 'UitschrijvenProcess', []);
    },
    startPolling: async function () {
      this.logs = await getVrouwUitschrijvenLogs(this.$route.params.womanId);
    }
  }
}
</script>

<style scoped>

</style>
