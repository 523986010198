<template>
	<v-dialog max-width='920' v-model='dialogOpen'>
		<template v-slot:activator='{ on, attrs }'>
			<v-btn color='error'
						 small
						 class='header-btn'
						 v-bind='attrs'
						 v-on='on'>
				<v-icon class='material-icons-outlined'>stop_circle</v-icon>
				{{ $t('woman.details.header.stop') }}
			</v-btn>


		</template>

		<template v-slot:default='dialog'>

			<v-card class='pb-3'>
				<v-card-title>
					<h4>{{ $t('woman.stopzettingOpvolging.title') }}</h4>
					<v-spacer />
					<v-icon @click='dialog.value = false'>close</v-icon>
				</v-card-title>

				<v-card-text>

					<v-form ref='form' class='my-6'>
						<v-select v-model='form.reason.value'
											:label='$t(form.reason.label)'
											:rules='form.reason.rules'
											:items='form.reason.selectItems'
											outlined
						/>
					</v-form>

				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn text @click='dialog.value = false'>
						{{ $t('common.cancel') }}
					</v-btn>

					<v-btn color='error' @click='handleSubmit'>
						{{ $t('woman.stopzettingOpvolging.actions.submit') }}
					</v-btn>
				</v-card-actions>
			</v-card>


		</template>
	</v-dialog>
</template>

<script>
import { StopzettingOpvolgingForm } from '@/modules/vrouw/forms/stopzettingOpvolging';
import { correlateAMessage } from '@/modules/camunda/api';

export default {
	name: 'StopzettingOpvolging',
	props: {
		vrouwId: String,
	},
	data() {
		return {
			form: new StopzettingOpvolgingForm(this.$i18n),
			dialogOpen: false,
		};
	},
	methods: {
		handleSubmit: async function() {
			if (!this.$refs.form.validate()) {
				return;
			}
			try {
				await correlateAMessage(this.vrouwId, this.form.reason.value);
				this.dialogOpen = false;
				this.setSnackBarMessage('success');
				this.$emit('submit');
			} catch (e) {
				this.setSnackBarMessage('error');
			}

		},
		setSnackBarMessage(state) {
			this.$message.color = this.getSnackbarColor(state);
			this.$message.text = this.getSnackbarText(state);
			this.$message.show = true;
		},
		getSnackbarColor(state) {
			switch (state) {
				case 'success': {
					return '#4caf50';
				}
				case 'error': {
					return '#ff5252';
				}
				default:
					return 'grey4-base';
			}
		},
		getSnackbarText(state) {
			switch (state) {
				case 'success': {
					return this.$t('woman.stopzettingOpvolging.submit.success');
				}
				case 'error': {
					return this.$t('woman.stopzettingOpvolging.submit.error');
				}
				default:
					return '';
			}

		},
	},
};
</script>

<style scoped lang='scss'>

</style>