<template>

	<p v-if='readonly' class='mb-0 default-select-readonly'>
		{{ readonlyText }}
	</p>

  <div v-else class="d-flex flex-grow-1">
    <v-autocomplete
        :outlined='!isNotOutlined'
        :items='activeItems'
        :rules='rules'
        :label='$t(label)'
        :value='value'
        :readonly='readonlyOutlined'
        :clearable='clearable'
        @input='handleInput'
    />

    <v-btn v-if="refreshable" icon x-large @click="handleTypeChange">
      <v-icon>refresh</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {getAllDiabeticTeamsNoLimits} from '@/modules/diabetesteam/api';
import {getAllDoctorsNoLimits} from '@/modules/huisarts/api';
import {mapGetters, mapState} from 'vuex';

export default {
	name: 'defaultSelect',
	props: {
		value: {
			type: String,
			required: true,
		},
		rules: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		clearable: {
			type: Boolean,
			required: false,
		},
		readonly: {
			type: Boolean,
			required: false,
		},
		readonlyOutlined: {
			type: Boolean,
			required: false,
		},
		isNotOutlined: {
			type: Boolean,
			default: () => false,
		},
		type: {
			type: String,
			required: true,
		},
    refreshable: {
      type: Boolean,
      required: false,
      default: false
    }
	},
	data() {
		return {
			items: [],
			activeItems: [],
		};
	},
	mounted() {
		this.handleTypeChange();
	},
	computed: {
		...mapState('gemeenten', ['gemeenten']),
		...mapGetters('gemeenten', ['getGemeenteById']),
		readonlyText() {
			const item = this.items.find(item => item.value === this.value);
			return item?.text ?? '';
		},
	},
	methods: {
		handleInput(doctor) {
			this.$emit('input', doctor);
		},
		async handleTypeChange() {
			switch (this.type) {
				case 'doctor': {
					const doctors = await getAllDoctorsNoLimits();
					this.items = doctors.map(doctor => {
						return {
							value: doctor.huisartsId,
							text: `${doctor.fullName} - ${doctor.gemeente.naam}${doctor.actief? "": " (INACTIEF)"}`,
							actief: doctor.actief
						};
					});
          this.activeItems = this.items.filter(i => i.actief)
					break;
				}
				case 'diabeticTeam': {
					const diabeticTeams = await getAllDiabeticTeamsNoLimits();
					this.items = diabeticTeams.map(dt => {
						return {
							value: dt.diabetesteamId,
							text: dt.naam,
						};
					});
          this.activeItems = this.items
					break;
				}
				case 'city': {
					this.items = this.gemeenten.map(city => ({ value: city.gemeenteId, text: `${city.naam} (${city.postcode})` }));
          this.activeItems = this.items
					break;
				}
			}
		},
	},
	watch: {
		type() {
			this.handleTypeChange();
		},
		gemeenten() {
			this.handleTypeChange();
		},
	},
};
</script>

<style scoped lang='scss'>
::v-deep.v-input--is-readonly {
	.v-input__append-inner {
		display: none;
	}
}
</style>
