import {Module} from "vuex";
import {AxiansModal} from "@/modules/core/modal/modal.domain";

export default (): Module<any, any> => {
    return ({
        namespaced: true,
        state: {
            modal: null,
        },
        mutations: {
            SHOW_MODAL(state, modal: AxiansModal) {
                state.modal = modal;
            },
            HIDE_MODAL(state, modal: AxiansModal) {
                state.modal = null;
            }
        },
        actions: {
        }
    });
};