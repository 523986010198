import {
	AxiansForm,
	FormItem,
	formRules,
	SelectItem,
} from '@/modules/core/components/forms/forms.model';
import moment from 'moment';
import VueI18n from 'vue-i18n';
import { getValueFromObject } from '@/modules/core/utils';

export enum ControlOptions {
	YES = 'J',
	NO = 'N',
	NO_BREASTFEEDING = 'Borstvoeding',
	NO_PLANNED = 'Gepland',
	NIET_GEKEND = '',
}

export enum DiabeticOptions {
	YES = 'yes',
	NO = 'no',
}

export type M3FormType = {
	deliveryDate: string;
	control: string;
	diabetic: string;
};

export class M3Result {
	uiteindelijkeBevallingsDatum: moment.Moment;
	naBevallingBloedafname: ControlOptions;
	naBevallingDiabetes: DiabeticOptions;

	constructor(result: any) {
		this.uiteindelijkeBevallingsDatum = moment(
			getValueFromObject(result, 'uiteindelijkeBevallingsDatum', null)
		);
		this.naBevallingBloedafname = this._getControlType(
			getValueFromObject(result, 'naBevallingBloedafname', '')
		);
		this.naBevallingDiabetes = this._getDiabeticType(
			getValueFromObject(result, 'naBevallingDiabetes', false)
		);
	}

	private _getControlType(controlType: string): ControlOptions {
		switch (controlType) {
			case 'J':
				return ControlOptions.YES;
			case 'N':
				return ControlOptions.NO;
			case 'Borstvoeding':
				return ControlOptions.NO_BREASTFEEDING;
			case 'Gepland':
				return ControlOptions.NO_PLANNED;
			default:
				return ControlOptions.NIET_GEKEND;
		}
	}

	private _getDiabeticType(diabeticType: boolean): DiabeticOptions {
		return Boolean(diabeticType) ? DiabeticOptions.YES : DiabeticOptions.NO;
	}
}

export class M3Form implements AxiansForm {
	uiteindelijkeBevallingsDatum: FormItem;
	naBevallingBloedafname: FormItem;
	naBevallingDiabetes: FormItem;

	constructor(translator: VueI18n, initialData?: M3Result) {
		this.uiteindelijkeBevallingsDatum = new FormItem(
			'deliveryDate',
			'woman.followUp.3m.form.deliveryDate.label',
			initialData?.uiteindelijkeBevallingsDatum.format('YYYY-MM-DD'),
			formRules.required(translator, 'woman.followUp.3m.form.deliveryDate.required')
		);

		this.naBevallingBloedafname = new FormItem(
			'control',
			'woman.followUp.3m.form.control.label',
			initialData?.naBevallingBloedafname ?? '',
			formRules.required(translator, 'woman.followUp.3m.form.deliveryDate.required'),
			-1,
			[
				new SelectItem('woman.followUp.3m.form.control.options.yes', ControlOptions.YES),
				new SelectItem('woman.followUp.3m.form.control.options.no', ControlOptions.NO),
				new SelectItem(
					'woman.followUp.3m.form.control.options.noBreastFeeding',
					ControlOptions.NO_BREASTFEEDING
				),
				new SelectItem(
					'woman.followUp.3m.form.control.options.noPlanned',
					ControlOptions.NO_PLANNED
				),
			]
		);

		this.naBevallingDiabetes = new FormItem(
			'diabetic',
			'woman.followUp.3m.form.diabetic.label',
			initialData?.naBevallingDiabetes ?? '',
			formRules.required(translator, 'woman.followUp.3m.form.deliveryDate.required'),
			-1,
			[
				new SelectItem('woman.followUp.3m.form.diabetic.options.yes', DiabeticOptions.YES),
				new SelectItem('woman.followUp.3m.form.diabetic.options.no', DiabeticOptions.NO),
			]
		);
	}

	toJSON(): M3FormType {
		return {
			deliveryDate: moment(this.uiteindelijkeBevallingsDatum.value).format('YYYY-MM-DD'),
			control: this.naBevallingBloedafname.value,
			diabetic: this.naBevallingDiabetes.value,
		};
	}
}
