<template>
	<v-container>
		<v-row class='mb-3 mt-6'>
			<v-col>
				<h2>{{ $t('woman.header.title') }}</h2>
			</v-col>

			<v-col class='d-flex align-center justify-end'>
				<export-dossiers />
				<woman-c-u @submitted='handleNewDossier' />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-text-field
					:label="$t('woman.search.label')"
					prepend-inner-icon='search'
					outlined
					v-model='searchInput'
					clearable
				/>
			</v-col>
			<v-col>
				<v-text-field
					:label="$t('woman.search.on-corr-number')"
					prepend-inner-icon='manage_search'
					outlined
					v-model='searchInputCorrNumber'
					clearable
					@keyup.enter='handleEnter'
				/>
			</v-col>
		</v-row>

		<v-data-table
			class='elevation-1'
			calculate-widths
			:headers='headers'
			:items='women'
			:options.sync='options'
			:server-items-length='totalWoman'
			:footer-props="{ 'items-per-page-options': [100, 50, 25, 10, -1] }"
			:items-per-page='100'
			v-on:click:row='handleRowClick'
		>
			<template v-slot:item.registratieKanaal='{ item }'>
				<div class='d-flex align-center'>
					<v-icon class='material-icons-outlined' :color='getRegistrationSourceColor(item)'>
						{{ getRegistrationSourceIcon(item) }}
					</v-icon>
				</div>
			</template>
			<template v-slot:item.geboortedatum='{ item }'>
				<span>{{ item.geboortedatum ? item.geboortedatum.format('DD/MM/YYYY') : '' }}</span>
			</template>
			<template v-slot:item.registratieDatum='{ item }'>
				<span>{{ item.registratieDatum.format('DD/MM/YYYY') }}</span>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import {getAlleVrouwen, getVrouwDoorCorrespondentieNummer} from '@/modules/vrouw/api';
import {RegistratieKanaal} from '@/modules/vrouw/domains/vrouw';
import {ROUTES} from '@/modules/core/router/routes';
import WomanCU from '@/modules/vrouw/dialogs/WomanCU';
import ExportDossiers from '@/modules/vrouw/dialogs/ExportDossiers.vue';

export default {
	name: 'list',
	components: { ExportDossiers, WomanCU },
	data() {
		return {
			women: [],
			totalWoman: 0,
			options: {},
			headers: [
				{
					text: this.$t('woman.table.registrationSource.label'),
					sortable: false,
					value: 'registratieKanaal',
				},
				{ text: this.$t('woman.table.firstName'), value: 'voornaam' },
				{ text: this.$t('woman.table.lastName'), value: 'naam' },
				{
					text: this.$t('woman.table.birthday'),
					value: 'geboortedatum',
					sort: (a, b) => {
						return a.isAfter(b) ? 1 : -1;
					},
				},
				{
					text: this.$t('woman.table.registratieDatum'),
					value: 'registratieDatum',
					sort: (a, b) => {
						return a.isAfter(b) ? 1 : -1;
					},
				},
				{ text: this.$t('woman.table.email'), value: 'email' },
				{ text: this.$t('woman.table.telefoon'), value: 'telefoon' },
			],
			searchInput: '',
			searchInputCorrNumber: '',
			ctrlUp: false,
		};
	},
	created() {
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	},
	beforeDestroy() {
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	},
	methods: {
		fetchAllWomen: async function(searchInput = '', options = this.options) {
			try {
				const response = await getAlleVrouwen(searchInput, options);
				this.women = response.content;
				this.totalWoman = response.totalElements;
			} catch (e) {
			}
		},
		fetchAllWomenDebounce: function(searchInput = '') {
			clearTimeout(this._fetchAllWomenTimer);
			this._fetchAllWomenTimer = setTimeout(() => {
				if (this.options.page === 1) {
					this.fetchAllWomen(searchInput);
				} else {
					this.options.page = 1; // this will trigger the watcher for the options
				}
			}, 500);
		},
		fetchWomanByCorrNumber: async function() {
			if (this.searchInputCorrNumber.length < 7) {
				return;
			}

			try {
				const woman = await getVrouwDoorCorrespondentieNummer(this.searchInputCorrNumber);
				this.$message.color = '#4caf50';
				this.$message.text = this.$t('woman.search.corr-found');
				this.$message.show = true;
				this.handleRowClick(woman);
			} catch (e) {
				this.$message.color = '#ff5252';
				this.$message.text = this.$t('woman.search.no-corr-found');
				this.$message.show = true;
			}
		},
		handleRowClick: function(element) {
			if (this.ctrlUp) {
				const route = this.$router.resolve({ name: ROUTES.WOMAN_DETAILS.name, params: { id: element.vrouwId } });
				window.open(route.href, element.vrouwId);
				return;
			}
			this.$router.push({ name: ROUTES.WOMAN_DETAILS.name, params: { id: element.vrouwId } });
		},
		getRegistrationSourceIcon: function(item) {
			return item.registratieKanaal === RegistratieKanaal.online ? 'devices' : 'description';
		},
		getRegistrationSourceColor: function(item) {
			if (item.voorlopigeRegistratie) {
				return 'grey';
			}

			return item.registratieKanaal === RegistratieKanaal.online ? 'green' : 'blue';
		},
		exportRows: function() {
			alert(`Jan, Write an API call to export these ${this.totalWoman} rows`);
		},
		handleEnter: function(event) {
			event.preventDefault();
			this.fetchWomanByCorrNumber();
		},
		handleNewDossier: function() {
			this.fetchAllWomenDebounce(this.searchInput);
		},

		handleKeyDown: function(event) {
			if (event.key === 'Control') {
				this.ctrlUp = true;
			}
		},
		handleKeyUp: function(event) {
			if (event.key === 'Control') {
				this.ctrlUp = false;
			}
		},
	},
	watch: {
		searchInput: function(searchInput) {
			this.fetchAllWomenDebounce(searchInput);
		},
		options: {
			handler() {
				this.fetchAllWomen(this.searchInput);
			},
			deep: true,
		},
	},
};
</script>

<style scoped lang='scss'></style>
