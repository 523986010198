import VueI18n from 'vue-i18n';
import {FormItem, formRules} from '@/modules/core/components/forms/forms.model';
import {Vrouw} from '@/modules/vrouw/domains/vrouw';
import {DetailsForm} from '@/modules/vrouw/forms/details/detailsForm';

export class ContactForm extends DetailsForm<Vrouw> {
	adres: FormItem;
	gemeenteId: FormItem;
	telefoon: FormItem;
	email: FormItem;
	bereikbaarPost: FormItem;
	bereikbaarEmail: FormItem;
	bereikbaarSms: FormItem;
	bereikbaarBellen: FormItem;

	constructor(translator: VueI18n, initialData?: Vrouw) {
		super(initialData);

		this.adres = new FormItem(
			'address',
			'woman.details.contact.address',
			initialData?.adres ?? '',
			// formRules.required(translator, 'woman.details.required')
		);

		this.gemeenteId = new FormItem(
			'city',
			'woman.details.contact.city',
			initialData?.gemeenteId ?? ''
		);

		this.telefoon = new FormItem(
			'phone',
			'woman.details.contact.telefoon',
			initialData?.telefoon ?? ''
		);

		this.email = new FormItem(
			'email',
			'woman.details.contact.email',
			initialData?.email ?? '',
			formRules.isEmail(translator, 'form.email')
		);

		this.bereikbaarPost = new FormItem(
			'bereikbaarPost',
			'woman.details.contact.bereikbaarPost',
			initialData?.bereikbaarPost ?? false
		);
		this.bereikbaarEmail = new FormItem(
			'bereikbaarEmail',
			'woman.details.contact.bereikbaarEmail',
			initialData?.bereikbaarEmail ?? false
		);
		this.bereikbaarSms = new FormItem(
			'bereikbaarSms',
			'woman.details.contact.bereikbaarSms',
			initialData?.bereikbaarSms ?? false
		);
		this.bereikbaarBellen = new FormItem(
			'bereikbaarTelefoon',
			'woman.details.contact.bereikbaarTelefoon',
			initialData?.bereikbaarBellen ?? false
		);
	}

	public resetData(): void {
		if (!this.initialData) {
			return;
		}
		this.adres.value = this.initialData.adres;
		this.telefoon.value = this.initialData.telefoon;
		this.email.value = this.initialData.email;
		this.bereikbaarPost.value = this.initialData.bereikbaarPost;
		this.bereikbaarEmail.value = this.initialData.bereikbaarEmail;
		this.bereikbaarSms.value = this.initialData.bereikbaarSms;
		this.bereikbaarBellen.value = this.initialData.bereikbaarBellen;
		this.gemeenteId.value = this.initialData.gemeenteId;
	}

	toJSON(): { [p: string]: any } {
		return {
			streetNameAndHouseNumber: this.adres.value,
			city: this.gemeenteId.value,
			telefoon: this.telefoon.value,
			email: this.email.value ? this.email.value : null,
			bereikbaarPost: this.bereikbaarPost.value,
			bereikbaarEmail: this.bereikbaarEmail.value,
			bereikbaarSms: this.bereikbaarSms.value,
			bereikbaarBellen: this.bereikbaarBellen.value,
		};
	}
}
