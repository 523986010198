import moment from "moment/moment";
import {getValueFromObject} from "@/modules/core/utils";

export enum RegistratieKanaal {
    online = 'online',
    papier = 'papier',
}

export class Vrouw {
    vrouwId: string;
    voornaam: string;
    naam: string;
    geboortedatum: moment.Moment | null;
    registratieDatum: moment.Moment;
    jaarlijkseOpvolgingDatum: moment.Moment
    registratieKanaal: RegistratieKanaal;
    email: string;
    adres: string;
    gemeenteId: string;
    telefoon: string;
    taal: string;
    voorkeurEmail: boolean;
    comment: string;
    huisartsId: string;
    extraDokterData: { naam: string, gemeenteId: string };
    diabetesteamId: string;
    voorlopigeRegistratie: boolean;
    bereikbaarPost: boolean;
    bereikbaarEmail: boolean;
    bereikbaarSms: boolean;
    bereikbaarBellen: boolean;
    gewicht: string;
    length: string;
    vermoedelijkeBevallingsDatum: moment.Moment;
    stopzetting: any | null;


    constructor(woman: any) {
        const contact = woman.contact;

        this.vrouwId = woman.vrouwId;
        this.voornaam = woman.voornaam;
        this.naam = woman.naam;
        this.geboortedatum = woman.geboortedatum ? moment(woman.geboortedatum) : null;
        this.registratieDatum = moment(woman.registratieDatum);
        this.jaarlijkseOpvolgingDatum = moment(woman.jaarlijkseOpvolgingDatum);
        this.registratieKanaal = this._getRegistrationSource(getValueFromObject(woman, "registratieKanaal", ""))
        this.email = contact?.email || "";
        this.adres = getValueFromObject(woman, 'contact.adres', '')
        this.gemeenteId = getValueFromObject(woman, 'contact.gemeenteId', '')
        this.telefoon = getValueFromObject(woman, 'contact.telefoon', "")
        this.taal = getValueFromObject(woman, 'taal', "")
        this.voorkeurEmail = woman?.contact.voorkeurEmail;
        this.comment = getValueFromObject(woman, 'comment', "")
        this.huisartsId = getValueFromObject(woman, 'huisartsId', "")
        this.extraDokterData = {
            naam: getValueFromObject(woman, 'onlineHuisartsData.naam', ""),
            gemeenteId: getValueFromObject(woman, 'onlineHuisartsData.gemeenteId', ""),
        }
        this.diabetesteamId = getValueFromObject(woman, 'diabetesteamId', "")
        this.voorlopigeRegistratie = getValueFromObject(woman, 'voorlopigeRegistratie', false)
        this.bereikbaarPost = getValueFromObject(woman, 'contact.bereikbaarPost', false)
        this.bereikbaarEmail = getValueFromObject(woman, 'contact.bereikbaarEmail', false)
        this.bereikbaarSms = getValueFromObject(woman, 'contact.bereikbaarSms', false)
        this.bereikbaarBellen = getValueFromObject(woman, 'contact.bereikbaarBellen', false)
        this.gewicht = getValueFromObject(woman, 'prenataal.gewicht', '')
        this.length = getValueFromObject(woman, 'prenataal.length', '')
        this.vermoedelijkeBevallingsDatum = moment(woman.prenataal.vermoedelijkeBevallingsDatum)
        this.stopzetting = getValueFromObject(woman, 'stopzetting', null)
    }

    get fullName(): string {
        if (!this.voornaam || !this.naam) {
            return this.email
        }

        return `${this.voornaam} ${this.naam}`
    }
    get reference(): string {
        return `${this.fullName} (${this.vrouwId})`
    }

    get hasNoHuisartsAndExtraDoctorData(): boolean {
        return Boolean(this.extraDokterData && this.extraDokterData.naam && !this.huisartsId );
    }

    private _getRegistrationSource(registrationSource: string): RegistratieKanaal {
        switch (registrationSource) {
            case "online":
                return RegistratieKanaal.online;
            case "papier":
                return RegistratieKanaal.papier;
            default:
                return RegistratieKanaal.online
        }
    }
}
