import { httpService } from '@/modules/core/services/http-service';
import { BPMFile, TemplateType } from '@/modules/bpmbeheer/domains';
import { UserProfile } from '@/modules/user/services/user-profile-service';

export async function getTaskInfoByTaskNameAndBusinessKey(
	taskName: string,
	businessKey: string,
	processInstanceId: string
): Promise<{ id: string; name: string } | null> {
	try {
		let url = `/camunda/engine-rest/task?taskDefinitionKey=${taskName}&processInstanceBusinessKey=${businessKey}`;
		if (processInstanceId) {
			url += `&processInstanceId=${processInstanceId}`;
		}
		const response: any = await httpService.get(url);
		return { id: response[0].id, name: response[0].name };
	} catch (e) {
		return null;
	}
}

export async function completeTaskById(taskId: string, payload: any = {}): Promise<void> {
	await httpService.post(`/camunda/engine-rest/task/${taskId}/complete`, payload);
}

export async function isProcessInstanceStillRunning(processInstanceId: string): Promise<boolean> {
	try {
		await httpService.get(`/camunda/engine-rest/process-instance/${processInstanceId}`);
		return true;
	} catch (e) {
		return false;
	}
}

export async function getAllTasksByPID(pid: string): Promise<any> {
	try {
		const childPIDs: any[] = await httpService.post<any[]>(
			`/camunda/engine-rest/process-instance`,
			{
				superProcessInstance: pid,
			}
		);

		const childTasks = await Promise.all(
			childPIDs.map((child: any) =>
				httpService.get(`/camunda/engine-rest/task?processInstanceId=${child.id}`)
			)
		);

		const rootTasks: any[] = await httpService.get(
			`/camunda/engine-rest/task?processInstanceId=${pid}`
		);

		return rootTasks.concat(childTasks.flatMap((it) => it));
	} catch (e) {
		return [];
	}
}

export async function correlateAMessage(vrouwId: string, messageName: string): Promise<void> {
	await httpService.post(`/camunda/engine-rest/message`, {
		businessKey: vrouwId,
		messageName: messageName,
	});
}

export async function findPIDByProcessDefinitionKeyAndVariables(
	vrouwId: string,
	processDefinitionKey: string,
	variables: { key: string; value: string }[]
): Promise<string> {
	try {
		const response = await httpService.post<any[]>(`/camunda/engine-rest/process-instance`, {
			businessKey: vrouwId,
			processDefinitionKey: processDefinitionKey,
			variables: variables.map(({ key, value }) => ({ name: key, operator: 'eq', value })),
		});
		return response[0].id;
	} catch (e) {
		return '';
	}
}

export async function getDeployments(): Promise<BPMFile[]> {
	const deployments: any[] = await httpService.get(
		`/camunda/engine-rest/deployment?name=${TemplateType.mediakanaal}&sortBy=deploymentTime&sortOrder=desc`
	);

	if (!deployments.length && !deployments[0].id) {
		return Promise.reject('No deployments were found.');
	}

	const deployment = deployments[0];

	const resources: any[] = await httpService.get(
		`/camunda/engine-rest/deployment/${deployments[0].id}/resources`
	);

	return [
		new BPMFile({
			id: '',
			type: TemplateType.mediakanaal,
			laatstGewijzigdOp: deployment.deploymentTime,
			laatstGewijzigdDoor: deployment.source.replace('frontend__', ''),
			link: `/camunda/engine-rest/deployment/${deployment.id}/resources/${resources[0].id}/data`,
		}),
	];
}

export async function createNewDeployment(
	type: TemplateType,
	currentVrouw: UserProfile,
	newFile: File
): Promise<void> {
	const formData = new FormData();
	formData.append('deployment-name', type);
	formData.append('deployment-source', `frontend__${currentVrouw.username}`);
	formData.append('deploy-changed-only', 'false');
	formData.append('enable-duplicate-filtering', 'true');
	formData.append('*', newFile);
	await httpService.postWithFile(`/camunda/engine-rest/deployment/create`, formData);
}
