<template>
  <v-card class="pa-6">
    <v-form ref="form">

      <date-field :date.sync="form.uiteindelijkeBevallingsDatum.value"
                  :rules="form.uiteindelijkeBevallingsDatum.rules"
                  :label="form.uiteindelijkeBevallingsDatum.label"
                  :name="form.uiteindelijkeBevallingsDatum.id"
      />

      <v-radio-group v-model="form.naBevallingBloedafname.value"
                     :label="$t(form.naBevallingBloedafname.label)"
                     :name="form.naBevallingBloedafname.id"
                     :rules="form.naBevallingBloedafname.rules">
        <v-radio v-for="option in form.naBevallingBloedafname.selectItems"
                 :key="option.value"
                 :value="option.value"
                 :label="$t(option.text)"
        />
      </v-radio-group>

      <v-radio-group v-model="form.naBevallingDiabetes.value"
                     :label="$t(form.naBevallingDiabetes.label)"
                     :name="form.naBevallingDiabetes.id"
                     :rules="form.naBevallingDiabetes.rules">
        <v-radio v-for="option in form.naBevallingDiabetes.selectItems"
                 :key="option.value"
                 :value="option.value"
                 :label="$t(option.text)"
        />
      </v-radio-group>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="saveForm">
          <v-icon>save</v-icon>
          <span class="pa-2">
            {{ $t('woman.followUp.3m.form.save') }}
          </span>
        </v-btn>
      </v-card-actions>

    </v-form>
  </v-card>
</template>

<script>
import {M3Form} from "@/modules/vrouw/forms/followUp/3mForm";
import {getVrouwOpvolgingResultaat, updateVrouwDetails3m} from "@/modules/vrouw/api";
import {OpvolgingType} from "@/modules/vrouw/domains/opvolging";
import DateField from "@/modules/core/components/forms/DateField";

export default {
  name: "M3Form",
  components: {DateField},
  props: {
    womanId: String
  },
  data() {
    return {
      formData: null,
      form: new M3Form(this.$i18n)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: async function () {
      this.formData = await getVrouwOpvolgingResultaat(this.womanId, OpvolgingType.M3);

      if (this.formData) {
        this.form = new M3Form(this.$i18n, this.formData)
      }
    },
    saveForm: async function () {
      if (this.$refs.form.validate()) {
        await updateVrouwDetails3m(this.womanId, this.form.toJSON())
        this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.followUp.saved');
        this.$message.show = true;
      }
    }
  }
}
</script>

<style scoped>
</style>
