import { UserRole } from '@/modules/core/authorisation/auth.domain';

export enum Action {
	ROUTE_INFO = 'ROUTE_INFO',
	ROUTE_EXAMPLES = 'ROUTE_EXAMPLES',
	ROUTE_TRANSLATIONS = 'ROUTE_TRANSLATIONS',
	ROUTE_ROUTER = 'ROUTE_ROUTER',
	ROUTE_VUEX = 'ROUTE_VUEX',
	ROUTE_VUETIFY = 'ROUTE_VUETIFY',
	ROUTE_WORKBOX = 'ROUTE_WORKBOX',
	ROUTE_DATETIME = 'ROUTE_DATETIME',
	ROUTE_FORM = 'ROUTE_FORM',
	ROUTE_MESSAGES = 'ROUTE_MESSAGES',
	ROUTE_DASHBOARD = 'ROUTE_DASHBOARD',
	ROUTE_EXAMPLES_DASHBOARD = 'ROUTE_EXAMPLES_DASHBOARD',
	ROUTE_DIALOG = 'ROUTE_DIALOG',
	ROUTE_TABLE = 'ROUTE_TABLE',
	THEME_PICKER = 'THEME_PICKER',
	LANG_PICKER = 'LANG_PICKER',
	CAMUNDA = 'CAMUNDA',
	BPM_BEHEER = 'BPM_BEHEER',
}

export const ACCESS_RIGHTS: { [key in Action]: Array<UserRole> } = {
	LANG_PICKER: [UserRole.USER, UserRole.ADMIN],
	ROUTE_DASHBOARD: [UserRole.USER, UserRole.ADMIN],
	ROUTE_DATETIME: [UserRole.ADMIN],
	ROUTE_DIALOG: [UserRole.ADMIN],
	ROUTE_EXAMPLES: [UserRole.ADMIN],
	ROUTE_FORM: [UserRole.ADMIN],
	ROUTE_INFO: [UserRole.ADMIN],
	ROUTE_MESSAGES: [UserRole.ADMIN],
	ROUTE_ROUTER: [UserRole.ADMIN],
	ROUTE_TABLE: [UserRole.ADMIN],
	ROUTE_TRANSLATIONS: [UserRole.ADMIN],
	ROUTE_EXAMPLES_DASHBOARD: [UserRole.ADMIN],
	ROUTE_VUETIFY: [UserRole.ADMIN],
	ROUTE_WORKBOX: [UserRole.ADMIN],
	ROUTE_VUEX: [UserRole.ADMIN],
	THEME_PICKER: [UserRole.ADMIN],
	CAMUNDA: [UserRole.ADMIN, UserRole.USER],
	BPM_BEHEER: [UserRole.ADMIN],
};

export const getAccessRightsByRoles = (userRoles: UserRole[]): Array<Action> => {
	return Object.entries(ACCESS_RIGHTS)
		.map(([action, roles]: [string, Array<UserRole>]): Action | null => {
			const hasRights = roles.some((role: UserRole) => userRoles.includes(role));
			return hasRights ? Action[action] : null;
		})
		.filter((action) => action !== null) as Array<Action>;
};
