<template>
  <v-timeline-item large right :color='getColor' :class="{'cursor-pointer': canClick}" @click.native='handleClick'>

    <template v-slot:opposite>
      <v-tooltip bottom max-width='400'>
        <template v-slot:activator='{on, attrs}'>
          <span v-on='on' v-bind='attrs'>{{ item.timestamp.format('DD MMM YYYY') }}</span>
        </template>
        <span>{{ item.timestamp.format('DD/MM/YYYY HH:mm') }}</span>
      </v-tooltip>
    </template>

    <template v-slot:icon>
      <v-icon color='white'>
        {{ getIcon }}
      </v-icon>
    </template>

    <template v-slot:default>
      <v-card>
        <v-card-title>
          <span class='mr-auto'>{{ getTitle }}</span>

          <v-tooltip v-if='canRedoEmail' bottom max-width='400'>
            <template v-slot:activator='{on, attrs}'>
              <v-icon class='redo-text ml-4'
                      @click='handleRedoEmail'
                      v-on='on' v-bind='attrs'>
                alternate_email
              </v-icon>
            </template>
            <span>{{ $t('woman.timeline.resend.e-mail_tooltip') }}</span>
          </v-tooltip>

          <v-tooltip v-if='canRedoPost' bottom max-width='400'>
            <template v-slot:activator='{on, attrs}'>
              <v-icon class='redo-text ml-4'
                      @click='handleRedoPost'
                      v-on='on'
                      v-bind='attrs'>
                mail
              </v-icon>
            </template>
            <span>{{ $t('woman.timeline.resend.post_tooltip') }}</span>
          </v-tooltip>

          <v-tooltip v-if='canRedoEmailDiabetesTeam' bottom max-width='400'>
            <template v-slot:activator='{on, attrs}'>
              <v-icon class='redo-text ml-4'
                      @click='handleRedoDiabetesEmail'
                      v-on='on' v-bind='attrs'>
                alternate_email
              </v-icon>
            </template>
            <span>{{ $t('woman.timeline.resend.e-mail_tooltip') }}</span>
          </v-tooltip>

          <v-tooltip v-if='canRedoHuisartsNotificatie' bottom max-width='400'>
            <template v-slot:activator='{on, attrs}'>
              <v-icon class='redo-text ml-4'
                      @click='handleRedoHuisartsNotificatie'
                      v-on='on' v-bind='attrs'>
                send
              </v-icon>
            </template>
            <span>{{ $t('woman.timeline.resend.ehealth_tooltip') }}</span>
          </v-tooltip>

        </v-card-title>
        <v-card-text v-if='getMetaData.length'>
          <div class='info-row' v-for='item in getMetaData'>
                <span class='info-row__label'>
                  {{ item.key }}
                </span>
            <span class='info-row__value'>
                  {{ item.value }}
                </span>
          </div>
        </v-card-text>
        <v-card-actions v-if='canCloseTask'>
          <v-btn color='orange' v-if="closeTaskStatus === 'open'" @click="closeTaskStatus = 'question'">
            <v-icon color='white'>done</v-icon>
          </v-btn>

          <v-row v-if="closeTaskStatus ==='question'">
            <v-col md='auto' class='d-flex align-center'>
              <span>{{ $t('woman.timeline.complete_task') }}</span>
            </v-col>
            <v-col>
              <v-btn text color='text' @click="closeTaskStatus = 'open'">
                {{ $t('common.yes_no.no') }}
              </v-btn>
              <v-btn text color='orange' @click='handleCloseTask'>{{ $t('common.yes_no.yes') }}</v-btn>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
      <polling :poll-func='getTask'/>
    </template>
  </v-timeline-item>
</template>

<script>
import {BaseLog, MediaLogType, OpvolgingLogType, RegistratieLogType, UitschrijvenLogType, VrouwLogType} from '@/modules/vrouw/domains/logs';
import {completeTaskById, getTaskInfoByTaskNameAndBusinessKey} from '@/modules/camunda/api';
import {Vrouw} from '@/modules/vrouw/domains/vrouw';
import Polling from '@/modules/core/components/Polling';
import {downloadByGet} from '@/modules/core/services/downloadFile';
import {resendDiabetesteamEmail, resendEmail, resendHuisartsNotificatie} from '@/modules/vrouw/api';

export default {
  name: "TimeLineItem",
  components: {Polling},
  props: {
    item: BaseLog,
    bpmInfo: Object | undefined,
    woman: Vrouw,
    pollingSeconds: Number,
  },
  mounted() {
    this.getTask();
  },
  data() {
    return {
      taskId: null,
      taskName: '',
      closeTaskStatus: 'open',
    };
  },
  computed: {
    getIcon() {
      let output = 'email';

      switch (this.item.logType) {
        case MediaLogType.bellen: {
          output = 'call';
          break;
        }
        case MediaLogType.sms: {
          output = 'sms';
          break;
        }
        case MediaLogType.drukker:
          output = 'approval';
          break;
        case MediaLogType.brief:
          output = 'print';
          break;
        case MediaLogType.unknown:
          output = 'report_problem';
          break;
        case VrouwLogType.bevallen:
          output = 'child_care';
          break;
        case VrouwLogType.registratie:
          output = 'fiber_manual_record';
          break;
        case VrouwLogType.opvolging3m:
          output = 'linear_scale';
          break;
        case VrouwLogType.opvolgingJaarlijks:
          output = 'linear_scale';
          break;
        case VrouwLogType.uitschrijven:
          output = 'unsubscribe';
          break;
        case RegistratieLogType.registratie_ingegeven:
          output = 'fiber_manual_record';
          break;
        case RegistratieLogType.dubbele_registratie_check:
          output = 'checklist';
          break;
        case RegistratieLogType.geregistreerd:
          output = 'how_to_reg';
          break;
        case RegistratieLogType.huisarts_verwittigd:
          output = 'medical_services';
          break;
        case RegistratieLogType.diabetesteam_verwittigd:
          output = 'emergency';
          break;
        case OpvolgingLogType.feedback_ontvangen:
          output = 'feedback';
          break;
        case UitschrijvenLogType.uitschrijven_detail:
          output = 'unsubscribe';
          break;
      }

      if (this.hasTask || this.bpmInfo?.tasks?.length) {
        output = 'priority_high';
      }

      return output;
    },
    getColor() {
      if (this.hasTask || Boolean(this.bpmInfo)) {
        return '#F2994A';
      }
      return 'primary';
    },
    getTitle() {
      if (this.item.logType === VrouwLogType.opvolgingJaarlijks) {
        return this.$t(`woman.timeline.type.${this.item.opvolgingType}`);
      }
      if (this.item.communicatie_type && this.item.communicatie_type === "onbereikbaar") {
        return this.$t(`woman.timeline.communicatie_type.${this.item.communicatie_type}`) + ' ' + this.$t(`woman.timeline.onbereikbaar_type.${this.item.detail_type}`) + ': ' + this.$t(`woman.timeline.type.${this.item.logType}`);
      } else if (this.item.communicatie_type) {
        return this.$t(`woman.timeline.communicatie_type.${this.item.communicatie_type}`) + ': ' + this.$t(`woman.timeline.type.${this.item.logType}`);
      } else {
        return this.$t(`woman.timeline.type.${this.item.logType}`);
      }
    },
    getMetaData() {
      const output = Object.keys(this.item.details)
          .map(key => {
            return ({
              key: this.$te(`woman.timeline.details.keys.${key}`)? this.$t(`woman.timeline.details.keys.${key}`): key, // if no key found, show the actual key
              value: this.$te(`woman.timeline.details.values.${this.item.details[key]}`)? this.$t(`woman.timeline.details.values.${this.item.details[key]}`): this.item.details[key],
            })
          });

      if (this.taskName) {
        output.push({key: this.$t(`woman.timeline.task_name`), value: this.taskName});
      }

      if (this.item.legacy) {
        output.push({
          key: 'legacy',
        });
      }


      return output;
    },
    canRedoEmail() {
      const canResendType = [
        MediaLogType.email,
        MediaLogType.brief,
        MediaLogType.drukker,
        MediaLogType.geen_media_gevonden,
      ].includes(this.item.logType);
      return canResendType;
    },
    canRedoEmailDiabetesTeam() {
      return this.item.logType === RegistratieLogType.diabetesteam_verwittigd;
    },
    canRedoHuisartsNotificatie() {
      return this.item.logType === RegistratieLogType.huisarts_verwittigd && this.item.details["ehealth_geslaagd"] === false;
    },
    canRedoPost() {
      const canResendType = [
        MediaLogType.email,
        MediaLogType.brief,
        MediaLogType.drukker,
        MediaLogType.geen_media_gevonden,
      ].includes(this.item.logType);
      return canResendType;
    },
    canClick() {
      let clickableItems = [
        VrouwLogType.registratie,
        VrouwLogType.opvolging3m,
        VrouwLogType.opvolgingJaarlijks,
        VrouwLogType.uitschrijven
      ];
      return clickableItems.includes(this.item.logType) ||
          (this.item.logType === RegistratieLogType.dubbele_registratie_check && this.taskId);;
    },
    canCloseTask() {
      return this.taskId && !this.canClick;
    },
    hasTask() {
      return Boolean(this.taskId);
    },
  },
  methods: {
    handleRedoEmail: async function (event) {
      event.stopPropagation();
      try {
        await resendEmail(this.woman, this.item);
        this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.timeline.resend.resend_email.success');
        this.$message.show = true;
      } catch (e) {
      }
    },
    handleRedoDiabetesEmail: async function (event) {
      event.stopPropagation();
      try {
        await resendDiabetesteamEmail(this.woman);
        this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.timeline.resend.resend_email.success');
        this.$message.show = true;
      } catch (e) {
      }
    },
    handleRedoHuisartsNotificatie: async function (event) {
      event.stopPropagation();
      try {
        await resendHuisartsNotificatie(this.woman);
        this.$message.color = '#4caf50';
        this.$message.text = this.$t('woman.timeline.resend.resend_ehealth.success');
        this.$message.show = true;
      } catch (e) {
      }
    },
    handleRedoPost: async function (event) {
        event.stopPropagation();

        let params = `communicatieType=${this.item.communicatie_type}&taal=${this.woman.taal}`;
        if (this.item.detail_type) {
            params += `&detailType=${this.item.detail_type}`;
        }
        await downloadByGet(`/api/brief/${this.woman.vrouwId}?${params}`);
    },
    handleClick: function (event) {
      if (!this.canClick) {
        return;
      }
      this.$emit('clickItem', this.item, this.taskId, event);
    },
    handleCloseTask: async function (event) {
      event.stopPropagation();
      await completeTaskById(this.taskId);
      this.taskId = null;
      this.taskName = '';
      this.closeTaskStatus = 'open';
    },
    async getTask() {
      if (!this.item.taskId || !this.item.checkTask || !this.item.processInstanceId) {
        return;
      }
      const response = await getTaskInfoByTaskNameAndBusinessKey(this.item.taskId, this.woman.vrouwId, this.item.processInstanceId);


      this.taskId = response?.id ?? null;
      this.taskName = response?.name ?? '';
    },
  },
};
</script>

<style scoped lang='scss'>

.cursor-pointer {
  cursor: pointer;
}

.info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 12px;
  margin-bottom: 8px;

  &__label {
    color: var(--v-text_light-base)
  }

  &__value {
    flex: 1;
    text-align: right;
  }

}

.redo-text {
  cursor: pointer;

  &:hover {
    color: #F2994A;
  }
}

::v-deep .v-timeline-item__body {
  max-width: unset !important;
}

::v-deep .v-timeline-item__opposite {
  max-width: 120px !important;
  width: 120px !important;
  min-width: 120px !important;
  flex: unset !important;
}
</style>
