<template>
  <v-row>
    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-icon class='material-icons-outlined'>alternate_email</v-icon>
          <span class='mx-3'>{{ $t('woman.details.contact.header') }}</span>
          <v-icon v-if='!readonly' color='primary' @click.stop="handleChangeEditMode('contact')">edit</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref='contact'>
            <div :class="getRowClass(forms.contact,'adres')">
                    <span class='info-row__label'>
                      {{ $t(forms.contact.adres.label) }}
                    </span>
              <v-text-field :readonly="editModeValue !== 'contact'"
                            v-model='forms.contact.adres.value'
                            :rules='forms.contact.adres.rules'/>
            </div>

            <div :class="getRowClass(forms.contact,'gemeenteId')">
              <span class='info-row__label'>
                {{ $t(forms.contact.gemeenteId.label) }}
              </span>
              <default-select type='city'
                              :readonly="editModeValue !== 'contact'"
                              v-model='forms.contact.gemeenteId.value'
                              :rules='forms.contact.gemeenteId.rules'
                              label=''
                              clearable
                              is-not-outlined
              />
            </div>
            <div :class="getRowClass(forms.contact,'telefoon')">
              <span class='info-row__label'>
                {{ $t(forms.contact.telefoon.label) }}
              </span>
              <phone-field :readonly="editModeValue !== 'contact'"
                           v-model='forms.contact.telefoon.value'
                           label=''
                           is-not-outlined
              />
            </div>
            <div :class="getRowClass(forms.contact,'email')">
              <span class='info-row__label'>
                {{ $t(forms.contact.email.label) }}
              </span>
              <v-text-field :readonly="editModeValue !== 'contact'"
                            v-model='forms.contact.email.value'
                            :rules='forms.contact.email.rules'
              />

            </div>


            <div :class="getRowClass(forms.contact, 'bereikbaarPost')">
              <span class='info-row__label'>
                {{ $t(forms.contact.bereikbaarPost.label) }}
              </span>
              <v-checkbox v-model='forms.contact.bereikbaarPost.value'
                          :readonly="editModeValue !== 'contact'"
                          hide-details
              />

            </div>
            <div :class="getRowClass(forms.contact, 'bereikbaarEmail')">
              <span class='info-row__label'>
                {{ $t(forms.contact.bereikbaarEmail.label) }}
              </span>
              <v-checkbox v-model='forms.contact.bereikbaarEmail.value'
                          :readonly="editModeValue !== 'contact'"
                          hide-details
              />

            </div>
            <div :class="getRowClass(forms.contact, 'bereikbaarSms')">
              <span class='info-row__label'>
                {{ $t(forms.contact.bereikbaarSms.label) }}
              </span>
              <v-checkbox v-model='forms.contact.bereikbaarSms.value'
                          :readonly="editModeValue !== 'contact'"
                          hide-details
              />

            </div>
            <div :class="getRowClass(forms.contact, 'bereikbaarBellen')">
              <span class='info-row__label'>
                {{ $t(forms.contact.bereikbaarBellen.label) }}
              </span>
              <v-checkbox v-model='forms.contact.bereikbaarBellen.value'
                          :readonly="editModeValue !== 'contact'"
                          hide-details
              />

            </div>
            <div class='info-row actions' v-if="editModeValue === 'contact'">
              <v-btn text @click='handleCancelUpdate(forms.contact)'>
                {{ $t('woman.details.actions.cancel') }}
              </v-btn>
              <v-btn class='primary' @click='handleUpdate($refs.contact)'>{{
                  $t('woman.details.actions.submit')
                }}
              </v-btn>
            </div>
          </v-form>

        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-icon class='material-icons-outlined'>person</v-icon>
          <span class='mx-3'>{{ $t('woman.details.personal.header') }}</span>
          <v-icon v-if='!readonly' color='primary' @click.stop="handleChangeEditMode('personal')">edit</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref='personal'>
            <div :class="getRowClass(forms.personal,'naam')">
              <span class='info-row__label'>
                {{ $t(forms.personal.naam.label) }}
              </span>
              <v-text-field :readonly="editModeValue !== 'personal'"
                            v-model='forms.personal.naam.value'
                            :rules='forms.personal.naam.rules'
              />
            </div>
            <div :class="getRowClass(forms.personal,'voornaam')">
              <span class='info-row__label'>
                {{ $t(forms.personal.voornaam.label) }}
              </span>
              <v-text-field :readonly="editModeValue !== 'personal'"
                            :rules='forms.personal.voornaam.rules'
                            v-model='forms.personal.voornaam.value'
              />
            </div>
            <div :class="getRowClass(forms.personal,'geboortedatum')">
              <span class='info-row__label'>
                {{ $t(forms.personal.geboortedatum.label) }}
              </span>
              <date-field :readonly="editModeValue !== 'personal'"
                          :rules='forms.personal.geboortedatum.rules'
                          :date.sync='forms.personal.geboortedatum.value'
                          hide-borders
                          label=''/>
            </div>
            <div :class="getRowClass(forms.personal,'taal')">
              <span class='info-row__label'>
                {{ $t(forms.personal.taal.label) }}
              </span>
              <v-select :readonly="editModeValue !== 'personal'"
                        :rules='forms.personal.taal.rules'
                        :items='forms.personal.taal.selectItems'
                        v-model='forms.personal.taal.value'
              />
            </div>
            <div :class="getRowClass(forms.personal,'registratieKanaal')">
              <span class='info-row__label'>
                {{ $t(forms.personal.registratieKanaal.label) }}
              </span>
              <v-select readonly
                        :rules='forms.personal.registratieKanaal.rules'
                        :items='forms.personal.registratieKanaal.selectItems'
                        v-model='forms.personal.registratieKanaal.value'
              />
            </div>
            <div :class="getRowClass(forms.personal,'jaarlijkseOpvolgingDatum')">
              <span class='info-row__label'>
                {{ $t(forms.personal.jaarlijkseOpvolgingDatum.label) }}
              </span>
              <v-select :readonly="editModeValue !== 'personal'"
                        :rules='forms.personal.jaarlijkseOpvolgingDatum.rules'
                        :items='forms.personal.jaarlijkseOpvolgingDatum.selectItems'
                        v-model='forms.personal.jaarlijkseOpvolgingDatum.value'
              />
            </div>
            <div :class="getRowClass(forms.personal,'voorkeurEmail')">
              <span class='info-row__label'>
                {{ $t(forms.personal.voorkeurEmail.label) }}
              </span>
              <v-select :readonly="editModeValue !== 'personal'"
                        :rules='forms.personal.voorkeurEmail.rules'
                        :items='forms.personal.voorkeurEmail.selectItems'
                        v-model='forms.personal.voorkeurEmail.value'
              />
            </div>
            <div class='info-row actions' v-if="editModeValue === 'personal'">
              <v-btn text @click='handleCancelUpdate(forms.personal)'>{{ $t('woman.details.actions.cancel') }}</v-btn>
              <v-btn class='primary' @click='handleUpdate($refs.personal)'>{{
                  $t('woman.details.actions.submit')
                }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-icon class='material-icons-outlined'>chat</v-icon>
          <span class='mx-3'>{{ $t('woman.details.comment.header') }}</span>
          <v-icon v-if='!readonly' color='primary' @click.stop="handleChangeEditMode('comment')">edit</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref='comment'>
            <div :class="getRowClass(forms.comment,'comment', 'row-comment one-col')">
              <v-textarea :readonly="editModeValue !== 'comment'"
                          v-model='forms.comment.comment.value'
                          clearable
                          auto-grow
                          rows='1'
              />
            </div>
            <div class='info-row actions' v-if="editModeValue === 'comment'">
              <v-btn text @click='handleCancelUpdate(forms.comment)'>{{ $t('woman.details.actions.cancel') }}</v-btn>
              <v-btn class='primary' @click='handleUpdate($refs.comment)'>{{
                  $t('woman.details.actions.submit')
                }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-icon class='material-icons-outlined'>medical_services</v-icon>
          <span class='mx-3'>{{ $t('woman.details.doctor.header') }}</span>
          <v-icon v-if='!readonly' color='primary' @click.stop="handleChangeEditMode('medical')">edit</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref='medical'>
            <div :class="getRowClass(forms.doctor,'huisartsId', 'one-col')"
                 v-if="forms.doctor.huisartsId.value || editModeValue === 'medical' ">
              <default-select type='doctor'
                              :readonly="editModeValue !== 'medical'"
                              v-model='forms.doctor.huisartsId.value'
                              :rules='forms.doctor.huisartsId.rules'
                              label=''
                              is-not-outlined
                              clearable/>
            </div>

            <v-alert v-if='woman.hasNoHuisartsAndExtraDoctorData' type="warning" text dense border="left" elevation="2" class="mt-4">
              <div >{{ $t('woman.details.doctor.extra.available') }}</div>
              <div class='info-row'>
              <span class='info-row__label'>
                {{ $t('woman.details.doctor.name') }}
              </span>
                <span>{{ woman.extraDokterData.naam }}</span>
              </div>

              <div class='info-row'>
              <span class='info-row__label'>
                {{ $t('woman.details.doctor.city') }}
              </span>
                <default-select readonly
                                type='city'
                                :value='woman.extraDokterData.gemeenteId'
                                label=''/>
              </div>
            </v-alert>


            <div class='info-row actions' v-if="editModeValue === 'medical'">
              <v-btn text @click='handleCancelUpdate(forms.doctor)'>{{ $t('woman.details.actions.cancel') }}</v-btn>
              <v-btn class='primary' @click='handleUpdate($refs.medical)'>{{
                  $t('woman.details.actions.submit')
                }}
              </v-btn>
            </div>
          </v-form>

        </v-card-text>

      </v-card>
    </v-col>

    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-icon class='material-icons-outlined'>emergency</v-icon>
          <span class='mx-3'>{{ $t('woman.details.diabeticTeam.header') }}</span>
          <v-icon v-if='!readonly' color='primary' @click.stop="handleChangeEditMode('diabetic')">edit</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref='diabetic'>
            <div :class="getRowClass(forms.diabeticTeam,'diabetesteamId', 'one-col')">
              <default-select type='diabeticTeam'
                              v-model='forms.diabeticTeam.diabetesteamId.value'
                              :readonly="editModeValue !== 'diabetic'"
                              :rules='forms.diabeticTeam.diabetesteamId.rules'
                              label=''
                              is-not-outlined
                              clearable/>
            </div>

            <div class='info-row actions' v-if="editModeValue === 'diabetic'">
              <v-btn text @click='handleCancelUpdate(forms.diabeticTeam)'>{{
                  $t('woman.details.actions.cancel')
                }}
              </v-btn>
              <v-btn class='primary' @click='handleUpdate($refs.diabetic)'>{{
                  $t('woman.details.actions.submit')
                }}
              </v-btn>
            </div>
          </v-form>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import {Vrouw} from '@/modules/vrouw/domains/vrouw';
import {ContactForm} from '@/modules/vrouw/forms/details/contactForm';
import {PersonalForm} from '@/modules/vrouw/forms/details/personalForm';
import {CommentForm} from '@/modules/vrouw/forms/details/commentForm';
import {DoctorForm} from '@/modules/vrouw/forms/details/doctorForm';
import {DiabeticForm} from '@/modules/vrouw/forms/details/diabeticForm';
import {getDoctor} from '@/modules/huisarts/api';
import {getDiabeticTeam} from '@/modules/diabetesteam/api';
import {updateVrouw} from '@/modules/vrouw/api';
import DefaultSelect from '@/modules/core/components/selects/defaultSelect';
import DateField from '@/modules/core/components/forms/DateField';
import PhoneField from '@/modules/core/components/forms/PhoneField.vue';


export default {
  name: 'WomanDetails',
  components: {PhoneField, DateField, DefaultSelect},
  props: {
    woman: Vrouw,
    compareWoman: {
      type: Vrouw,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      doctor: {
        name: '',
        city: '',
      },
      diabeticTeam: {
        name: '',
        hospital: '',
      },
      editModeValue: null,
      forms: {
        contact: new ContactForm(this.$i18n),
        personal: new PersonalForm(this.$i18n),
        comment: new CommentForm(this.$i18n),
        doctor: new DoctorForm(this.$i18n),
        diabeticTeam: new DiabeticForm(this.$i18n),
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.forms.doctor = new DoctorForm(this.$i18n);
      this.forms.diabeticTeam = new DiabeticForm(this.$i18n);
      if (this.woman.huisartsId) {
        this.doctor = await getDoctor(this.woman.huisartsId);
        if (this.doctor) {
          this.forms.doctor = new DoctorForm(this.$i18n, this.doctor);
        }
      }
      if (this.woman.diabetesteamId) {
        this.diabeticTeam = await getDiabeticTeam(this.woman.diabetesteamId);
        if (this.diabeticTeam) {
          this.forms.diabeticTeam = new DiabeticForm(this.$i18n, this.diabeticTeam);
        }
      }

      this.forms.contact = new ContactForm(this.$i18n, this.woman);
      this.forms.personal = new PersonalForm(this.$i18n, this.woman);
      this.forms.comment = new CommentForm(this.$i18n, this.woman);

    },
    handleChangeEditMode: function (value) {

      if (this.editModeValue === value) {
        return;
      }

      this.editModeValue = value;

      this.forms.contact.resetData();
      this.forms.personal.resetData();
      this.forms.comment.resetData();
      this.forms.doctor.resetData();
      this.forms.diabeticTeam.resetData();
    },
    handleCancelUpdate: function (form) {
      this.editModeValue = null;
      form.resetData();
    },
    handleUpdate: async function (form) {

      if (form && form.hasOwnProperty('validate') && !form.validate()) {
        return;
      }


      this.editModeValue = null;

      const formData = [
        this.forms.contact,
        this.forms.personal,
        this.forms.comment,
        this.forms.doctor,
        this.forms.diabeticTeam,
      ];


      await updateVrouw(this.woman.vrouwId, formData);
      this.$emit('update-completed');
    },
    getRowClass(form, key, extra = '') {
      let output = `info-row ${extra}`;
      if (this.compareWoman && !form.isSame(key, this.compareWoman[key])) {
        output += ' compare';
      }

      return output;
    },
  },
  watch: {
    woman() {
      this.fetchData();
    },
    editModeValue() {
      if (!this.editModeValue) {
        return;
      }

      const form = this.forms[this.editModeValue];

      if (!form || !form.changeEditData) {
        return;
      }

      form.changeEditData();

    },
  },
};
</script>

<style scoped lang='scss'>
.extrahuisartsdata {
}

.v-expansion-panel-header > .v-icon {
  margin-right: 8px;
  flex: unset;
}

.v-expansion-panel-header > span {
  flex: unset;
  margin-right: 16px;
}

.info-row {
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 12px;
  align-items: center;
  min-height: 32px;

  &__label {
    color: var(--v-text_light-base);
    word-break: break-word;
  }

  &__value {
    //text-align: right;
  }

  &.actions {
    justify-content: flex-end;
    margin-top: 24px;
    grid-template-columns: auto auto;
  }

  &.one-col {
    grid-template-columns: 1fr;
  }

  &.compare {
    color: var(--v-secondary-base);

    ::v-deep input,
    ::v-deep .default-select-readonly,
    ::v-deep .v-select__selection--comma {
      color: var(--v-secondary-base);
    }

  }

  ::v-deep .v-input {

    padding: 0;
    margin: 0;
    flex: 2;

    .v-input__slot {
      padding: 0 !important;
      margin: 0 !important;

    }

    .v-text-field__details {
      min-height: unset;

      .v-messages {
        min-height: unset;
      }

      .v-messages__message {
        padding: 6px 0;
      }
    }

    input {
      padding: 0;
    }

    &--is-readonly {
      .v-input__slot {
        min-height: unset;

        &:before, &:after {
          content: unset;
        }
      }

      .v-select__selections {
        input {
          display: none;
        }
      }

      .v-select__selection--comma {
        margin: 0;
      }

      .v-input__append-inner {
        display: none;
      }

      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }


    }
  }

  ::v-deep textarea {
    max-height: 400px;
    overflow: auto;
  }

}
</style>
