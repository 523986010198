<template>
  <v-container v-if="woman" fluid>
    <HeaderContainer :back-text="$t('woman.followUp.overview.header.back').toString()"
                     :label-text="$t(`woman.timeline.type.${type}`).toString()"
                     :woman="woman"
                     :prev-route-should-be="prevRoute">
      <v-btn v-if="Boolean(processInstanceId)"
             :href="`/camunda/app/cockpit/default/#/process-instance/${processInstanceId}/runtime`">
          {{ $t('common.tobpm') }}&nbsp;
        <v-icon>
          launch
        </v-icon>
      </v-btn>
    </HeaderContainer>
    <v-row>
      <v-col md="5">
        <m3-form v-if="type === FollowUpType.M3" :woman-id="woman.vrouwId"/>
        <jm-form v-else :woman-id="woman.vrouwId" :type="type"/>
      </v-col>

      <time-line-container>
        <time-line>
          <time-line-item v-for="item in logs.slice().reverse()"
                          :item="item"
                          :key="item.id"
                          :woman="woman"
                          :polling-seconds="pollingSeconds"
                          />
        </time-line>
      </time-line-container>
    </v-row>
    <polling :poll-func="startPolling" :seconds="pollingSeconds"/>
  </v-container>
</template>

<script>
import HeaderContainer from "@/modules/vrouw/components/headerContainer";
import M3Form from "@/modules/vrouw/forms/followUp/3M";
import JmForm from "@/modules/vrouw/forms/followUp/JM";
import TimeLine from "@/modules/vrouw/components/TimeLine";
import TimeLineItem from "@/modules/vrouw/components/TimeLineItem";
import {getVrouwDoorId, getVrouwOpvolgingLogs} from "@/modules/vrouw/api";
import {getOpvolgingType, OpvolgingType} from "@/modules/vrouw/domains/opvolging";
import TimeLineContainer from "@/modules/vrouw/components/TimeLineContainer";
import {ROUTES} from "@/modules/core/router/routes";
import Polling from "@/modules/core/components/Polling";
import {findPIDByProcessDefinitionKeyAndVariables} from "@/modules/camunda/api";
import {getEnv} from "@/modules/core/utils";

export default {
  name: "FollowUp",
  components: { Polling, TimeLineContainer, HeaderContainer, M3Form, JmForm, TimeLine, TimeLineItem},
  data() {
    return {
      woman: null,
      processInstanceId: '',
      logs: [],
      type: null,
      FollowUpType: OpvolgingType,
      prevRoute: {name: ROUTES.WOMAN_DETAILS.name, params: {id: this.$route.params.womanId}}
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    pollingSeconds() {
      try {
        const pollingMilliseconds = Number(getEnv("VUE_APP_POLLING_OPVOLGING", 5000))
        return pollingMilliseconds / 1000;
      } catch (e) {
        return 5;
      }
    }
  },
  methods: {
    init: async function () {
      this.type = getOpvolgingType(this.$route.params.type)

      if (!this.type) {
        return
      }

      this.woman = await getVrouwDoorId(this.$route.params.womanId);
    },
    startPolling: async function () {
      if (!this.woman || !this.type) {
        return
      }
      this.logs = await getVrouwOpvolgingLogs(this.woman.vrouwId, this.type);
			this.processInstanceId = await findPIDByProcessDefinitionKeyAndVariables(this.woman.vrouwId, 'OpvolgingProcess', [
				{key: 'opvolging_type', value: this.type},
			]);
    }
  }
}
</script>

<style scoped>

</style>
