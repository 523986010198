var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3 mt-6"},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$t('correspondentieBeheer.sms.title')))])]),_c('v-col',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","small":""},on:{"click":_vm.handleNewSMSTemplate}},[_c('v-icon',{staticClass:"material-icons-outlined"},[_vm._v("add")]),_c('span',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.$t('correspondentieBeheer.sms.new')))])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":100,"footer-props":{'items-per-page-options': [100, 50, 25, 10]}},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("correspondentieBeheer.sms.types." + (item.type)))))]),(item.hasDetailType)?_c('span',[_vm._v("  "+_vm._s(_vm.$t(("correspondentieBeheer.sms.detailTypes.opvolging." + (item.detailType))))+" ")]):_vm._e()]}},{key:"item.laatstGewijzigdOp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.laatstGewijzigdOp ? item.laatstGewijzigdOp.format('DD/MM/YYYY') : ''))])]}},{key:"item.taal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("common.languages." + (item.taal))))+" ")]}},{key:"item.acties",fn:function(ref){
var item = ref.item;
return [(item.linkNaarDownload)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.linkNaarDownload}},[_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"color":"primary"}},[_vm._v("file_download")])],1):_vm._e(),_c('v-icon',{staticClass:"ml-8",attrs:{"color":"error"},on:{"click":function (event) { return _vm.handleDelete(event,item); }}},[_vm._v("delete")])]}}])}),(_vm.cuTemplateDialog)?_c('cu-s-m-s-template',{attrs:{"sms-template":_vm.activeCuTemplate},on:{"submit":_vm.handleCuSubmit,"cancel":_vm.handleCuCancel}}):_vm._e(),_c('confirm-delete-s-m-s-template',{attrs:{"active-template":_vm.activeDeleteItem},on:{"submit":_vm.handleSubmitDelete},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }