import {AxiansForm, FormItem, formRules, SelectItem,} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import {Vrouw} from '@/modules/vrouw/domains/vrouw';
import moment from 'moment';

export class CreateUpdateForm implements AxiansForm {
	voornaam: FormItem;
	naam: FormItem;
	email: FormItem;
	geboortedatum: FormItem;
	adres: FormItem;
	gemeenteId: FormItem;
	telefoon: FormItem;
	IDNumber: FormItem;
	taal: FormItem;
	vermoedelijkeBevallingsDatum: FormItem;
	heightBefore: FormItem;
	weightBefore: FormItem;
	huisartsId: FormItem;
	diabetesteamId: FormItem;
	comment: FormItem;

	constructor(translator: VueI18n, initialData?: Vrouw) {
		this.voornaam = new FormItem(
			'firstName',
			'woman.cu.form.firstName',
			initialData?.voornaam ?? '',
			formRules.maxChars(translator, 100)
		);

		this.naam = new FormItem(
			'lastName',
			'woman.cu.form.lastName',
			initialData?.naam ?? '',
			formRules.maxChars(translator, 100)
		);

		this.email = new FormItem(
			'email',
			'woman.cu.form.email',
			initialData?.email ?? '',
			formRules.isEmail(translator, 'form.email')
		);

		this.geboortedatum = new FormItem(
			'birthday',
			'woman.cu.form.birthday',
			initialData?.geboortedatum?.format('YYYY-MM-DD') ?? '',
			formRules.geboorteDatumValidatie(translator)
		);

		this.adres = new FormItem(
			'streetName',
			'woman.cu.form.streetName',
			initialData?.adres ?? '',
			formRules.required(translator, 'woman.cu.required').concat(formRules.maxChars(translator, 100))
		);

		this.gemeenteId = new FormItem(
			'city',
			'woman.cu.form.city',
			initialData?.gemeenteId ?? '',
			formRules.required(translator, 'woman.cu.required')
		);

		this.telefoon = new FormItem('phone', 'woman.cu.form.phone', initialData?.telefoon ?? '');

		this.IDNumber = new FormItem('idNumber', 'woman.cu.form.idNumber', '');

		this.taal = new FormItem(
			'languagePreference',
			'woman.cu.form.languagePreference',
			initialData?.taal ?? '',
			formRules.required(translator, 'woman.cu.required'),
			-1,
			[
				new SelectItem(translator.t('common.languages.nl').toString(), 'nl'),
				new SelectItem(translator.t('common.languages.fr').toString(), 'fr'),
				new SelectItem(translator.t('common.languages.en').toString(), 'en'),
			]
		);

		this.vermoedelijkeBevallingsDatum = new FormItem(
			'deliveryDate',
			'woman.cu.form.deliveryDate',
			'',
			formRules.vermoedelijkeBevallingsDatumValidatie(translator)
		);

		this.heightBefore = new FormItem(
			'heightBefore',
			'woman.cu.form.heightBefore',
			'',
			formRules.numberBetween(translator, 0, 3)
		);

		this.weightBefore = new FormItem(
			'weightBefore',
			'woman.cu.form.weightBefore',
			'',
			formRules.numberBetween(translator, 0, 500)
		);

		this.huisartsId = new FormItem('doctor', 'woman.cu.form.doctor', initialData?.huisartsId ?? '');

		this.diabetesteamId = new FormItem(
			'diabeticTeam',
			'woman.cu.form.diabeticTeam',
			initialData?.diabetesteamId ?? ''
		);

		this.comment = new FormItem('comment', 'woman.cu.form.comment', initialData?.comment ?? '');
	}

	toJSON(): { [p: string]: any } {
		return {
			firstName: this.voornaam.value,
			lastName: this.naam.value,
			email: Boolean(this.email.value) ? this.email.value : null,
			geboortedatum: Boolean(this.geboortedatum.value)
				? moment(this.geboortedatum.value).format('DD/MM/YYYY')
				: null,
			streetNameAndHouseNumber: this.adres.value,
			city: this.gemeenteId.value,
			telefoon: this.telefoon.value,
			idNumber: this.IDNumber.value,
			language: this.taal.value,
			deliveryDate: moment(this.vermoedelijkeBevallingsDatum.value).format('DD/MM/YYYY'),
			heightBefore: this.heightBefore.value,
			weightBefore: this.weightBefore.value,
			doctor: this.huisartsId.value,
			diabeticTeam: this.diabetesteamId.value,
			comment: this.comment.value,
		};
	}
}
