import VueI18n from "vue-i18n";
import {AxiansForm, FormItem, formRules} from "@/modules/core/components/forms/forms.model";
import {Diabetesteam} from "@/modules/diabetesteam/domains";

export class CuDiabetesForm implements AxiansForm {

    naam: FormItem;
    email: FormItem;
    actief:FormItem;

    initialData?: Diabetesteam

    constructor(translator: VueI18n, initialData?: Diabetesteam) {
        this.initialData = initialData;


        this.naam = new FormItem(
            "naam",
            "diabeticTeams.cu.naam",
            initialData?.naam ?? '',
            formRules.required(translator, 'form.required')
        )

        this.email = new FormItem(
            "email",
            "diabeticTeams.cu.email",
            initialData?.email ?? '',
            formRules.isEmailAndRequired(translator, 'form.email')
        )

        this.actief = new FormItem(
            "actief",
            "diabeticTeams.cu.actief",
            initialData?.actief ?? true,
        )


    }


    toJSON(): { [p: string]: any } {
        return {
            naam: this.naam.value,
            email: this.email.value,
            actief: this.actief.value,
        };
    }

    resetData(): void {
        this.naam.value = this.initialData?.naam;
        this.email.value = this.initialData?.email;
        this.actief.value = this.initialData?.actief;
    }
}