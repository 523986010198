<template>
  <v-card class="pa-6">
    <v-form ref="form">
      <v-row>
        <v-col>

          <p>{{ $t(form.reden.label) }}</p>
          <p>{{ form.reden.value }}</p>

        </v-col>
      </v-row>

    </v-form>
  </v-card>
</template>

<script>
import DateField from "@/modules/core/components/forms/DateField";
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {UitschrijvenForm} from "@/modules/vrouw/forms/uitschrijven/UitschrijvenForm";

export default {
  name: "UitschrijvenForm",
  components: {DateField},
  props: {
    woman: Vrouw,
  },
  data() {
    return {
      formData: null,
      form: new UitschrijvenForm(this.$i18n)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: function () {
      this.form = new UitschrijvenForm(this.$i18n, this.woman)
    },
  }
}
</script>

<style scoped>
</style>