<template>

	<v-dialog max-width='1250' v-model='dialogOpen'>

		<template v-slot:activator='{on,  attrs}'>
			<v-btn color='primary'
						 outlined
						 small
						 v-bind='attrs'
						 v-on='on'>
				<v-icon class='material-icons-outlined'>download</v-icon>
				<span class='pa-2'>{{ $t('woman.header.export') }}</span>
			</v-btn>
		</template>

		<template v-slot:default='dialog'>
			<v-card>
				<v-card-title>
					<v-row class='mb-8'>
						<v-col md='3' class='mr-8' />
						<v-col>
							<h4>{{ $t('woman.header.export') }}</h4>
						</v-col>

						<v-col md='1' class='d-flex justify-end'>
							<v-icon @click="handleCancel">close</v-icon>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text>
					<v-form ref='form'>
						<v-row>
							<v-col>
								<date-field
									:label='$t("woman.export.begin")'
									:date.sync='beginDate'
									:rules='beginDateRules'
								/>
							</v-col>

							<v-col>
								<date-field
									:label='$t("woman.export.eind")'
									:date.sync='eindDate'
									:rules='eindDateRules'
								/>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class='pb-8'>
					<v-spacer />
					<v-btn @click='handleCancel'>
						{{ $t('woman.export.actions.cancel') }}
					</v-btn>
					<v-btn color='primary' @click='handleSubmit'>
						{{ $t('woman.export.actions.submit') }}
					</v-btn>
				</v-card-actions>


			</v-card>
		</template>

	</v-dialog>


</template>

<script>

import DateField from '@/modules/core/components/forms/DateField.vue';
import {formRules} from '@/modules/core/components/forms/forms.model';
import moment from 'moment';
import {downloadByGet} from '@/modules/core/services/downloadFile';

export default {
	name: 'ExportDossiers',
	components: { DateField },
	data() {
		return {
			dialogOpen: false,
			beginDate: '',
			eindDate: '',
		};
	},
	computed: {
		beginDateRules() {
			return formRules.datumIsRequired(this.$i18n);
		},
		eindDateRules() {
			const isEindAfterBegin = [
				() => moment(this.eindDate).isSameOrAfter(moment(this.beginDate)) ? true : this.$t('form.date'),
			];
			return formRules.datumIsRequired(this.$i18n).concat(isEindAfterBegin);
		},
	},
	methods: {
		handleCancel() {
			this.$refs.form.reset();
			this.dialogOpen = false;

			this.beginDate = '';
			this.eindDate = '';
		},
		async handleSubmit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			await downloadByGet(`/api/rapportering?van=${moment(this.beginDate).format('YYYY-MM-DD')}&tot=${moment(this.eindDate).format('YYYY-MM-DD')}`);

			this.handleCancel();
		},
	},
};
</script>

<style scoped>

</style>
