import {getQueryString, httpService, httpSilentService, PaginationType,} from '@/modules/core/services/http-service';
import {DiabeticOptions, M3FormType, M3Result} from '@/modules/vrouw/forms/followUp/3mForm';
import {JMFormType, JMResult} from '@/modules/vrouw/forms/followUp/jmForm';
import {DetailsForm} from '@/modules/vrouw/forms/details/detailsForm';
import {HuisArts} from '@/modules/huisarts/domains';
import {Diabetesteam} from '@/modules/diabetesteam/domains';
import {Vrouw} from '@/modules/vrouw/domains/vrouw';
import {BaseLog, OpvolgingLog, RegistratieLog, UitschrijvenLog, VrouwLog,} from '@/modules/vrouw/domains/logs';
import {JaarlijkseOpvolging, OpvolgingType} from '@/modules/vrouw/domains/opvolging';
import {RegForm} from '@/modules/vrouw/forms/registration/RegForm';
import {completeTaskById} from '@/modules/camunda/api';

const mapKeysWithSortForAllWoman = {
	voornaam: 'voornaam',
	naam: 'naam',
	email: 'contact.email',
	geboortedatum: 'geboortedatum',
	registratieDatum: 'registratieDatum',
	telefoon: 'telefoon',
};

export async function getAlleVrouwen(
	searchQuery: string = '',
	options: any
): Promise<PaginationType<Vrouw>> {
	const response = await httpService.get<PaginationType<Vrouw>>(
		`/api/vrouw?${getQueryString(searchQuery, options, mapKeysWithSortForAllWoman)}`
	);
	return {
		...response,
		content: response.content.map((woman: any) => new Vrouw(woman)),
	};
}

export async function getVrouwDoorId(id: string): Promise<Vrouw> {
	const response = await httpService.get<any>(`/api/vrouw/${id}`);
	return new Vrouw(response);
}

export async function getVrouwDoorCorrespondentieNummer(corrNumber: string): Promise<Vrouw> {
	const response = await httpService.get<any>(
		`/api/vrouw/correspondentienummer?corrNumber=${corrNumber}`
	);
	return new Vrouw(response);
}

export async function postVoorlopigePapierRegistratie(
	formData: any
): Promise<{ id: string; dubbelsGevonden: boolean }> {
	return await httpService.post<any>('/api/vrouw/voorlopige-registratie/papier', formData);
}

export async function updateVrouw(
	id: string,
	forms: DetailsForm<Vrouw | HuisArts | Diabetesteam>[]
): Promise<void> {
	await httpService.put<any>(
		`/api/vrouw/${id}`,
		forms.reduce(
			(output: object, current: DetailsForm<any>) => ({ ...output, ...current.toJSON() }),
			{}
		)
	);
}

export async function getVrouwLogs(id: string): Promise<VrouwLog[]> {
	const response = await httpService.get<any>(`/api/vrouw/${id}/log`);
	return response.map((followUp: any) => new VrouwLog(followUp));
}

export async function getVrouwOpvolgingResultaat(
	womanId: string,
	type: OpvolgingType
): Promise<any> {
	try {
		const responseResult = await httpSilentService.get<any>(
			`/api/vrouw/${womanId}/opvolging/${type}/result`
		);

		if (type === OpvolgingType.M3) {
			return new M3Result(responseResult);
		}

		if (
			[
				OpvolgingType.J1,
				OpvolgingType.J2,
				OpvolgingType.J3,
				OpvolgingType.J4,
				OpvolgingType.J5,
				OpvolgingType.J6,
				OpvolgingType.J7,
				OpvolgingType.J8,
				OpvolgingType.J9,
				OpvolgingType.J10,
			].includes(type)
		) {
			return new JMResult(responseResult);
		}

		throw new Error('No valid type');
	} catch (e) {
		return null;
	}
}

export async function getVrouwOpvolgingLogs(
	womanId: string,
	type: OpvolgingType
): Promise<OpvolgingLog[]> {
	const responseLog = await httpService.get<any>(`/api/vrouw/${womanId}/opvolging/${type}/log`);
	return responseLog.map((log) => new OpvolgingLog(log));
}

export async function updateVrouwDetails3m(womanId: string, data: M3FormType): Promise<void> {
	await httpService.put<any>(`/api/vrouw/${womanId}/opvolging/3m/result`, {
		uiteindelijkeBevallingsDatum: data.deliveryDate,
		naBevallingBloedafname: data.control,
		naBevallingDiabetes: data.diabetic === DiabeticOptions.YES,
	});
}

export async function updateVrouwDetailsJm(
	womanId: string,
	type: string,
	data: JMFormType
): Promise<void> {
	await httpService.put<any>(`/api/vrouw/${womanId}/opvolging/${type}/result`, {
		bloedglucoseWaarde: data.bloedglucoseWaarde,
		bloedglucoseResultaat: data.bloedglucoseResultaat,
		consultatieHuisarts: data.consultatieHuisarts,
	});
}

export async function getVrouwJaarlijkseOpvolgingen(id: string): Promise<JaarlijkseOpvolging[]> {
	const response = await httpService.get<any>(`/api/vrouw/${id}/opvolging/all/result`);
	return response.map((followUp: any) => new JaarlijkseOpvolging(followUp));
}

export async function getVrouwDubbels(id: string): Promise<Vrouw[]> {
	const response = await httpService.get<any>(`/api/vrouw/dubbele-registratie/${id}`);
	return response.map((woman) => new Vrouw(woman));
}

export async function updateGeenDubbels(taskId: string): Promise<void> {
	await completeTaskById(taskId, {
		variables: { dubbel_id: { value: null } },
	});
}

export async function updateDubbel(taskId: string, vrouwId: string): Promise<void> {
	await completeTaskById(taskId, {
		variables: { dubbel_id: { value: vrouwId } },
	});
}

export async function updateVrouwRegistratie(womanId: String, data: RegForm) {
	await httpService.put<any>(`/api/vrouw/${womanId}/registratie`, data.toJSON());
}

export async function getVrouwRegistratieLogs(womanId: string): Promise<RegistratieLog[]> {
	const responseLog = await httpService.get<any>(`/api/vrouw/${womanId}/registratie/log`);
	return responseLog.map((log) => new RegistratieLog(log));
}

export async function getVrouwUitschrijvenLogs(womanId: string): Promise<UitschrijvenLog[]> {
	const responseLog = await httpService.get<any>(`/api/vrouw/${womanId}/uitschrijven/log`);
	return responseLog.map((log) => new UitschrijvenLog(log));
}

export async function resendEmail(vrouw: Vrouw, item: BaseLog): Promise<void> {
	let params = `communicatieType=${item.communicatie_type}&taal=${vrouw.taal}`;
	if (item.detail_type) {
		params += `&detailType=${item.detail_type}`;
	}
	await httpService.post(`/api/email/${vrouw.vrouwId}?${params}`, {});
}
export async function resendDiabetesteamEmail(vrouw: Vrouw): Promise<void> {
	await httpService.post(`/api/diabetesteam-email/${vrouw.vrouwId}`, {});
}
export async function resendHuisartsNotificatie(vrouw: Vrouw): Promise<void> {
	await httpService.post(`/api/huisartsnotificatie/${vrouw.vrouwId}`, {});
}
