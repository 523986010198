import {Module} from 'vuex';
import {router} from '@/modules/core/router/router';
import {ROUTES} from '@/modules/core/router/routes';

export default (): Module<any, any> => ({
	namespaced: true,
	state: {
		sidebarShown: true, // null sets web default to true and mobile to false
		sidebarMini: false,
		currentItem: undefined,
		items: sidebarItems,
	},
	mutations: {
		CURRENT_ITEM(state, item) {
			state.currentItem = item;
		},
		SHOW_SIDEBAR(state) {
			state.sidebarShown = true;
		},
		CLOSE_SIDEBAR(state) {
			state.sidebarShown = false;
		},
		TOGGLE_SIDEBAR(state) {
			state.sidebarShown = !state.sidebarShown;
		},
		SET_SIDEBAR_SHOWN(state, value) {
			state.sidebarShown = value;
		},
		TOGGLE_SIDEBAR_MINI(state) {
			state.sidebarMini = !state.sidebarMini;
		},
	},
	actions: {
		setCurrentItem(context, item) {
			context.commit('CURRENT_ITEM', item);
		},
		goTo(context, item) {
			context.commit('CURRENT_ITEM', item);
			router.push({ name: item.route.name });
		},
	},
	getters: {
		canSeeItem: (state, getters, rootState, rootGetters) => (item) => {
			if (item.route !== undefined) {
				if (item.route.publicRoute) {
					return true;
				} else {
					let accesRights = rootGetters['authorisationStore/accessRights'];
					return item.route.accessRight == null || accesRights.includes(item.route.accessRight);
				}
			} else {
				return true;
			}
		},
	},
});

export const sidebarItems = [
	{ icon: 'person_search', text: 'sidebar.person-search', route: ROUTES.WOMAN },
	{ icon: 'medical_services', text: 'sidebar.medical-services', route: ROUTES.DOCTORS },
	{ icon: 'emergency', text: 'sidebar.emergency', route: ROUTES.DIABETIC_TEAMS },
	{ icon: 'mail', text: 'sidebar.mail', route: ROUTES.CORRESPONDENTIE_BEHEER },
	{ icon: 'account_tree', text: 'sidebar.bpm', route: ROUTES.BPM_BEHEER },
];
