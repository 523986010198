<template>
  <v-container fluid>
    <div v-if="womanReference">
      <h2>{{ $t('woman.doubleChecks.title') }}</h2>

      <v-row class="mt-12 align-center">
        <v-col>
          <p class="text-h6 text-center">{{ $t('woman.doubleChecks.compare') }}</p>
        </v-col>

        <v-col>
          <p v-if="womenCompare.length === 1" class="text-h6 text-center">
            {{ $t('woman.doubleChecks.reference') }}
          </p>
          <v-select v-if="womenCompare.length > 1"
                    v-model="activeWomanCompare"
                    :items="womenCompare"
                    :label="$t('woman.doubleChecks.reference')"
                    item-text="reference"
                    return-object
                    outlined
                    hide-details>

          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <woman-details :woman="womanReference"
                         @update-completed="handleSaveDetailsReferenceWoman"/>
        </v-col>

        <v-col>
          <woman-details v-if="activeWomanCompare"
                         :woman="activeWomanCompare"
                         :compare-woman="womanReference"
                         @update-completed="handleSaveDetailsActiveWomanCompare"/>
        </v-col>
      </v-row>

      <v-row class="mt-12">
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('woman.doubleChecks.noDouble.title') }}
            </v-card-title>
            <v-card-text>
              {{ $t('woman.doubleChecks.noDouble.description') }}
            </v-card-text>
            <v-card-actions class="align-center justify-center pb-4">
              <v-btn class="primary" @click="handleNoDouble">
                <v-icon class="mr-5">check_circle_outline</v-icon>
                <span>{{ $t('woman.doubleChecks.noDouble.action') }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('woman.doubleChecks.double.title') }}
            </v-card-title>
            <v-card-text>
              {{ $t('woman.doubleChecks.double.description') }}
            </v-card-text>
            <v-card-actions class="align-center justify-center pb-4">
              <v-btn class="secondary" @click="handleDouble">
                <v-icon class="mr-5">warning_amber</v-icon>
                <span>{{ $t('woman.doubleChecks.double.action') }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>

</template>

<script>
import {getVrouwDoorId, getVrouwDubbels, updateDubbel, updateGeenDubbels} from "@/modules/vrouw/api";
import WomanDetails from "@/modules/vrouw/components/WomanDetails";
import {ROUTES} from "@/modules/core/router/routes";
import {getTaskInfoByTaskNameAndBusinessKey} from "@/modules/camunda/api";
import {TaskNames} from "@/modules/camunda/enum";

export default {
  name: "DoubleChecks",
  components: {WomanDetails},
  mounted() {
    this.fetchTaskId();
    this.fetchWomanDetails(this.$route.params.id);
  },
  data() {
    return {
      womanReference: null,
      womenCompare: [],
      activeWomanCompare: null,
      taskId: null
    }
  },
  methods: {
    fetchWomanDetails: async function (id) {
      this.womanReference = await getVrouwDoorId(id);
      this.womenCompare = await getVrouwDubbels(id);

      if (this.womenCompare.length) {
        this.activeWomanCompare = this.womenCompare[0];
      }
    },
    async handleSaveDetailsReferenceWoman(formData) {
      this.womanReference = await getVrouwDoorId(this.womanReference.vrouwId);
    },
    async handleSaveDetailsActiveWomanCompare(formData) {
      this.activeWomanCompare = await getVrouwDoorId(this.activeWomanCompare.vrouwId);
      this.womenCompare = this.womenCompare.map(i => i.vrouwId === this.activeWomanCompare.vrouwId? this.activeWomanCompare : i)
    },
    async handleNoDouble() {
      await updateGeenDubbels(this.taskId);
      await this.$router.replace({name: ROUTES.WOMAN_DETAILS.name, params: {id: this.womanReference.vrouwId}})
    },
    async handleDouble() {
      await updateDubbel(this.taskId, this.activeWomanCompare.vrouwId);
      await this.$router.replace({name: ROUTES.WOMAN_DETAILS.name, params: {id: this.activeWomanCompare.vrouwId}})
    },
    async fetchTaskId() {
      const taskIdFromQuery = this.$route.query.taskId;

      if (taskIdFromQuery) {
        this.taskId = taskIdFromQuery;
        return
      }

      const response = await getTaskInfoByTaskNameAndBusinessKey(TaskNames.manueleControleDubbeleRegistratie, this.$route.params.id, null);  //no need to fetch the piId b/c there will ever be 1 dubbele controle task
      this.taskId = response ? response.id : null;
    }
  },
  watch: {
    activeWomanCompare() {
      this.formDataActiveWomanCompare = [];
    }
  }
}
</script>

<style scoped>

</style>
