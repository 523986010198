<template>
  <v-timeline class="d-flex flex-column">
    <slot/>
  </v-timeline>
</template>

<script>
export default {
  name: "TimeLine"
}
</script>

<style scoped lang="scss">

  .v-timeline {
  height: 100%;

  &:before {
    background: #CC0066;
    left: 168px !important;
  }
}

</style>