import {getOpvolgingType, OpvolgingType} from "@/modules/vrouw/domains/opvolging";
import {getValueFromObject} from "@/modules/core/utils";
import {VrouwLogType} from "@/modules/vrouw/domains/logs";
import {httpService} from "@/modules/core/services/http-service";
import {getAllTasksByPID} from "@/modules/camunda/api";


function mapVariablesWithOpvolgingLogType(variable: any): OpvolgingType {
    const type = getValueFromObject(variable, "opvolging_type.value");
    return getOpvolgingType(type)
}

function mapRegistrationWithLogType(): any {
    return VrouwLogType.registratie;
}


function mapVariablesWithStopzettingLogType(): VrouwLogType {
    return VrouwLogType.uitschrijven;
}


function getAllVariablesForPID(pid: string): Promise<any> {
    return httpService.get(`/camunda/engine-rest/process-instance/${pid}/variables`)
}

async function getDataForProcessInstances(vrouwId: string, processInstances: any[], logTypeMapper: Function): Promise<{ logType: string, tasks: any }[]> {
    try {
        const output: any = [];


        for (let processInstance of processInstances) {
            const allVariables = await getAllVariablesForPID(processInstance.id);
            const logType = logTypeMapper(allVariables);

            const logTypeIndex = output.findIndex(element => element.logType === logType);

            const tasks = await getAllTasksByPID(processInstance.id);

            if(logTypeIndex !== -1) {
                output[logTypeIndex].tasks = output[logTypeIndex].tasks.concat(tasks);
            } else {
                output.push({
                    logType,
                    tasks
                })
            }
        }

        return output
    } catch (e) {
        return []
    }
}

export async function getVrouwLogsActief(vrouwId: string): Promise<{ logType: string, tasks: any }[]> {
    const responseOpvolging = await httpService.get<any[]>(`/camunda/engine-rest/process-instance?businessKey=${vrouwId}&processDefinitionKey=OpvolgingProcess`);
    const opvolgingData: any[] = await getDataForProcessInstances(vrouwId, responseOpvolging, mapVariablesWithOpvolgingLogType);

    const responseRegistratie = await httpService.get<any[]>(`/camunda/engine-rest/process-instance?businessKey=${vrouwId}&processDefinitionKey=RegistratieProcess`);
    const registratieData: any[] = await getDataForProcessInstances(vrouwId, responseRegistratie, mapRegistrationWithLogType);

    const responseStopzetting = await httpService.get<any[]>(`/camunda/engine-rest/process-instance?businessKey=${vrouwId}&processDefinitionKey=UitschrijvenProcess`);
    const stopzettingData: any[] = await getDataForProcessInstances(vrouwId, responseStopzetting, mapVariablesWithStopzettingLogType);

    return opvolgingData.concat(registratieData).concat(stopzettingData);
}
