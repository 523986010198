<template>
  <v-dialog max-width="1200" v-model="dialogOpen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary"
             outlined
             small
             class="header-btn"
             v-bind="attrs"
             v-on="on">
        <v-icon class="material-icons-outlined">table_chart</v-icon>
        {{ $t("woman.details.header.tableFollowUp") }}
      </v-btn>
    </template>

    <template v-slot:default="dialog">

      <v-card>
        <v-card-title>
          <h4>{{ $t('woman.followUp.table.title') }}{{ woman.fullName }}</h4>
          <v-spacer/>
          <v-icon @click="dialog.value = false">close</v-icon>
        </v-card-title>

        <v-data-table disable-sort
                      :headers="headers"
                      :items="followUps">

          <template v-slot:item.opvolgingType="{item}">
            {{ $t(`woman.followUp.table.type.${item.opvolgingType}`) }}
          </template>

          <template v-slot:item.consultatieHuisarts="{item}">
            <v-icon class="material-icons-outlined" v-if="isIcon(item)">
              {{ getIcon(item) }}
            </v-icon>
            <span v-else>{{ getIcon(item) }}</span>
          </template>

          <template v-slot:item.bloedglucoseWaarde="{item}">
            {{ item.bloedglucoseWaarde ? item.bloedglucoseWaarde : '' }}
          </template>

          <template v-slot:item.communicatieVerstuurd="{ item }">
            <span>{{ item.communicatieVerstuurd ? item.communicatieVerstuurd.format("DD/MM/YYYY") : '' }}</span>
          </template>

          <template v-slot:item.antwoordOp="{ item }">
            <span>{{ item.antwoordOp ? item.antwoordOp.format("DD/MM/YYYY") : '' }}</span>
          </template>

        </v-data-table>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="dialog.value = false">
            {{ $t('woman.followUp.table.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </template>

  </v-dialog>
</template>

<script>
import {Vrouw} from "@/modules/vrouw/domains/vrouw";
import {getVrouwJaarlijkseOpvolgingen} from "@/modules/vrouw/api";
import {consultatieHuisartsType} from "@/modules/vrouw/domains/opvolging";

export default {
  name: "JaarlijkseOpvolgingTable",
  props: {woman: Vrouw},
  data() {
    return {
      followUps: [],
      headers: [
        {
          text: this.$t('woman.followUp.table.headers.type'),
          value: 'opvolgingType',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.year'),
          value: 'year',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.doctorScreening'),
          value: 'consultatieHuisarts',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.bloodSugarNumber'),
          value: 'bloedglucoseWaarde',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.result'),
          value: 'bloedglucoseResultaat',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.communicationSent'),
          value: 'communicatieVerstuurd',
          align: 'center'
        },
        {
          text: this.$t('woman.followUp.table.headers.resultReceived'),
          value: 'antwoordOp',
          align: 'center'
        },
      ],
      dialogOpen: false
    }
  },
  methods: {
    fetchFollowUp: async function () {
      try {
        this.followUps = await getVrouwJaarlijkseOpvolgingen(this.woman.vrouwId);
      } catch (e) {

      }
    },
    isIcon(item) {
      return item.consultatieHuisarts !== consultatieHuisartsType.GeenResultaat
    },
    getIcon(item) {
      switch (item.consultatieHuisarts) {
        case consultatieHuisartsType.Ja:
          return "done_outline";
        case consultatieHuisartsType.GeenResultaat:
          return "";
        default:
          return "close";
      }
    }
  },
  watch: {
    dialogOpen: function () {
      if (this.dialogOpen) {
        this.fetchFollowUp()
      }
    }
  }
}
</script>

<style scoped>

</style>